import React from 'react'
import SizeReactiveComponent from '../SizeReactiveComponent'

import UserAvatarButton from '../UserAvatarButton'
import ActivityContainer from '../../Components/ActivityContainer'
import config from '../../Common/Config'

import Icon from './icons'

/** This component renders the header bar for the Map screen. */
export default class Header extends SizeReactiveComponent {

    // Constructor
    constructor(props) {

        super(props)

        // Set default state
        this.state = {}
        this.state.showNotifications = false

    }

    /** Render components */
    render() {

        return (
          <div style={{ backgroundColor: config.theme.header.backgroundColor }}>
            {/* Side padding */}
            <div style={{ display: 'block', marginLeft: this.isMobile ? '0px' : '6%', marginRight: this.isMobile ? '0px' : '6%' }}>

                {/* First line of bar */}
                <div style={{ display: 'flex', alignItems: 'center' }}>

                    {/* Tab buttons, if on desktop */}
                    {this.isDesktop ? <React.Fragment>
                        <div style={{marginRight: '40px'}}>
                            <HeaderLogo
                                height = {config.theme.header.logoHeight}
                                width = {config.theme.header.logoWidth}
                                src = {config.theme.header.logo}
                            />
                        </div>
                        {(config.features.header.inventory) ? <HeaderDesktopTab
                            icon={'inventory'}
                            text={config.theme.header.altInventoryName}
                            textColor={config.theme.header.tabs.fontColor}
                            borderColor={config.theme.header.backgroundColor}

                            fontFamily={config.theme.header.tabs.fontFamily}
                            fontSize={config.theme.header.tabs.fontSize}
                            fontWeight={config.theme.header.tabs.fontWeight}

                            onClick={e => window.location.hash = '/inventory'}
                            selected={this.props.tab === 'inventory'}
                            selectedTextColor={config.theme.header.selectedTabs.fontColor}
                            selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                            selectedFontSize={config.theme.header.selectedTabs.fontSize}
                            selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                            selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                            selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}

                        /> : null}

                        {(config.features.header.coins) ? <HeaderDesktopTab
                            icon={'coins'}
                            indicator={true}
                            indicatorColor={config.theme.header.tabs.fontColor}
                            indicatorSelectedColor={config.theme.header.selectedTabs.fontColor}
                            indicatorBg={config.theme.header.tabs.fontColor}
                            indicatorSelectedBg={config.theme.header.tabs.fontColor}
                            indicatorValue={this.props.indicatorValue}
                            text={config.theme.header.altCoinsName}
                            textColor={config.theme.header.tabs.fontColor}
                            borderColor={config.theme.header.backgroundColor}

                            fontFamily={config.theme.header.tabs.fontFamily}
                            fontSize={config.theme.header.tabs.fontSize}
                            fontWeight={config.theme.header.tabs.fontWeight}

                            onClick={e => window.location.hash = '/coins'}
                            selected={this.props.tab === 'coins'}
                            selectedTextColor={config.theme.header.selectedTabs.fontColor}
                            selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                            selectedFontSize={config.theme.header.selectedTabs.fontSize}
                            selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                            selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                            selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}

                        /> : null}

                        {(config.features.header.map) ? <HeaderDesktopTab
                            icon={'map'}
                            text={config.theme.header.altMapName}
                            textColor={config.theme.header.tabs.fontColor}
                            borderColor={config.theme.header.backgroundColor}

                            fontFamily={config.theme.header.tabs.fontFamily}
                            fontSize={config.theme.header.tabs.fontSize}
                            fontWeight={config.theme.header.tabs.fontWeight}

                            onClick={e => window.location.hash = '/map'}
                            selected={this.props.tab === 'map'}
                            selectedTextColor={config.theme.header.selectedTabs.fontColor}
                            selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                            selectedFontSize={config.theme.header.selectedTabs.fontSize}
                            selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                            selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                            selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}
                        /> : null}

                        {(config.features.header.scanner) ? <HeaderDesktopTab
                            icon={'scanner'}
                            text={config.theme.header.altScannerName}
                            textColor={config.theme.header.tabs.fontColor}
                            borderColor={config.theme.header.backgroundColor}

                            fontFamily={config.theme.header.tabs.fontFamily}
                            fontSize={config.theme.header.tabs.fontSize}
                            fontWeight={config.theme.header.tabs.fontWeight}

                            onClick={e => window.location.hash = '/scanner'}
                            selected={this.props.tab === 'scanner'}
                            selectedTextColor={config.theme.header.selectedTabs.fontColor}
                            selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                            selectedFontSize={config.theme.header.selectedTabs.fontSize}
                            selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                            selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                            selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}
                        /> : null}
                    </React.Fragment> : null}

                    {/* Tab buttons, if on mobile */}

                    {this.isMobile ? <React.Fragment>
                        <div style={{ marginLeft: '16px' }} />
                        <HeaderLogo
                            height = {config.theme.header.logoHeight}
                            width = {config.theme.header.logoWidth}
                            src = {config.theme.header.logo}
                        />
                    </React.Fragment> : null}

                    {/* Divider */}
                    <div style={{ flexGrow: '1' }} />

                    {/* Notification button */}
                    {(config.features.header.activity) ? <ActivityContainer /> : null}

                    {/* User avatar button */}
                    {(config.features.header.profile) ? <UserAvatarButton style={{ marginLeft: 16 }} onClick={e => window.location.hash = '/profile'} /> : null }
                    {this.isMobile ? <React.Fragment>
                        <div style={{ marginRight: '16px' }} />
                    </React.Fragment> : null}
                </div>

                {/* Second line of bar */}
                {this.isMobile ? <React.Fragment>
                    <div style={{ display: 'flex', alignItems: 'center'}}>

                        {/* Tab buttons, if on mobile */}
                        {this.isMobile ? <React.Fragment>

                            {(config.features.header.inventory) ? <HeaderMobileTab
                                text={config.theme.header.altInventoryName}
                                textColor={config.theme.header.tabs.fontColor}
                                borderColor={config.theme.header.tabs.borderColor}

                                fontFamily={config.theme.header.tabs.fontFamily}
                                fontSize={config.theme.header.tabs.fontSize}
                                fontWeight={config.theme.header.tabs.fontWeight}

                                onClick={e => { this.setState({ showMenu: false }); window.location.hash = '/inventory' }}
                                selected={this.props.tab === 'inventory'}
                                selectedTextColor={config.theme.header.selectedTabs.fontColor}
                                selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                                selectedFontSize={config.theme.header.selectedTabs.fontSize}
                                selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                                selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                                selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}
                            /> : null}

                            {(config.features.header.coins) ? <HeaderMobileTab
                              text={config.theme.header.altCoinsName}
                              textColor={config.theme.header.tabs.fontColor}
                              borderColor={config.theme.header.tabs.borderColor}
                              indicator={true}
                              indicatorColor={config.theme.header.tabs.fontColor}
                              indicatorSelectedColor={config.theme.header.selectedTabs.fontColor}
                              indicatorBg={config.theme.header.tabs.fontColor}
                              indicatorSelectedBg={config.theme.header.tabs.fontColor}
                              indicatorValue={this.props.indicatorValue}
                              fontFamily={config.theme.header.tabs.fontFamily}
                              fontSize={config.theme.header.tabs.fontSize}
                              fontWeight={config.theme.header.tabs.fontWeight}

                              onClick={e => { this.setState({ showMenu: false }); window.location.hash = '/coins' }}
                              selected={this.props.tab === 'coins'}
                              selectedTextColor={config.theme.header.selectedTabs.fontColor}
                              selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                              selectedFontSize={config.theme.header.selectedTabs.fontSize}
                              selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                              selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                              selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}
                          /> : null}

                            {(config.features.header.map) ? <HeaderMobileTab
                                text={config.theme.header.altMapName}
                                textColor={config.theme.header.tabs.fontColor}
                                borderColor={config.theme.header.tabs.borderColor}

                                fontFamily={config.theme.header.tabs.fontFamily}
                                fontSize={config.theme.header.tabs.fontSize}
                                fontWeight={config.theme.header.tabs.fontWeight}

                                onClick={e => { this.setState({ showMenu: false }); window.location.hash = '/map' }}
                                selected={this.props.tab === 'map'}
                                selectedTextColor={config.theme.header.selectedTabs.fontColor}
                                selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                                selectedFontSize={config.theme.header.selectedTabs.fontSize}
                                selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                                selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                                selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}
                            /> : null}

                            {(config.features.header.scanner) ? <HeaderMobileTab
                                text={config.theme.header.altScannerName}
                                textColor={config.theme.header.tabs.fontColor}
                                borderColor={config.theme.header.tabs.borderColor}

                                fontFamily={config.theme.header.tabs.fontFamily}
                                fontSize={config.theme.header.tabs.fontSize}
                                fontWeight={config.theme.header.tabs.fontWeight}

                                onClick={e => { this.setState({ showMenu: false }); window.location.hash = '/scanner' }}
                                selected={this.props.tab === 'scanner'}
                                selectedTextColor={config.theme.header.selectedTabs.fontColor}
                                selectedFontFamily={config.theme.header.selectedTabs.fontFamily}
                                selectedFontSize={config.theme.header.selectedTabs.fontSize}
                                selectedFontWeight={config.theme.header.selectedTabs.fontWeight}
                                selectedTabAccentColor={config.theme.header.selectedTabs.accentColor}
                                selectedTabAccentHeight={config.theme.header.selectedTabs.accentHeight}
                            /> : null}

                        </React.Fragment> : null}
                        {/* Divider */}
                    </div>
                </React.Fragment> : null}

            </div>
          </div>
        )

    }

}

/** Convert hex color to rgba */
function hexToRgbA(hex, opacity){
  var c
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('')
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c= '0x'+c.join('')
      let val = (`rgba(`+[(c>>16)&255, (c>>8)&255, c&255].join(',')+`,${opacity})`)
      return val
  }
  console.warn('Bad Hex');
}



/** Shows a header button */
function HeaderButton(props) {

    return <div onClick={props.onClick} style={Object.assign({
        display: 'inline-block',
        width: 64,
        height: 64,
        opacity: '1',
        backgroundImage: 'url(' + props.icon + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer' }, props.style)}>

    </div>

}

const HeaderLogo = (props) => {
    if(props.src == undefined ) {
      return null
    }

    return (
        <img
            height={props.height}
            width={props.width}
            src={props.src}
        >
        </img>
    )
}

/** Tab button in desktop mode */
function HeaderDesktopTab(props) {

    // Create default style
    var style = {
        position: 'relative',
        display: 'flex',
        borderTopWidth: props.selectedTabAccentHeight,
        borderTopStyle: 'solid',
        borderTopColor: props.borderColor,
        paddingTop: 27,
        paddingBottom: 27,
        cursor: 'pointer',
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight
    }

    // Set ON style
    if (props.selected) {
        style.borderColor = props.selectedTabAccentColor
        style.borderTopWidth = props.selectedTabAccentHeight
        style.color = props.selectedTextColor
        style.fontFamily = props.selectedFontFamily
        style.fontSize = props.selectedFontSize
        style.fontWeight = props.selectedFontWeight
    }

    return (

        <div style={style} onClick={props.onClick}>

            {/* Icon */}
            <div style={{ marginLeft: 8, marginRight: 6 }}>
                {props.selected ? <Icon name={props.icon} stroke={props.selectedTextColor} /> : <Icon name={props.icon} stroke={props.textColor} /> }
            </div>

            {/* Title */}
            <div style={{ marginLeft: 0, marginRight: 24 }}>
                { props.text }
            </div>

            {/* Indicator */}
            {props.indicator && localStorage.getItem('coinValue') > 0 ?
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '-19px', fontSize: '12px', alignItems: 'center', padding: '0', backgroundColor: `${hexToRgbA(props.indicatorColor, 0.2)}`, color: `${props.selected ? props.indicatorSelectedColor : props.indicatorColor}`, width:'30px', height: '20px', borderRadius: '10px', fontFamily: 'sans-serif'  }}>
                <span>{localStorage.getItem('coinValue')}</span>
            </div>

            : null }

        </div>
    )

}



/** Tab button on mobile */
function HeaderMobileTab(props) {

    // Create default style
    var style = {
        position: 'relative',
        color: props.textColor,
        cursor: 'pointer',
        flex: '1 1 0',
        width: 0,
        alignItems: 'center',
        borderBottomWidth: props.selectedTabAccentHeight,
        borderBottomStyle: 'solid',
        borderBottomColor: props.borderColor,
        borderColor: props.borderColor,
        backgroundColor: props.backgroundColor,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight
    }

    // Set ON style
    if (props.selected) {
        style.borderColor = props.selectedTabAccentColor
        style.borderBottomWidth = props.selectedTabAccentHeight
        style.color = props.selectedTextColor
        style.borderBottomColor = props.selectedTabAccentColor
        style.fontFamily = props.selectedFontFamily
        style.fontSize = props.selectedFontSize
        style.fontWeight = props.selectedFontWeight
    }

    return (
        <div style={style} onClick={props.onClick} >
            <p style={{ display: 'inline-block', height: '18px' }}>{ props.text }
              {/* Indicator */}
              {props.indicator && localStorage.getItem('coinValue') > 0 ? 
              <div style={{ display: 'inline-block', justifyContent: 'center', transform: 'translateY(-5px)', marginLeft: '5px', fontSize: '12px', alignItems: 'center', padding: '0', backgroundColor: `${hexToRgbA(props.indicatorColor, 0.2)}`, color: `${props.selected ? props.indicatorSelectedColor : props.indicatorColor}`, width:'30px', height: '20px', borderRadius: '10px', fontFamily: 'sans-serif'  }}>

                  <span style={{ display: 'block', transform: 'translateY(3px)' }}>{localStorage.getItem('coinValue')}</span>
              </div> : null }
            </p>

        </div>

    )

}
