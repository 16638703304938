
import EventEmitter from './Code/EventEmitter'

/**
 *  The NetworkManager singleton manages control over the device's network, including registering a Service Worker
 *  to manage caching of requests.
 *
 *  Remember to inject your files into the service worker, by calling `workbox injectManifest path/to/NetworkManager.workbox.js`
 *  after building your app! This should be done as part of the build script automatically.
 *
 *  As of now, the service worker itself has to be in the root source directory. It's a parcel bug -_-
 *
 * @event statechange The state of the service worker has changed
 *
 */
export default new class NetworkManager extends EventEmitter {

    /** Possible states for the service worker */
    State = {

        /** Service worker is not available */
        NotAvailable: 0,

        /** Service worker is checking for app updates */
        Checking: 1,

        /** Service worker is busy downloading */
        Downloading: 2,

        /** Service worker is registered and handling requests normally */
        Ok: 3,

        /** Service worker has installed but is waiting for the browser to activate it */
        Waiting: 4,

        /** Service worker has failed to install correctly */
        Error: 5

    }

    /** The current service worker state */
    state = 0

    /** If state == Error, this contains the error */
    error = null

    /**
     *  Called on app startup to register components
     */
    async register() {

        // Catch errors
        try {

            // Check if Service Workers are supported
            if (!navigator.serviceWorker)
                throw new Error('Service workers are not supported in this browser.')

            // Check if a service worker exists already
            this.servedFromCache = !!navigator.serviceWorker.controller

            // Register service worker
            this.registration = await navigator.serviceWorker.register('./NetworkManager.worker.js')

            // Safari!!! Damn thing does not emit events correctly... Just check on a timer for changes
            this.checkTimer = setInterval(this.checkSWState.bind(this), 5000)

        } catch (err) {

            // Failed!
            console.warn('[NetworkManager] Unable to register. ', err)
            this.state = this.State.Error
            this.error = err
            this.emit('statechange')

        }

    }

    /** Called periodically to check the state of the service worker */
    async checkSWState() {

        // Get service worker state
        var newState = 0
        try {

            // Get current registration
            let reg = await navigator.serviceWorker.getRegistration() || this.registration
            if (!reg)
                throw new Error('Unable to get the state of the service worker.')

            // Check registration state
            if (reg.installing) {

                // A service worker is in the process of being downloaded
                newState = this.State.Downloading

            } else if (reg.waiting) {

                // Service worker has downloaded and is waiting to be "installed"
                newState = this.State.Waiting

            } else if (reg.active && navigator.serviceWorker.controller) {

                // Service worker is controlling us
                newState = this.State.Ok

            } else if (reg.active) {

                // Service worker is ready but not controlling us for some reason
                console.warn('[NetworkManager] Service worker has not claimed this page, we need the user to reload.')
                newState = this.State.Waiting

                // Technically we should never leave this state, stop the timer
                clearInterval(this.checkTimer)

            } else {

                // Service worker is not controlling us
                throw new Error('Your browser has stopped the service worker.')

            }

        } catch (err) {

            // Failed!
            console.warn('[NetworkManager] Unable to read service worker state. ', err)
            newState = this.State.Error
            this.error = err

        }

        // Check if our state changed
        if (this.state === newState)
            return

        // Notify listeners
        this.state = newState
        this.emit('statechange')

    }

    /**
     *  The app can call this when the state is UpdatesPending, to refresh the app and install the updates.
     */
    async installUpdates() {

        // Get registration
        let reg = await navigator.serviceWorker.getRegistration() || this.registration

        // If the service worker failed to claim this page, but is ready to go, just reload now
        if (!reg.waiting)
            return location.reload()

        // Create unending promise, since the page will reload on successful install
        return new Promise((resolve, reject) => {

            // Add a timeout handler
            var timeout = setTimeout(e => {

                // Failed to install, reload anyway since most likely the service worker event
                // just did not reach us
                console.warn('There was a problem installing the update, the service worker is not responding.')
                location.reload()

            }, 5000)

            // Register a handler for the service worker control switch
            navigator.serviceWorker.addEventListener('controllerchange', e => {

                // Done, reload the page
                clearTimeout(timeout)
                location.reload()

            })

            // Ask the service worker to skip waiting
            reg.waiting.postMessage({ type: 'SKIP_WAITING' })

        })

    }

}()
