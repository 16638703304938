
/* global Analytics */
import JSAlert from 'js-alert'
import BLOCKv from '../Common/Blockv'
import Errors from '../Common/Errors'
import swal2 from 'sweetalert2'

import Config from '../Common/Config'
import '../Common/sweet-alert-2.css'
import closeVatomIcon from '../images/close-vatom-icon.svg'

export default class VatomPickup {

    static async triggerAnalytics (vatom) {
        try {

            // Check which action is available
            if (vatom.actions.find(a => a.name.endsWith(':Dispense') && a.properties)) {

                // Call the Dispense action to get a vAtom
                await BLOCKv.Vatoms.performAction(vatom.id, 'Dispense')

                // Log analytics
                Analytics.event('performAction', {
                    'event': 'performAction',
                    'eventValue': 1,
                    'actionUri': 'Dispense',
                    'userId': BLOCKv.store.userId,
                    'digitalObjectId': vatom.id,
                    'vatomId': vatom.id,
                    'templateVariationName': vatom.properties.template_variation,
                    'location': {
                        'longitude': location?.longitude,
                        'latitude': location?.latitude
                    }
                }, vatom)

            } else {

                // Using the old method, pick a random child and call the AcquirePubVariation action on it
                let children = await BLOCKv.Vatoms.getVatomChildren(vatom.id)
                let child = children[Math.floor(Math.random() * children.length)]
                if (!child)
                    throw new Error('There are no vAtoms inside this dispenser.')

                // Call the correct action
                if (child.actions.find(a => a.name === 'Acquire')) {

                    // Acquire it
                    await BLOCKv.Vatoms.performAction(child.id, 'Acquire')

                    // Log analytics
                    Analytics.event('performAction', {
                        'event': 'performAction',
                        'eventValue': 1,
                        'actionUri': 'Acquire',
                        'userId': BLOCKv.store.userId,
                        'digitalObjectId': vatom.id,
                        'vatomId': vatom.id,
                        'templateVariationName': vatom.properties.template_variation,
                        'location': {
                            'longitude': location?.longitude,
                            'latitude': location?.latitude
                        },
                        'source': 'Map'
                    }, vatom)

                } else {

                    // Acquire a copy
                    await BLOCKv.Vatoms.performAction(child.id, 'AcquirePubVariation')

                    // Log analytics
                    Analytics.event('performAction', {
                        'event': 'performAction',
                        'eventValue': 1,
                        'actionUri': 'AcquirePubVariation',
                        'userId': BLOCKv.store.userId,
                        'digitalObjectId': vatom.id,
                        'vatomId': vatom.id,
                        'templateVariationName': vatom.properties.template_variation,
                        'location': {
                            'longitude': location?.longitude,
                            'latitude': location?.latitude
                        },
                        'source': 'Map'
                    }, vatom)

                }

            }

            // Go to the inventory
            window.location.hash = '/inventory'

        } catch (err) {

            Errors.show(err)

        }
    }

    /** @private Picks up the specified vatom from the map. */
    static async pickup(vatom, location = null, vatomUrl) {

        // Check if it's a dispenser
        if (vatom.properties.template.indexOf('MapDispenser') !== -1 || vatom.properties['is_map_dispenser'] || vatom.actions.find(a => a.name.endsWith(':Dispense')))
            return VatomPickup.pickupFromDispenser(vatom, vatomUrl)

        swal2.fire({
            imageUrl: vatomUrl,
            title: (vatom.properties.title).toUpperCase(),
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: Config.theme.vatomPopUp.buttonColor,
            confirmButtonText: 'PICK UP',
            showCloseButton: true,
            closeButtonHtml: `<img src=${closeVatomIcon} alt='close me'>`,
            customClass: {
                container: 'container-class',
                popup: 'popup-class',
                header: 'header-class',
                title: 'title-class',
                closeButton: 'close-button-class',
                icon: 'icon-class',
                image: 'image-class',
                content: 'content-class',
                input: 'input-class',
                actions: 'actions-class',
                confirmButton: 'confirm-button-class',
                cancelButton: 'cancel-button-class',
                footer: 'footer-class'
            },
            preConfirm: async () => {
                try {

                    // Claim it
                    await BLOCKv.Vatoms.performAction(vatom.id, 'Pickup')

                    swal2.fire({
                        title: 'picking up...',
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false,
                    })

                    // Go to the inventory
                    window.location.hash = '/inventory'

                    // Log analytics
                    Analytics.event('performAction', {
                        'event': 'performAction',
                        'eventValue': 1,
                        'actionUri': 'Pickup',
                        'userId': BLOCKv.store.userId,
                        'digitalObjectId': vatom.id,
                        'vatomId': vatom.id,
                        'templateVariationName': vatom.properties.template_variation,
                        'location': {
                            'longitude': location?.longitude,
                            'latitude': location?.latitude
                        }
                    }, vatom)

                } catch (err) {

                    Errors.show(err,vatom, null, null, 'pickup')

                }
            }
        })

    }

    /** @private Picks up a vatom from a dispenser vatom on the map */
    static async pickupFromDispenser(vatom, vatomUrl, location = null) {

        swal2.fire({
            imageUrl: vatomUrl,
            title: (vatom.properties.title).toUpperCase(),
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: Config.theme.vatomPopUp.buttonColor,
            confirmButtonText: 'PICK UP',
            showCloseButton: true,
            closeButtonHtml: `<img src=${closeVatomIcon} alt='close me'>`,
            customClass: {
                container: 'container-class',
                popup: 'popup-class',
                header: 'header-class',
                title: 'title-class',
                closeButton: 'close-button-class',
                icon: 'icon-class',
                image: 'image-class',
                content: 'content-class',
                input: 'input-class',
                actions: 'actions-class',
                confirmButton: 'confirm-button-class',
                cancelButton: 'cancel-button-class',
                footer: 'footer-class'
            },
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return VatomPickup.triggerAnalytics(vatom);
            }

        })
    }
}
