//
//

import Contact from './Contact'
import ContactsPlugin from './ContactsPlugin'
import Config from '../../Common/Config'

const CLIENT_ID = Config.googleContactsClientID

/** This Contacts plugin pulls contacts from the user's Google account */
export default class GoogleContactsPlugin extends ContactsPlugin {

    constructor(manager) {

        super(manager)

        // Setup properties
        this.id = 'com.google.contacts'
        this.name = 'Google Contacts'
        this.iconURL = require('./google.svg')
        this.accessToken = null

        // Load existing access token, if possible
        try {

            this.accessToken = localStorage[this.id + ':token']
            if (this.accessToken) this.connected = true

        } catch (e) {
        }

    }

    /** Refresh the inventory */
    async refresh() {

        // Stop if no access token
        if (!this.accessToken)
            return

        // Don't load if already loaded
        if (this.contacts.length > 0)
            return

        // Start updating
        if (this.updating) return
        this.updating = true

        // Fetch contacts
        fetch(`https://people.googleapis.com/v1/people/me/connections?pageSize=2000&personFields=emailAddresses,coverPhotos,names,phoneNumbers&access_token=${this.accessToken}`).then(response => response.json()).then(data => {

            // Check for error
            if (data.error) {

                // Throw the error
                let err = new Error(data.error.message)
                err.code = data.error.status
                throw err

            }

            // Use contacts
            for (let person of data.connections) {

                // Get base info
                let avatarURL = person.coverPhotos && person.coverPhotos[0] && person.coverPhotos[0].url
                let firstName = person.names && person.names[0] && person.names[0].givenName
                let lastName = person.names && person.names[0] && person.names[0].familyName

                // Stop if no name available
                if (!firstName && !lastName)
                    continue

                // Go through emails
                if (person.emailAddresses) {

                    for (let emailInfo of person.emailAddresses) {

                        // Check if got data
                        if (!emailInfo.value)
                            continue

                        // Create contact
                        let contact = new Contact()
                        contact.plugin = this
                        contact.id = person.resourceName
                        contact.avatarURL = avatarURL
                        contact.firstName = firstName
                        contact.lastName = lastName
                        contact.token = emailInfo.value
                        this.addContact(contact)

                    }

                }

                // Go through phone numbbers
                if (person.phoneNumbers) {

                    for (let phoneInfo of person.phoneNumbers) {

                        // Check if got data
                        if (!phoneInfo.canonicalForm)
                            continue

                        // Create contact
                        let contact = new Contact()
                        contact.plugin = this
                        contact.id = person.resourceName
                        contact.avatarURL = avatarURL
                        contact.firstName = firstName
                        contact.lastName = lastName
                        contact.phone = phoneInfo.canonicalForm
                        contact.source = 'Google Contact'
                        this.addContact(contact)

                    }

                }

            }

            // Done!
            this.updating = false

        }).catch(err => {

            // Failed!
            this.updating = false
            console.error('unable to read contacts!', err)

        })

    }

    /** Connect to the service */
    connect() {

        // NOTE: In order to show the OAuth flow, we use this process in order to not leave the app:
        // 1. First we create a popup dialog asking the user to login.
        // 2. We get Google to redirect to our oauthCallback.html, which contains code to postMessage us the final token
        // 3. We listen for the token, and then close the popup dialog.

        // Create the redirect URL
        let instanceID = Math.random().toString(36).substring(2)
        let redirectTo = 'https://viewer.blockv.io/oauthCallback.html'
        let scopes = ['https://www.googleapis.com/auth/contacts.readonly']
        let state = { from: 'web-viewer', action: 'google-auth', instance: instanceID, origin: window.location.origin }
        let url = 'https://accounts.google.com/o/oauth2/v2/auth' +
            '?redirect_uri=' + encodeURIComponent(redirectTo) +
            '&client_id=' + CLIENT_ID +
            '&response_type=token' +
            '&scope=' + encodeURIComponent(scopes.join(' ')) +
            '&state=' + encodeURIComponent(JSON.stringify(state)) +
            '&include_granted_scopes=true'

        // Show the window
        let popup = window.open(url, 'oauth', 'top=200,left=300,width=600,height=700,centerscreen')

        // Wait for a message from the popup
        waitForWindowMessage(instanceID).then(msg => {

            // Got access token!
            this.accessToken = msg.accessToken
            this.connected = true

            // Save to local storage
            try {

                localStorage[this.id + ':token'] = msg.accessToken

            } catch (err) {

                console.warn('Unable to save Google access token to local storage', err)

            }

            // Close the window
            popup.close()

            // Do a refresh
            this.refresh()

        }).catch(err => {

            // Failed to connect
            console.error('Unable to authorize Google', err)

            // Close the window
            popup.close()

        })

    }

    /** @override Call to remove all credentials for this plugin */
    reset() {

        // Remove credentials and contacts
        this.contacts = []
        this.accessToken = null
        this.updating = false

        // Remove from local storage as well
        try {

            localStorage[this.id + ':token'] = ''

        } catch (err) {

            console.warn('Unable to save Google access token to local storage', err)

        }

    }

}

/**
 *  This function waits for a message to be delivered to the window.
 *  It adds a listener, waits for a message with the specified instance ID, then removes the listener.
 *
 *  @private
 *  @param {String} instance The instance ID to listen for.
 *  @returns {Promise<Object>} The message data.
 */
function waitForWindowMessage(instance) {

    // Create promise
    return new Promise((resolve, reject) => {

        // Create listener function
        var listener = null
        listener = function(e) {

            // Check that instance matches
            if (e.data.instance !== instance)
                return

            // Found response, check for error
            if (e.data.response === 'error') {

                // Failed, return error
                let error = new Error(e.data.errorText || 'An unknown error occurred.')
                error.code = e.data.error
                error.data = e.data
                reject(error)

            } else {

                // Success!
                resolve(e.data)

            }

            // Unregister listener
            window.removeEventListener('message', listener)

        }

        // Register listener
        window.addEventListener('message', listener)

    })

}
