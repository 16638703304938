
import React from 'react'
import ThreeDotLoader from './ThreeDotLoader'

/** Loads a component asynchronously. Use like this: `<ComponentLoader load={cb => require(['./MyComponent'], component => cb(component))} />` */
export default class ComponentLoader extends React.Component {

    /** Constructor */
    constructor(props) {

        super(props)
        this.state = {
            // short for "module" but that's a keyword in js, so "mod"
            mod: null
        }

    }

    /** @private Called when the component is mounted. */
    componentDidMount() {

        // Call the provided loader function
        this.props.load((mod, props) => {

            // Update state with newly loaded module
            this.setState({

                // handle both es imports and cjs
                mod: mod.default ? mod.default : mod,
                props: props

            })

        })

    }

    render() {

        // Render blank if not loaded yet
        if (!this.state.mod) {

            return <div style={{ display: 'flex', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <ThreeDotLoader />
            </div>

        }

        // If we have URL parameters, apply them as standard props instead of in the match prop
        let props = Object.assign({}, this.state.props || {})
        let params = (props && props.match && props.match.params) || {}
        for (var key in params) {

            if (!props[key])
                props[key] = params[key]

        }

        // Render component
        return React.createElement(this.state.mod, props)

    }

}
