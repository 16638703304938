//
// Fetch country list

/** Get countries, and return a promise. */
export default class Countries {

    static async get() {

        // Check if loaded already
        if (Countries.loadedItems)
            return Countries.loadedItems

        // Load countries
        const CountriesList = await import('countries-list/dist/countries.emoji.json')

        // Create array
        let items = []
        for (var code in CountriesList) {

            // Create country object
            let info = {
                code: code.toLowerCase(),
                name: CountriesList[code].name,
                label: code,
                phonePrefix: CountriesList[code].phone ? '+' + CountriesList[code].phone : ''
            }

            // Add it to array
            items.push(info)

        }

        // Sort array
        items.sort((a, b) => a.name.localeCompare(b.name))

        // Done
        Countries.loadedItems = items
        return items

    }

    // Detect the current user's country info
    static async detect() {

        // Return if already detected
        if (Countries.detected)
            return Countries.detected

        // Load items
        let countries = await Countries.get()

        // Fetch our country code
        let response = await fetch('https://geoip.vatomviewer.com/json/')
        let data = await response.json()

        // Find matching item
        var country = countries.find(c => c.code.toLowerCase() === data.country_code.toLowerCase())

        // Set it as our detected country
        if (country) {

            Countries.detected = country
            console.log('Detected country: ', country)

        }

        // Done
        return country

    }

}
