import React from 'react'

const Scanner = ({
    style = {},
    stroke = '#888888',
    width = '16px',
    height = '16px',
    className = '',
    viewBox = '0 0 22 22'
}) => {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <path fill={stroke} fillRule="nonzero" d="M20.894 14c.571 0 1.04.43 1.1.985l.006.12v2.26a4.629 4.629 0 0 1-4.423 4.63l-.212.005h-2.26A1.105 1.105 0 0 1 14 20.894c0-.57.43-1.04.985-1.1l.12-.006h2.26a2.42 2.42 0 0 0 2.418-2.264l.005-.16v-2.258c0-.612.494-1.106 1.106-1.106zM1.106 14c.612 0 1.106.494 1.106 1.106v2.259a2.42 2.42 0 0 0 2.423 2.423h2.26c.61 0 1.105.494 1.105 1.106C8 21.506 7.506 22 6.894 22H4.635A4.63 4.63 0 0 1 0 17.365v-2.26C0 14.496.494 14 1.106 14zm19.806-4c.602 0 1.088.447 1.088 1 0 .516-.424.94-.97.994l-.118.006H1.088C.486 12 0 11.553 0 11c0-.516.424-.94.97-.994L1.088 10h19.824zM6.894 0C7.506 0 8 .494 8 1.106c0 .57-.43 1.04-.985 1.1l-.12.006h-2.26a2.42 2.42 0 0 0-2.418 2.264l-.005.16v2.258C2.212 7.506 1.718 8 1.106 8c-.571 0-1.04-.43-1.1-.985L0 6.895v-2.26A4.629 4.629 0 0 1 4.423.005L4.635 0h2.26zm10.47 0a4.629 4.629 0 0 1 4.631 4.423l.005.212v2.26C22 7.504 21.506 8 20.894 8c-.57 0-1.04-.43-1.1-.985l-.006-.12v-2.26a2.42 2.42 0 0 0-2.264-2.418l-.16-.005h-2.258A1.105 1.105 0 0 1 14 1.106c0-.571.43-1.04.985-1.1l.12-.006h2.26z"/>
        </svg>
    )

}

export default Scanner
