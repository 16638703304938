
import React, { Component } from 'react'
import './App.css'
import { HashRouter, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import BLOCKv from './Common/Blockv'
import ComponentLoader from './Components/ComponentLoader'
import LoginScreen from './Routes/Login/LoginScreen'
import InventoryScreen from './Routes/Inventory/InventoryScreen'
import MapScreen from './Routes/Map/'
import IncomingOverlay from './Components/IncomingOverlay'
import vAtomScreen from './Routes/vAtom/vAtomScreen'
import RegisterScreen from './Routes/Register/RegisterScreen'
import ProfileScreen from './Routes/Profile/ProfileScreen'
import FaceScreen from './Routes/Face/FaceScreen'
import Analytics from './Code/Analytics'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from 'react-device-detect'
import rotate from './images/rotation_icon.svg'

import Config from './Common/Config'
import Firebase from './Common/Firebase'
import Errors from './Common/Errors'
import Swal from 'sweetalert2'
import RefreshOnConfigUpdate from './Decorators/RefreshOnConfigUpdate'
import SearchFolderRoute from './Routes/SearchFolder'

const ActivatedVatomView = (p) => <ComponentLoader load={cb => import('./Routes/Activated/IconFullScreen').then(mod => cb(mod, p))} />
const AR = () => <ComponentLoader load={cb => import('./Routes/AR').then(mod => cb(mod))} />
const Scanner = () => <ComponentLoader load={cb => import('./Routes/Scanner').then(mod => cb(mod))} />

const Claim = (p) => <ComponentLoader load={cb => import('./Routes/Claim').then(mod => cb(mod, p))} />
const Acquire = (p) => <ComponentLoader load={cb => import('./Routes/Acquire').then(mod => cb(mod, p))} />
const PerformAction = (p) => <ComponentLoader load={cb => import('./Routes/PerformAction').then(mod => cb(mod, p))} />

const Redeem = (p) => <ComponentLoader load={cb => import('./Routes/Redeem/Redeem').then(mod => cb(mod, p))} />
const AutoLogin = () => <ComponentLoader load={cb => import('./Routes/AutoLogin').then(mod => cb(mod))} />

export default @RefreshOnConfigUpdate class App extends Component {

    constructor() {

        super()
        Analytics.setup({ trackingID: Config.analyticsTrackingID })
        BLOCKv.WebSockets.addEventListener('inventory', this.onVatomReceived.bind(this))
        BLOCKv.WebSockets.addEventListener('websocket.rpc', this.onRPC.bind(this))

        // Setup messaging
        Firebase.setup()
    }

    componentDidMount() {
        // app title
        document.title = Config.appTitle

        // favicon
        if (Config.appIcon) {
            var link = document.createElement('link')
            link.id = 'favicon'
            link.rel = 'shortcut icon'
            link.href = Config.appIcon
            document.head.appendChild(link)

        }

        loadExternalStyles(Config.theme.css)
        updateCssVariables(Config)
        
        if (Config?.redirectToNewViewer) { 
          this.redirectToNew();
        }
    }

    redirectToNew() {

        let val = window.location.href;
        
        const urlToMake = new URL(val);
        // we only want the host part of the url
        const host = urlToMake.host;
        // constains all the parts of the url
        const splitByDot = host.split('.');
        // get first split
        const campaign = splitByDot[0];

        let redirectUrl = new URL(`https://${campaign}.nftviewer.app`);

        const beta = splitByDot.includes('beta');
        
        // return if we are accessing the old viewer directly
        if (campaign == 'vatomviewer') return

        // if beta is found, use beta url
        if (beta) {
            redirectUrl = new URL(`https://${campaign}.beta.nftviewer.app`);
        }

        // redirect to viewer
        window.location.href = redirectUrl;

    }

    render() {
        return (
            <div>
                <BrowserView>
                    <div className='App'>
                        <header />
                        <PageRoutes />
                    </div>
                </BrowserView>

                <MobileView>
                    <DeviceOrientation lockOrientation={'landscape'}>

                        {/* Will only be in DOM in landscape on mobile */}
                        <Orientation orientation='landscape' alwaysRender={false}>
                            {Config.features.showPortraitLockMessage ?
                               <MobileLandscapeMessage  /> : null
                            }
                        </Orientation>

                        {/* Will only be visible in portrait on mobile */}
                        <Orientation orientation='portrait' alwaysRender={false}>

                        </Orientation>

                    </DeviceOrientation>

                    {/* Always render the app, no matter the orientation */}
                    <div className='App'>
                        <header />
                        <PageRoutes />
                    </div>

                </MobileView>
            </div>
        )
    }

    onRPC(rpcpayload) {

        let { payload } = rpcpayload
        // Check RPC name
        if (payload.rpc !== 'request_cmd')
            return

        // Get field values
        let vatomID = payload.data.object_id
        let requestorID = payload.data.requestor_id
        if (!vatomID || !requestorID)
            return console.warn('Redeem request RPC arrived, but it was missing either the object_id or the requestor_id.')

        // Fetch vatom payload
        BLOCKv.Vatoms.getUserVatoms(vatomID).then(vatom => {

            // Check if can redeem immediately
            if (vatom[0].properties.silent_redeem !== requestorID) {

                // Can't redeem immediately, go to confirm screen
                window.location.hash = `/redeem/${vatomID}/${requestorID}`
                return

            }

            // If the user is currently looking at the vatom, go back to the inventory screen
            if (window.location.hash.indexOf(vatomID) !== -1)
                window.location.hash = '/inventory'

            return BLOCKv.Vatoms.performAction(vatomID, 'Redeem', {

                'new.owner.id': requestorID

            })

        }).catch(Errors.show)

    }

    /** @private Called when a vatom is received */
    async onVatomReceived(msg) {


        let vatomID = msg.payload && msg.payload.id

        // Check if the vatom belongs to us
        if (msg.payload && msg.payload.new_owner !== BLOCKv.store.userID) return

        // Check if we are picking up the vatom
        if ((msg.payload && msg.payload.action_name === 'Pickup') || (msg.payload && msg.payload.action_name === 'CreateAvatar')) return

        // Catch all errors
        try {

            // Skip if the user is still on the login screen
            if (window.location.hash.indexOf('/login') !== -1)
                return

            // Load vatom. This can fail due to the speed of the websocket, so we should retry a few times,
            // once per second for 10 seconds or so.
            let vatom = await BLOCKv.Vatoms.getUserVatoms(vatomID)
            if(vatom[0].properties.dropped)
              return

            // if (msg.payload.action_name === 'Acquire' || msg.payload.action_name === 'AcquirePubVariation') {

                // Analytics.event('performAction', {
                //     'actionUri': msg.payload.action_name,
                //     'digitalObjectId': vatomID,
                //     'vatomId': vatomID,
                //     'eventValue': 1,
                //     'event': 'performAction'
                // }, vatom[0])

            // }

            // Make sure the vatom is not inside a folder
            if (!vatom[0])
                throw new Error('Inconsistency error: Incoming vatom event received, but vatom was not found.')
            if (vatom[0].properties.parent_id !== '.')
                return console.log("Incoming vatom detected, but it's in a folder. Ignored.")

            // If we're not backgrounded, show the overlay
            if (!document.hidden) {

                // Show overlay
                IncomingOverlay.show(vatom[0])
                return

            }

            // Removed: Notifications handled by Firebase now.

        } catch (err) {

            // Failed
            console.warn('[Notification] An error occurred. ', err)

        }

    }

}

const MobileLandscapeMessage = () => {
    var containerStyle = {

        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 9999,
        backgroundColor: 'white',
        color: '#939393',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    }

    var textStyle = {
        textAlign: 'center',
        marginTop: '0',
        marginBottom: '0',
        width: '380px'
    }

    return (
        <div style={containerStyle}>
            <div>
                <RotateImage />
            </div>
            <div>
                <p style={textStyle}>
                    This experience is optimized for portrait viewing only.
                </p>
                <p style={textStyle}>
                    Please rotate your device.
                </p>
            </div>

        </div>
    )
}

const RotateImage = () => {
    var style = {
        height: '99px',
        width: '99px',
        marginBottom: '30px'
    }
    return (
        <img
            src={rotate}
            alt='image'
            style={style}
        />
    )
}

const PageRoutes = () => {
  return (
      <HashRouter>
          <Switch>
              <Route path='/login' component={LoginScreen} />
              <Route path='/register' component={RegisterScreen} />
              <Route path='/inventory' render={(props) => <InventoryScreen {...props} key={'1'} walletTab={'inventory'} />}  />
              <Route path='/coins' render={(props) => <InventoryScreen {...props} key={'2'} walletTab={'coins'} />}  />
              <Route path='/map' component={MapScreen} />
              <Route path='/ar' component={AR} />
              <Route path='/scanner' component={Scanner} />
              <Route path='/profile' component={ProfileScreen} />
              <Route path='/face/:id' component={FaceScreen} />
              <Route path='/vatom/:vatom' render={props => <ActivatedVatomView key={props.match.params.vatom} {...props} />} />
              <Route path='/search-folder/:vatom' render={props => <SearchFolderRoute key={props.match.params.vatom} {...props} />} />
              <Route path='/redeem/:vatom/:requestor' component={Redeem} />

              {/* Deep linking screens */}
              <Route path='/claim/:vatom' component={Claim} />
              <Route path='/acquire/:vatom' component={Acquire} />
              <Route path='/perform-action/:action/:vatom' component={PerformAction} />

              <Route path='/autologin' component={AutoLogin} />
              <Route path='/logout' component={() => <div>Logout will occur here!</div>} />
              <Route component={LoginScreen} />
          </Switch>
      </HashRouter>
  )
}

function loadExternalStyles(cssURL) {
    let link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = cssURL
    document.head.appendChild(link)
}

function updateCssVariables(Config) {
    let root = document.documentElement
    const popupFontFamily = Config.theme.vatomPopUp.buttonFont
    const popupFontColor = Config.theme.vatomPopUp.buttonFontColor

    root.style.setProperty('--popup-font-family', popupFontFamily)
    root.style.setProperty('--popup-font-color', popupFontColor)
}
