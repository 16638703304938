import React from 'react'

const MoreButton = ({
    style = {},
    stroke = '#888888',
    width = '23px',
    height = '5px',
    className = '',
    viewBox = '0 0 23 5'

}) => {

    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>More</title>
            <desc>More Button</desc>
            <defs></defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                <g id="Activated-State-Icon-Top-Bar" transform="translate(-72.000000, -25.000000)">
                    <g id="Top-Bar">
                        <g id="3-Dots-24-Dark-Blue" transform="translate(72.000000, 16.000000)">
                            <g>
                                <rect id="Rectangle-2" x="0" y="0" width="24" height="24"></rect>
                                <circle id="Oval-3" fill={stroke} cx="2.5" cy="11.5" r="2.5"></circle>
                                <circle id="Oval-3-Copy" fill={stroke} cx="11.5" cy="11.5" r="2.5"></circle>
                                <circle id="Oval-3-Copy-2" fill={stroke} cx="20.5" cy="11.5" r="2.5"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default MoreButton
