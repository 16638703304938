
import React from 'react'
import Popup from '../Popup'
import BLOCKv from '../../Common/Blockv'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import swal from 'sweetalert'
import Errors from '../../Common/Errors'
import MediaQuery from 'react-responsive'
import MergePopup from '../MergePopup'
export default class AddEmailPopup extends Popup {

    constructor(props) {
        super(props)

        // Setup vars
        this.state = {}
        this.state.email = props.preloadEmail || ''
        this.state.emailError = ''
        this.state.verifyStep = props.verifyStep || false
        this.state.verifyCode = ''
        this.state.verifyCodeError = ''
        this.state.skipVerify = props.skipVerify || false

    }

    async componentDidMount() {

        // Send verify code if we're starting on that screen
        if (this.state.verifyStep)
            this.verify()

    }

    render() {
        const { mainText, secondaryText } = this.props;

        return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', textAlign: 'center', overflowX: 'hidden', overflowY: 'hidden', WebkitOverflowScrolling: 'touch' }}>

            {/* UI when entering phone number */}
            <div style={{ display: this.state.verifyStep ? 'none' : 'block' }}>

                {/* Logo */}
                <div style={{ padding: '40px 0px' }}>
                    <MediaQuery query="(min-width: 1024px)">
                        <img src={require('./envelope.svg')} style={{ height: 128 }} />
                    </MediaQuery>
                </div>

                {/* Title */}
                <div style={{ fontSize: 21, color: '#444', marginBottom: 10 }}>{mainText || 'Add your email'}</div>
                <div style={{ fontSize: 15, color: '#444', margin: '0px 40px 40px 40px' }}>{secondaryText || 'Enter email address below.'}</div>

                {/* Fields */}
                <div style={{ padding: '0px 40px' }}>
                    <TextField fullWidth autoFocus variant="outlined" margin="dense" label={this.state.emailError || 'Email address'} type='email' error={!!this.state.emailError} value={this.state.email} onChange={e => this.setState({ email: e.target.value, emailError: '' })} />
                </div>

                {/* Buttons */}
                <div style={{ textAlign: 'center', margin: 20 }}>
                    <Button variant="outlined" style={{ marginRight: 20 }} onClick={e => this.close()}>Cancel</Button>
                    <Button variant="outlined" color="primary" onClick={e => this.verify()}>Send code</Button>
                </div>

            </div>

            {/* UI when verifying email */}
            <div style={{ visibility: this.state.verifyStep ? 'visible' : 'hidden' }}>

                {/* Logo */}
                <div style={{ padding: '40px 0px' }}>
                    <MediaQuery query="(min-width: 1024px)">
                        <img src={require('./mail-2.svg')} style={{ height: 128 }} />
                    </MediaQuery>
                </div>

                {/* Title */}
                <div style={{ fontSize: 21, color: '#444', marginBottom: 10 }}>Verify your email</div>
                <div style={{ fontSize: 15, color: '#444', margin: '0px 40px 40px 40px' }}>Enter the code we sent you.</div>

                {/* Fields */}
                <div style={{ padding: '0px 40px' }}>
                    <TextField fullWidth autoFocus variant="outlined" margin="dense" label={this.state.verifyCodeError || 'Enter code'} error={!!this.state.verifyCodeError} value={this.state.verifyCode} onChange={e => this.setState({ verifyCode: e.target.value, verifyCodeError: '' })} />
                </div>

                {/* Buttons */}
                <div style={{ textAlign: 'center', margin: 20 }}>
                    <Button variant="outlined" style={{ margin: 5 }} onClick={e => this.setState({ verifyStep: false })}>Back</Button>
                    <Button variant="outlined" style={{ margin: 5 }} onClick={e => this.verify()}>Resend</Button>
                    <Button style={{margin: 5}}variant="outlined" color="primary" onClick={e => this.verifyCode()}>Verify</Button>
                </div>

            </div>

        </div>

    }

    /** Verify phone */
    verify() {

        // Check email
        if (this.state.email.length < 3 || this.state.email.indexOf('@') === -1)
            return this.setState({ emailError: 'Not valid' })

        // Add the email to the profile
        swal({ buttons: false, text: 'Sending verification code...' })
        BLOCKv.client.request('POST', '/v1/user/tokens', { token: this.state.email, token_type: 'email' }, true).catch(err => {

            // Check if the email already existed on the profile
            if (err.code == 521) {

                // Check if this token exists on our account already
                let ourTokens = this.props.allTokens || []
                let isOurToken = ourTokens.find(t => t.properties.token.toLowerCase() == this.state.email.toLowerCase())
                if (isOurToken) {

                    // Token is on our account, the user wants to verify it, send the verification code
                    return BLOCKv.UserManager.sendTokenVerification(this.state.email, 'email')

                } else {

                    // Token is on another account, take the user to the merge account popup
                    MergePopup.show({ token: this.state.email, onComplete: this.props.onComplete })
                    this.close()

                }

            } else {

                // Rethrow error
                throw err

            }

        }).then(e => {

            // Move to verify screen
            swal.close()

            // vlabs
            if ( this.state.skipVerify ) {
                this.close()
                if (this.props.onComplete) this.props.onComplete()
                return
            }

            this.setState({ verifyStep: true })

        }).catch(err => {

            // Failed!
            swal.close()
            Errors.show(err)

        })

    }

    /** Verify code */
    verifyCode() {

        // Check code
        if (this.state.verifyCode.length < 4)
            return this.setState({ verifyCodeError: 'Too short' })

        // Send verify code
        swal({ buttons: false, text: 'Verifying email...' })
        BLOCKv.UserManager.verifyUserToken({ 'token': this.state.email, 'token_type': 'email', 'verify_code': this.state.verifyCode }).then(e => {

            // Success
            swal.close()
            this.close()

            // Show success
            swal('Email address added', 'Your email was verified successfully.', 'success')

            // Notify complete
            if (this.props.onComplete)
                this.props.onComplete()

        }).catch(err => {

            // Failed!
            swal.close()
            Errors.show(err)

        })

    }

}
