
import React from 'react'
import { JSFile } from 'js-file-manager'
import axios from 'axios'
import Config from '../../Common/Config'
import Swal from 'sweetalert2'
import getEnvironmentCamera from '../../Code/getEnvironmentCamera'

// NOTE: `webpack-serve` is broken to all hell! The hot module replacement plugin is broken inside workers, but the
// --no-hot-client flag on webpack-serve doesn't work. As a temporary workaroud, when debugging,
// add `options.hotClient = false` after the `if (hotClient === true...` check in node_modules/webpack-serve/lib/options.js:80
// eslint-disable-next-line
// import QRProcessWorker from 'worker-loader?inline!./QRProcessor.worker.js'

/** The token for Catchoom */
const CATCHOOM_TOKEN = process.env.CATCHOOM

/** This component displays the camera, allowing users to scan content. */
export default class ScannerView extends React.Component {

    constructor() {

        super()

        // Setup state
        this.state = {}
        this.state.isLoading = true
        this.state.error = null

        // Reference to the video player element
        this.player = null

        // Temporary canvas, to convert video to raw pixels
        this.canvas = document.createElement('canvas')
        this.canvasCtx = this.canvas.getContext('2d')

        // While `true` will try to detect QR code every frame.
        this.shouldLoop = true

        // Used for image recognition
        this.lastImageRecognition = 0
        this.isRecognizingImage = false

        // Bind loop function, so that we don't have to create a new bound function every iteration
        this.loop = this.loop.bind(this)

    }

    /** @private Called when the view is loaded */
    async componentDidMount() {

        // Start canvas detection loop
        this.shouldLoop = true
        this.loop()

        // Start loading
        this.setState({ error: null, isLoading: true })
        console.log('Scanner loading...')

        // Catch errors
        try {

            // Create web worker
            // console.log(QRProcessWorker)
            this.processor = new Worker('./QRProcessor.worker.js')
            this.processor.addEventListener('message', this.onMessageFromWorker.bind(this))


            // Get access to the camera
            this.stream = await getEnvironmentCamera()

            // Display video stream in the player
            this.player.srcObject = this.stream

            // Update canvas to be the same size as the video stream
            let videoTrack = this.stream.getVideoTracks()[0]
            let videoSettings = videoTrack.getSettings()
            this.canvas.width = videoSettings.width
            this.canvas.height = videoSettings.height
            console.log(`Began processing video feed of size ${videoSettings.width}x${videoSettings.height}`)

            // Done
            this.setState({ isLoading: false })

        } catch (err) {

            // Translate known errors into something more friendly
            if (err.message.includes("request is not allowed"))     err = new Error('Please check your camera permissions.')

            // Failed! Show error
            this.setState({ error: err, isLoading: false })
            console.error(err)

        }

    }

    /** @private Called when the view is being removed */
    componentWillUnmount() {

        // Stop loop
        this.shouldLoop = false

        // Stop all tracks in the stream
        for (let track of this.stream?.getTracks() || []) track.stop()

        // Cleanup
        this.stream = null
        this.player = null

    }

    /** @private Called every frame, to detect QR codes */
    loop() {

        // Stop if needed
        if (!this.shouldLoop) return

        // Do again next frame
        requestAnimationFrame(this.loop)

        // Check if got the stream
        if (!this.stream) return

        // Stop if processor is busy
        if (this.processorBusy) return

        // Draw frame into canvas
        this.canvasCtx.drawImage(this.player, 0, 0, this.canvas.width, this.canvas.height)

        // Extract image pixel data
        let imageData = this.canvasCtx.getImageData(0, 0, this.canvas.width, this.canvas.height)

        // Send image data to the web worker
        this.processorBusy = true
        this.processor.postMessage({
            action: 'detect',
            width: imageData.width,
            height: imageData.height,
            data: imageData.data.buffer
        }, [imageData.data.buffer])

        // Stop if not allowed to do image recognition
        if (this.props.noImageRecognition) return

        // Stop if not enough time has passed since our last image recognition attempt
        if (this.isRecognizingImage || this.lastImageRecognition + 1000 * 1.5 > Date.now()) return

        // Do image recognition!
        this.isRecognizingImage = true

        // Convert canvas to a jpg blob
        let dataURI = this.canvas.toDataURL('image/jpeg', 0.5)
        JSFile.fromURL(dataURI).getBlob().then(file => {

            // Send file to Catchoom
            let form = new FormData()
            form.set('token', CATCHOOM_TOKEN)
            form.set('image', file)
            return axios.post('https://search.craftar.net/v1/search', form, '@query.jpg')

        }).then(response => {
            //alert( JSON.stringify(response.data) );

            // Done
            this.isRecognizingImage = false
            this.lastImageRecognition = Date.now()

            // Throw errors
            if (response.data.error) {

                let err = new Error(response.data.error.message)
                err.code = response.data.error.code
                throw err

            }

            // Stop if no results
            if (!response.data.results || response.data.results.length === 0) return

            // Use content of first result, if any
            let url = response.data.results[0].item.url
            if (!url) return

            // Process link
            this.processLink(url)

        }).catch(err => {

            // Image recognition error
            console.warn('Image recognition error', err)
            this.isRecognizingImage = false
            this.lastImageRecognition = Date.now()

        })

    }

    /** @private Called when a message is received from the worker */
    onMessageFromWorker(e) {

        // Got response from image processor
        if (e.data.action === 'complete') {

            // Done
            this.processorBusy = false

        } else if (e.data.action === 'result') {

            // Ignore if no result
            if (!e.data.value) return

            // Ignore if this is the same as the previous scanned result
            if (e.data.value === this.lastScan) return
            this.lastScan = e.data.value

            // Found a value! Process it
            this.processLink(e.data.value)

        }

    }

    /** @private Called when a link is discovered and we should process / execute it */
    processLink(link) {

        // Pass to listener
        this.props.onContent(link)

    }

    /** @private Render UI components */
    render() {

        return <div style={Object.assign({ position: 'relative', backgroundColor: '#444' }, this.props.style)}>

            {/* Stream container */}
            <video autoPlay muted playsInline ref={r => this.player = r} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />

            {/* Display message if still loading */}
            {this.state.isLoading
                ? <Center><div style={{ padding: 40, color: '#FFF', fontSize: 17, textAlign: 'center' }}>Loading...</div></Center>
                : null}

            {/* Display error message if needed */}
            {this.state.error
                ? <Center>
                    <div style={{ textAlign: 'center' }}><img src={require('./warning.svg')} style={{ width: 48, height: 48 }} /></div>
                    <div style={{ padding: 20, paddingBottom: 0, color: '#FFF', fontSize: 17, textAlign: 'center' }}>Unable to Scan</div>
                    <div style={{ padding: 20, paddingTop: 8, color: '#DDD', fontSize: 15, textAlign: 'center' }}>{this.state.error.message}</div>

                    {/* If on Chrome on iOS, show Open in Safari button */}
                    {navigator.userAgent.includes("CriOS/") ?
                        <div style={{ padding: 20, paddingTop: 8, margin: '0px 40px', color: '#DDD', fontSize: 15, textAlign: 'center' }}>
                            <a href={location.href} style={{ color: '#49E' }} onClick={copyURL}>Tap here</a> to copy the URL, and then paste it into the address bar in Safari.
                        </div>
                    : null}

                </Center>
                : null}

        </div>

    }

}

/** This component displays UI in the center of the container */
const Center = props => <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div>{props.children}</div>
</div>

/** @private Copies the URL of this page to the clipboard */
function copyURL(e) {
    e.preventDefault()

    // HACK: Copy text
    // From: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
    const el = document.createElement('textarea');
    el.value = location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Inform user
    Swal.fire("URL copied", "You can now open Safari and paste it into the address bar.", 'success')

}
