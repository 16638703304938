
import EventEmitter from './EventEmitter'

/**
 *  This singleton class manages interaction with the system in order to install the app onto the user's device.
 *
 *  @event readyToInstall Triggered when the browser is ready to install our web app to the device
 *  @event installed Triggered after the app is installed onto the device.
 */
/* eslint-disable-next-line */
export default new class AppInstallManager extends EventEmitter {
    // Constructor
    constructor() {

        super()

        /** Contains the deferred install event */
        this.installEvent = null

    }

    /** Returns true if we've able to show the install popup */
    get canInstall() {

        return !!this.installEvent

    }

    /** Returns true if the app should show the install prompt to the user */
    get shouldShowInstallPrompt() {

        return this.canInstall && Date.now() > this.hideUntil

    }

    /** Returns the date that the install prompt should be hidden until */
    get hideUntil() {

        try {

            return parseInt(localStorage['app.installPrompt.hideUntil']) || 0

        } catch (e) {

            return 0

        }

    }

    /** Hides the install prompt until the specified date */
    set hideUntil(v) {

        try {

            localStorage['app.installPrompt.hideUntil'] = v

        } catch (e) {}

    }

    /** Call this on app startup */
    setup() {

        // Only do once
        if (this.isSetup) return
        this.isSetup = true

        // Add event listeners
        window.addEventListener('beforeinstallprompt', this.beforeInstallPrompt.bind(this))
        window.addEventListener('appinstalled', this.onInstalled.bind(this))

    }

    /** @private Called by the browser when it has deemed our app worthy of allowing to be installed to the home screen. */
    beforeInstallPrompt(e) {

        // Prevent browser's default popup
        console.log('[AppInstallManager] Browser has given us access to the native install prompt')
        e.preventDefault()

        // Store it for triggering the prompt later
        this.installEvent = e

        // Notify listeners
        this.emit('readyToInstall')

    }

    /**
   *  Shows the native install prompt to the user.
   *
   *  @returns {Promise<bool>} True if installed, false if cancelled.
   */
    async install() {

        // Throw if no promt has bbeen given to us yet
        if (!this.installEvent)
            throw new Error('No install event available.')

        // We can only prompt once. Discard after this.
        var event = this.installEvent
        this.installEvent = null

        // Show prompt to user
        event.prompt()

        // Wait for the user's choice
        var result = await event.userChoice

        // Return the result
        return result.outcome === 'accepted'

    }

    /** @private Called by the browser when the app is successfully installed. */
    onInstalled() {

        this.emit('installed')

    }

}
