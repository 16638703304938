
import { VatomView, FaceSelection } from '@blockv/sdk/face'
import _ from 'lodash'

class LiveVatomView extends VatomView {

    constructor(bv, vAtom, FSP, config) {

        // HACK: Custom FSP for the card view, to select the old `fullscreen` faces as well
        if (FSP === FaceSelection.Card)
            FSP = e => vAtom.faces.find(f => f.properties.constraints.view_mode === 'card') || vAtom.faces.find(f => f.properties.constraints.view_mode === 'fullscreen')

        // Create a new error view which doesn't show the warning triangle, if disabled in the config
        if (require('./Config').vatomWarningIcons === false) {

            // Create warningless error view
            config.errorView = config.errorView || function(bvi, v, err) {

                let con = document.createElement('div')
                const rs = v.properties.resources.find(r => r.name === 'ActivatedImage')
                const du = rs && bvi.UserManager.encodeAssetProvider(rs.value.value)
                con.style.backgroundSize = 'contain'
                con.style.backgroundPosition = 'center'
                con.style.backgroundRepeat = 'no-repeat'
                con.style.backgroundImage = `url('${du}')`
                con.style.width = '100%'
                con.style.height = '100%'
                return con

            }

        }

        // Init superclass
        super(bv, vAtom, FSP, config)

    }
    free() {

        super.free()

    }
    /** Call to find out if the specified vatom can be combined into our one. @returns Boolean */
    canCombineWith(otherVatom) {

        // Check if the face wants to override the value
        var value = this._currentFace && this._currentFace.canCombineWith && this._currentFace.canCombineWith(otherVatom)
        if (typeof value === 'boolean')
            return value
        else
            return this.vatomObj.canCombineWith(otherVatom)

    }

    /** Called to combine the specified vatom into this one. @returns Promise. */
    combineWith(otherVatom) {

        // Check if face wants to handle this
        var value = this._currentFace && this._currentFace.canCombineWith && this._currentFace.canCombineWith(otherVatom)
        if (value === true)
            return Promise.resolve().then(e => this._currentFace.combineWith(otherVatom))
        else

            return Promise.resolve().then(e => this.blockv.Vatoms.combineWith(this.vatomObj, otherVatom))

    }

}
export default LiveVatomView
