import React from 'react'
import lifecycle from 'react-pure-lifecycle'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import BLOCKv from '../Common/Blockv'
import Loader from '../Common/Loader'
import dayjs from 'dayjs'

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    inline: {
        display: 'inline'
    }
})

class ActivityItem extends React.Component {

    constructor() {

        super()
        console.log('INSTANCE : ', BLOCKv)

    }
    state = {
        message: [],
        avatar: [],
        time: [],
        loaded: false
    };

    componentDidMount() {

        let msg
        BLOCKv
            .Activity
            .myThreads()
            .then(m => {

                let message = []
                let avatar = []
                let time = []
                for (msg of m) {

                    let lastMessage = msg.last_message.msg
                    let d = new Date(msg.last_message.when_created)
                    let dateFormatted = dayjs(d).format('YYYY-MM-DD HH:mm:ss')
                    let userAvatar = BLOCKv
                        .UserManager
                        .encodeAssetProvider(msg.last_message_user_info.avatar_uri)
                    time.push(dateFormatted)
                    message.push(lastMessage)
                    avatar.push(userAvatar)
                    console.log(time)

                }
                this.setState({ loaded: true, message, avatar, time })

            })
            .catch(error => {

                this.setState({ error, loaded: true })
                console.log('An error has occurred : ', error)

            })

    };

    render() {

        var numMessages = 10
        const msgs = this
            .state
            .message
            .slice(0, numMessages)
            .map((msg, idx) => {

                return (
                    <ListItem alignItems="flex-start">

                        <ListItemAvatar>
                            <Avatar alt="Avatar" src={this.state.avatar[idx]} />
                        </ListItemAvatar>

                        <ListItemText
                            primary={<Typography
                                component="span"
                                color="textPrimary"
                                dangerouslySetInnerHTML={{
                                    __html: msg
                                }} />}
                            secondary={this.state.time[idx]} />
                    </ListItem>
                )

            })
        if (this.state.message.length > 0 || this.state.loaded) {

            return (
                <List>
                    {this.state.error
                        ? <div style={{ margin: 20 }}>{this.state.error.message}</div>
                        : null}
                    {msgs}
                </List>

            )

        }

        return (
            <div style={{ display: 'block', width: '100%' }}>
                <Loader style={{ display: 'block', marginLeft: '50%' }} />
            </div>
        )

    }

};

export default withStyles(styles)(ActivityItem)
