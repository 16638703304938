
import React from 'react'
import Popup from '../Popup'
import BLOCKv from '../../Common/Blockv'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import swal from 'sweetalert'
import Errors from '../../Common/Errors'

export default class SetNamePopup extends Popup {

    constructor(props) {

        super(props)

        // Setup vars
        this.state = {}
        this.state.firstName = props.preloadFirstName || ''
        this.state.firstNameError = ''
        this.state.lastName = props.preloadLastName || ''
        this.state.lastNameError = ''

    }

    render() {

        return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>

            {/* Logo */}
            <div>
                <img src={require('./user.svg')} style={{ height: 128, margin: '40px 0px' }} />
            </div>

            {/* Title */}
            <div style={{ fontSize: 21, color: '#444', marginBottom: 10 }}>Set Name</div>
            <div style={{ fontSize: 15, color: '#444', margin: '0px 40px 40px 40px' }}>Enter your first and last name.</div>

            {/* Fields */}
            <div style={{ padding: '0px 40px' }}>
                <TextField fullWidth variant="outlined" margin="dense" label={this.state.firstNameError || 'First name'} error={!!this.state.firstNameError} value={this.state.firstName} onChange={e => this.setState({ firstName: e.target.value, firstNameError: '' })} autoFocus />
                <TextField fullWidth variant="outlined" margin="dense" label={this.state.lastNameError || 'Last name'} error={!!this.state.lastNameError} value={this.state.lastName} onChange={e => this.setState({ lastName: e.target.value, lastNameError: '' })} />
            </div>

            {/* Buttons */}
            <div style={{ textAlign: 'center', margin: 20 }}>
                <Button variant="outlined" style={{ marginRight: 20 }} onClick={e => this.close()}>Cancel</Button>
                <Button variant="outlined" color="primary" onClick={e => this.save()}>Save</Button>
            </div>

        </div>

    }

    /** Save new password */
    save() {

        // Make sure data is ok
        if (this.state.firstName.length < 2) return this.setState({ firstNameError: 'Too short' })

        // Save password
        swal({ buttons: false, text: 'Saving name...' })
        BLOCKv.UserManager.updateUser({ first_name: this.state.firstName, last_name: this.state.lastName }).then(e => {

            // Success
            swal.close()
            this.close()

            // Show success
            swal('Name changed', 'Your name was changed successfully.', 'success')

            // Notify complete
            if (this.props.onComplete)
                this.props.onComplete()

        }).catch(err => {

            // Failed!
            swal.close()
            Errors.show(err)

        })

    }

}
