/* global Analytics */
// Error handling

// TODO: Link directly to the error-spec repo once it's ready
// Errors from https://github.com/BLOCKvIO/error-spec/master/Errors.json
import ErrorList from './Errors.json'
import swal2 from 'sweetalert2'

import closeVatomIcon from '../images/close-vatom-icon.svg'
import './variables.css'
import './sweet-alert-2.css'

export default class Errors {

    // Show the specified error to the user
    static async show(error, vatom, vatomUrl, customAlert, customAction = null) {
        const title = vatom?.properties?.title || 'Vatom'
        // Log to console
        console.warn(error)

        // Find error details in our table, or use a generic error message
        var detail = ErrorList.find(e => e.code === error.code)

        // Create footer button
        var footer = document.createElement('div')
        footer.style.cssText = 'font-size: 15px; color: #08F; cursor: pointer; '
        footer.innerText = `See more details`
        footer.addEventListener('click', e => {

            swal2.close()
            Errors.showDetail(error, detail)

        })

        // Check for custom error text from config
        let customError = require('./Config').customErrors?.find(e => e.code == error.code)

        // Show error
        switch(customAlert) {
            case 'mapPickupOutOfRange':
                    await swal2.fire({
                        customClass: {
                            container: 'container-class',
                            popup: 'popup-class',
                            header: 'header-class',
                            title: 'title-class',
                            closeButton: 'close-button-class',
                            icon: 'icon-class',
                            image: 'image-class',
                            content: 'content-class',
                            input: 'input-class',
                            actions: 'actions-class',
                            confirmButton: 'confirm-button-class',
                            cancelButton: 'cancel-button-class',
                            footer: 'footer-class'
                        },
                        html: detail?.client?.message?.body || (error.code ? `We couldn't perform the requested action.` : error.message),
                        title: (vatom.properties.title).toUpperCase(),
                        showConfirmButton: false,
                        showCloseButton: true,
                        closeButtonHtml: `<img src=${closeVatomIcon} alt='close me'>`,
                        imageUrl: vatomUrl,
                        footer: error.code ? footer : null,
                })
            break;

          default:
            let customErrorText = customError?.text.replace('{{title}}', title) || null
            
            if(error.code == 13 && customAction == 'pickup') {
              detail.client.message.title = "Oops!"
              detail.client.message.body = `You cannot pickup this ${title}`
            }
            
            await swal2.fire({
                titleText: customError?.title || detail?.client?.message?.title || 'There was a problem',
                text: customErrorText || detail?.client?.message?.body || (error.code ? `We couldn't perform the requested action.` : error.message),
                type: 'error',
                footer: error.code ? footer : null
            })

        }

        // Show error

        // Log analytics
        window.Analytics.error(error)

    }

    // Show detailed information about the error. This only gets called for BLOCKv errors that have a code
    static showDetail(error, detail) {

        // Show error
        swal2.fire({
            icon: 'error',
            titleText: 'Error details',
            html: `
                <table style='text-align: left; font-size: 12px; '>
                    <tr><td style='width: 100px; vertical-align: top; '><b>HTTP code</b></td><td>${error.httpStatus || '(none)'}</td></tr>
                    <tr><td style='width: 100px; vertical-align: top; '><b>Response code</b></td><td>${error.code || '(none)'}</td></tr>
                    <tr><td style='width: 100px; vertical-align: top; '><b>Response</b></td><td>${error.serverMessage || error.message || '(none)'}</td></tr>
                    <tr><td style='width: 100px; vertical-align: top; '><b>Request ID</b></td><td>${error.requestID || '(none)'}</td></tr>
                    <tr><td style='width: 100px; vertical-align: top; '><b>Message</b></td><td>${error.message || '(none)'}</td></tr>
                </table>
            `
        })

    }

}
