//
// Overlay view

import React from 'react'
import ConfettiLoader from './ConfettiLoader'
import LiveVatomViewContainer from '../../Components/LiveVatomViewContainer'
import JSAlert from 'js-alert'
import BLOCKv from '../../Common/Blockv'
import MediaQuery from 'react-responsive'
import Errors from '../../Common/Errors'
import Analytics from '../../Code/Analytics'
import Config from '../../Common/Config'

export default class IncomingOverlayView extends React.Component {

    constructor(props) {

        super(props)

        // Set variables
        this.state = {}
        this.state.visible = false
        this.state.loading = true
        this.state.fromName = null
        this.state.fromAvatar = null

        if (location.hash === '#/profile')
            location.hash = '/inventory'

    }

    /** @private Called by React when the component is displayed */
    componentDidMount() {

        // Log analytics
        Analytics.screen('IncomingOverlay')

        // Load previous user's info
        let fromUser = this.props.vatom.properties.transferred_by || this.props.vatom.properties.author
        if (!fromUser)
            return this.setState({ fromName: 'User', fromAvatar: null })

        // Fetch user info
        BLOCKv.UserManager.getPublicUserProfile(fromUser).then(user => {

            let avatar = BLOCKv.UserManager.encodeAssetProvider(user.properties.avatar_uri)

            // Update UI
            this.setState({ fromName: user.properties.first_name + ' ' + user.properties.last_name, fromAvatar: avatar })

        }).catch(err => {

            // Unable to get user details
            console.warn('Unable to fetch user info', err)
            this.setState({ fromName: 'User', fromAvatar: null })

        })

    }

    render() {

        // Background container
        return <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: '9999',
            overflow: 'hidden',
            opacity: this.state.visible ? '1' : '0',
            transition: 'opacity 1s',
            backgroundColor: 'rgba(20, 20, 20, 0.9)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)'
        }}>

            {/* Loading animation */}
            {Config.features.confetti ? <ConfettiLoader loading={this.state.loading} /> : null}

            {/* Vatom view container */}
            <div style={{
                position: 'absolute',
                top: 64,
                left: 0,
                width: '100%',
                height: 'calc(100% - 64px)',
                transform: (this.state.visible && !this.state.loading) ? 'translateY(0px)' : 'translateY(-100vh)',
                transition: 'transform 1s'
            }} onClick={this.onClicked.bind(this)}>

                {/* Vatom view */}
                <LiveVatomViewContainer vatom={this.props.vatom} style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}/>

            </div>

            {/* Top header bar */}
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 64, display: 'flex', backgroundColor: 'rgba(20, 20, 20, 0.75)', alignItems: 'center' }}>

                {/* Avatar image */}
                <div style={{
                    width: 32,
                    height: 32,
                    margin: '0px 20px',
                    flex: '0 0 auto',
                    backgroundColor: '#CCC',
                    borderRadius: 16,
                    backgroundSize: 'cover',
                    backgroundImage: 'url(' + this.state.fromAvatar + ')',
                    backgroundPosition: 'center'
                }} />

                {/* Text */}
                <div style={{
                    flex: '1 1',
                    color: '#FFF',
                    fontSize: 15,
                    fontWeight: 'bold',
                    textAlign: 'left'
                }}>
                    {this.state.fromName ? this.state.fromName + ' sent you a vAtom.' : ''}
                </div>

                {/* Close button */}
                <div style={{
                    width: 64,
                    height: '100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(' + require('./Close.svg') + ')',
                    backgroundSize: '20px 20px',
                    cursor: 'pointer',
                    flex: '0 0 auto',
                    fill: '#ffffff'
                }} onClick={this.onClose.bind(this)}>
                    <MediaQuery query="(min-width: 1024px)">
                        <span style={{ display: 'block', color: '#ffffff', position: 'absolute', right: '64px', top: '50%', transform: 'translateY(-50%)' }}>close</span>
                    </MediaQuery>
                </div>

            </div>

        </div>

    }

    onReadyToDisplay() {

        // Show
        this.setState({
            loading: false
        })

        // Auto hide soon
        if (this.state.visible)
            this.autoHideTimer = setTimeout(this.onClose.bind(this), 3000)

    }

    onLoadFailed(errorCode, errorText) {

        // Show error
        var error = new Error(errorText)
        error.code = errorCode
        Errors.show(error)

        // Hide
        this.setState({
            loading: false,
            visible: false
        })

    }

    /** Called when the user closes us */
    onClose() {

        // Hide view
        this.setState({ visible: false })

        // Notify listener
        setTimeout(_ => {

            if (this.props.onClosed)
                this.props.onClosed()

        }, 1000 * 1)

        // Stop auto hide timer
        if (this.autoHideTimer)
            clearTimeout(this.autoHideTimer)

    }

    /** Called when the user clicks on the vAtom */
    onClicked() {

        // Close us
        this.onClose()

        // Go to the vAtom's activated view
        window.location.hash = '/vatom/' + this.props.vatom.id

    }

}
