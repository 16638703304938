import BaseFace from '@blockv/sdk/face/faces/BaseFace'

/** Displays a sprite sheet vatom */
export default class SpriteSheetFace extends BaseFace {

    /** Native face URL */
    static url = 'native://sprite-sheet'

    /** Called on load */
    async onLoad() {

        // Extract configuration
        let config = this.face.properties.config || this.vatom.private
        let spriteAnimations = config.sprite_animations || {}
        this.animationRules = config.animation_rules || []
        this.animations = spriteAnimations.animations || []
        this.frame_width = spriteAnimations.frame_width
        this.frame_height = spriteAnimations.frame_height
        this.frame_spacing_x = spriteAnimations.frame_spacing_x || 0
        this.frame_spacing_y = spriteAnimations.frame_spacing_y || 0
        this.frame_rate = spriteAnimations.frame_rate || 30
        this.frame_count = spriteAnimations.frame_count
        this.currentFrameIndex = 0
        this.currentAnimation = null
        this.slideToFrame = -1

        // Check fields
        if (this.animationRules.length === 0) throw new Error('No animation rules found.')
        if (this.animations.length === 0) throw new Error('No animations found.')
        if (!this.frame_width || !this.frame_height) throw new Error('Frame width and height is not set.')

        // Find sprite sheet image
        let resource = null
        if (config.resource_name)
            resource = this.vatom.properties.resources.find(im => im.name === config.resource_name)

        // If not found, use the first entry in the resources array (deprecated)
        if (!resource && this.face.properties.resources && this.face.properties.resources.length > 0)
            resource = this.vatom.properties.resources.find(im => im.name === this.face.properties.resources[0])

        // If still not found, use the resource called 'SpriteSheet'
        if (!resource)
            resource = this.vatom.properties.resources.find(im => im.name === 'SpriteSheet')

        // If still not found, fail
        if (!resource)
            throw new Error('Unable to find a SpriteSheet resource.')

        // Create canvas
        this.canvas = document.createElement('canvas')
        this.canvas.style.cssText = 'position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; '
        this.canvasContext = this.canvas.getContext('2d')
        this.element.appendChild(this.canvas)

        // Load sprite sheet
        let url = await this.vatomView.blockv.UserManager.encodeAssetProvider(resource.value.value)
        this.spriteSheet = await new Promise((resolve, reject) => {

            let img = new Image()
            img.onload = e => resolve(img)
            img.onerror = e => reject(new Error('Unable to load sprite sheet image from the network.'))
            img.crossOrigin = 'Anonymous'
            img.src = url

        })

        // Add resize listener
        window.addEventListener('resize', this.onResize)
        this.onResize()

        // Add click handler
        this.canvas.addEventListener('touchstart', this.onClick.bind(this))
        this.canvas.addEventListener('mousedown', this.onClick.bind(this))

        // Start frame loop
        this.loopTimer = setInterval(this.onFrameLoop.bind(this), 1000 / this.frame_rate)

        // Trigger first animation rule, on "start"
        let rule = this.animationRules.find(rule => rule.on === 'start')
        this.currentAnimation = rule && this.animations.find(anim => anim.name === rule.play)
        if (this.currentAnimation) {

            // Animation rule exists! Immediately move to it's start frame.
            this.currentFrameIndex = this.currentAnimation.frame_start || 0
            this.render()

        }

        // Check for animations to play based on vatom state
        this.onVatomUpdated()

    }

    /** Called when the VatomView is unloading us */
    onUnload() {

        // Remove listener
        window.removeEventListener('resize', this.onResize)

        // Remove loop timer
        clearInterval(this.loopTimer)

    }

    /** Called when the browser window is resized. This function must be bound. */
    onResize = e => {

        // Update canvas size
        let rect = this.canvas.getBoundingClientRect()
        this.canvas.width = rect.width
        this.canvas.height = rect.height

        // Re-render
        this.render()

    }

    /** Called every frame change. This function should update the frame index and re-render if necessary. */
    onFrameLoop() {

        // Check if we have an active animation
        if (!this.currentAnimation)
            return

        // Check current animation mode
        let newFrameIndex = this.currentFrameIndex
        if (this.slideToFrame !== -1)
            newFrameIndex = this.onFrameLoopSlide()
        else
            newFrameIndex = this.onFrameLoopIncrement()

        if(newFrameIndex > this.frame_count)
          this.slideToFrame = -1
        
        // If frame changed, render it
        if (this.currentFrameIndex !== newFrameIndex) {

            this.currentFrameIndex = newFrameIndex
            this.render()

        }

    }

    /** Increase the frame index */
    onFrameLoopIncrement() {

        // Check if this new frame is past the end of the current animation, if not just increment it
        let frameEnd = this.frame_count ? Math.min(this.currentAnimation.frame_end, this.frame_count-1) : this.currentAnimation.frame_end
        if (!this.currentAnimation.backwards && this.currentFrameIndex + 1 <= frameEnd)
            return this.currentFrameIndex + 1
        if (this.currentAnimation.backwards && this.currentFrameIndex - 1 >= this.currentAnimation.frame_start)
            return this.currentFrameIndex - 1

        // Animation complete! Check if there's an animation rule to be triggered by this animation ending
        let animRule = this.animationRules.find(rule => rule.on === 'animation-complete' && rule.target === this.currentAnimation.name)
        let nextAnimation = animRule && this.animations.find(anim => anim.name === animRule.play)
        if (nextAnimation) {

            // Play this animation next
            this.currentAnimation = nextAnimation

            // Check if we should slide into this new animation
            if (nextAnimation.slide_in) {

                // Slide in
                this.slideToFrame = nextAnimation.backwards ? nextAnimation.frame_end : nextAnimation.frame_start
                return this.currentFrameIndex

            }

            // No slide, just go there immediately
            return nextAnimation.backwards ? nextAnimation.frame_end : nextAnimation.frame_start

        }

        // Check if the animation wants to loop, if so go back to the first frame of the animation
        if (this.currentAnimation.loop)
            return this.currentAnimation.backwards ? this.currentAnimation.frame_end : this.currentAnimation.frame_start

        // No next step! Just stop here then.
        this.currentAnimation = null
        return this.currentFrameIndex

    }

    /** Called in between animations, when the slide_in rule is in effect */
    onFrameLoopSlide() {

        // Slide one frame towards the target
        if (this.currentFrameIndex > this.slideToFrame)
            return this.currentFrameIndex - 1
        else if (this.currentFrameIndex < this.slideToFrame)
            return this.currentFrameIndex + 1

        // Frame reached! Stop sliding.
        this.slideToFrame = -1
        return this.currentFrameIndex

    }

    /** Renders the current frame into the canvas */
    render() {

        // Clear canvas
        this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height)

        // Get coordinates of the frame to render
        let frameWidthTotal = this.frame_width + this.frame_spacing_x
        let frameHeightTotal = this.frame_height + this.frame_spacing_y
        let framesPerRow = Math.floor(this.spriteSheet.width / this.frame_width)
        let frameX = Math.floor(this.currentFrameIndex % framesPerRow) * frameWidthTotal
        let frameY = Math.floor(this.currentFrameIndex / framesPerRow) * frameHeightTotal

        // Calculate frame size to fit into the available space
        let width = this.canvas.width
        let height = this.canvas.height
        if (this.frame_width > this.frame_height) {

            width = this.canvas.width
            height = width * (this.frame_height / this.frame_width)

            if (height > this.canvas.height) {

                height = this.canvas.height
                width = height * (this.frame_width / this.frame_height)

            }

        } else {

            height = this.canvas.height
            width = height * (this.frame_width / this.frame_height)
            if (width > this.canvas.width) {

                width = this.canvas.width
                height = width * (this.frame_height / this.frame_width)

            }

        }

        // Draw image
        let targetX = Math.floor((this.canvas.width - width) / 2)
        let targetY = Math.floor((this.canvas.height - height) / 2)
        this.canvasContext.drawImage(this.spriteSheet, frameX, frameY, this.frame_width, this.frame_height, targetX, targetY, width, height)

    }

    /** Get a vatom field based on a string path */
    vatomField(path) {

        // Split path into components
        let components = path.split(/\.(?=(?:[^"]*"[^"]*")*[^"]*$)/g)
        let current = this.vatom
        for (let nextComponent of components) {

            if (typeof current[nextComponent] !== 'undefined')
                current = current[nextComponent]

        }

        // Got value
        return current

    }

    /** Called when the vatom is updated. */
    onVatomUpdated() {

        // Find a matching animation rule

        let rule = this.animationRules.find(rule => rule.on === 'state' && this.vatomField(rule.target) === rule.value)
        if (rule)
            this.playAnimation(rule.play)

    }

    /** Called when the vatom is clicked */
    onClick(e) {

        e.preventDefault()

        // Find a matching animation rule and play it
        let rule = this.animationRules.find(rule => rule.on === 'click')
        if (rule)
            this.playAnimation(rule.play)

    }

    /** Play the specified named animation */
    playAnimation(name) {

        // Find it
        let animation = this.animations.find(anim => anim.name === name)
        if (!animation)
            return console.warn('[SpriteSheetFace] Tried to play nonexistent animation: ' + name)

        // Play this animation next
        this.currentAnimation = animation
        // Check if we should slide into this new animation or just go there immediately
        let frameEnd = this.frame_count ? Math.min(animation.frame_end, this.frame_count-1) : animation.frame_end
        if (animation.slide_in)
            this.slideToFrame = animation.backwards ? frameEnd : animation.frame_start
        else
            this.currentFrameIndex = animation.backwards ? frameEnd : animation.frame_start

    }

}
