module.exports = {

    images: {
        iconLight: require('./logoLight.png'), // iconLight is used on a dark background. ie. the login page
        iconDark: require('./logoDark.png'), // used on a light background. ie the inventory screen
        favicon_32: '',
        favicon_16: '',
        inventoryLogo: require('./Header.Logo.svg')
    },

    loginBackground: {
        backgroundImage: 'linear-gradient(147deg, #49a09d, #5f2c82)'
    },

    loginText: {
        title: 'BLOCKv is the central place for all your digital stuff',
        subTitle: 'Sign up to keep all your Vatoms safe',
        loginButton: 'Login',
        registerButton: 'Register'
    },
    guestRegistrationBar: {
        text: 'Already Registered? ',
        backgroundColor: '#eee',
        textColor: '#222',
        textAlign: 'center'
    }
}
