/* global Analytics */
import React from 'react'
import BLOCKv from '../../Common/Blockv'
import 'react-phone-number-input/style.css'
import Base from './Base'
import '../../Common/styles.css'
import Config from '../../Common/Config'
import CacheComponent from '../../Decorators/CacheComponent'
import Cookies from 'js-cookie'
const theme = require('../../theme/theme')

export default class LoginScreen extends React.Component {

    constructor(props) {

        super(props)
        this.state = {}
        this.state.isLoggedIn = false
        this.state.value = 0

        this.stateID = Math.random().toString(36).substr(2)

        this.redirectURI = encodeURIComponent(Config.oauthRedirect || 'https://login.blockv.io/send-event.html')
        this.uri = `${Config.oauthServer}/?response_type=token&client_id=${BLOCKv.store.appID}&redirect_uri=${this.redirectURI}&scope=all&state=${this.stateID}&nopadding=1&noconfirm=1`

    }

    // Create message listener
    messageListener = async (e) => {
        // Ensure the state matches
        if (e.data.state !== this.stateID) return false

        // Ensure the action matches
        if (e.data.action !== 'oauth-response') return false

        try {

            // Check response type
            if (!e.data.refresh_token)
                throw new Error('Missing Refresh Token')

            // We have our user data, store it
            BLOCKv.UserManager.setRefreshToken(e.data.refresh_token)
            BLOCKv.store.token = e.data.access_token

            // Get user info and set the store properties
            const profile = await BLOCKv.UserManager.getCurrentUser()
            BLOCKv.store.userID = profile.id
            const assetProviders = await BLOCKv.client.request('GET', '/v1/user/asset_providers', null, true)
            BLOCKv.store.assetProvider = assetProviders.asset_provider
            BLOCKv.dataPool.setSessionInfo({ userID: profile.id, client: BLOCKv.client })

            // Clear cached React components
            CacheComponent.clearCache()
            let getUrlParameter = (url, name) => decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || ['', ''])[1].replace(/\+/g, '%20')) || null;
            const firstDotIndex = window.location.hostname.indexOf('.');
            const domain = `.${window.location.hostname.substring(firstDotIndex + 1)}`;
            Cookies.set('uid', profile.id, { expires: 7, domain, secure: true });
            Analytics.event('userLogin', {
                'event': 'userLogin',
                'campaignUri': '',
                'viewerUri': window.location.origin,
                'userId': profile.id,
                'isGuestUser':  profile.guestId ? true : false,
                'isNewUser': (e.data.flow == 'login') ? false : true,
                'isAcquire': getUrlParameter(window.location.href, 'acquire') ? true : false
            })

            window.location.hash = '/inventory'

        } catch (err) {

            this.setState({ login: false })
            // Login failed, return error
            console.warn(err)

        }

    }

    componentDidMount() {

        // Go to inventory screen if logged in already
        if (BLOCKv.UserManager.isLoggedIn)
            window.location.hash = '/inventory'

        // if not logged in, redirect to external login page
        if (!BLOCKv.UserManager.isLoggedIn && Config.features.login_redirect)
            window.location = Config.features.login_redirect

        // Ensure SDK has been initialized
        // if (!BLOCKv.store.appID) throw new Error('Please initialize the SDK and set your App ID first.')
        var errorURL = "https://vatomviewer.com/v/errors/error.html";
        if ( window.location.host.match(/\.smowallet\.app$/) ) errorURL = "https://smowallet.app/error";

        if (!BLOCKv.store.appID)
            window.location = errorURL

        window.addEventListener('message', this.messageListener)

    }

    componentWillUnmount() {

        window.removeEventListener('message', this.messageListener)

    }

    startLogin() {

        this.setState({ login: true })
        Analytics.event('initiateLogin', {
            'event': 'initiateLogin',
            'eventValue': 'initiateLogin',
            'viewerUri': window.location.origin
        }, null)

    }

    render() {

        if (Config.features.login_redirect || !BLOCKv.store.appID)
            return <div/>

        return <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', margin: 0, padding: 0 }}>
            <Base>
                <div className={'form center'}>
                    <div style={{ marginBottom: '40px' }}>
                        <img style={{ width: '150px' }} src={theme.images.iconDark} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className={'split-button '}>
                            <button onClick={this.startLogin.bind(this)} className={'button custom split left isWhite'}>{theme.loginText.loginButton}</button>
                            <button onClick={this.startLogin.bind(this)} className={'button custom split right isWhite'}>{theme.loginText.registerButton}</button>
                        </div>
                    </div>
                </div>
                {(this.state.login) ? <iframe src={this.uri} sandbox="allow-forms allow-scripts allow-same-origin allow-modals" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#fff', border: 'none', borderRadius: 4, overflow: 'hidden' }}></iframe> : '' }
            </Base>
        </div>

    }

}
