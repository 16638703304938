
import React from 'react'

/** A component can subclass this class instead of `React.Component` in order to get access to size helpers in render(),
 *  such as `this.isMobile`, `this.isDesktop` etc.
 *
 *  If you do subclass, remember to call `super.componentDidMount()` and `super.componentWillUnmount()` if you override
 *  those methods.
 */
export default class SizeReactiveComponent extends React.Component {

    constructor(props) {

        super(props)

        /** Minimum width in CSS pixels before the window is seen as being "mobile" */
        this.minimumDesktopSize = 600

        /** Stores the window's current size */
        this.windowSize = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        // Bind listener functions, so unregistering will work as expected
        this.onResize = this.onResize.bind(this)

    }

    /** Called by React when the view has been mounted and is visible on the screen */
    componentDidMount() {

        // Register resize listener
        window.addEventListener('resize', this.onResize)
        this.onResize()

    }

    /** Called by React when the view is going to be removed from the screen */
    componentWillUnmount() {

        // Remove listeners
        window.removeEventListener('resize', this.onResize)

    }

    /** @private Called when the browser window is resized */
    onResize() {

        // Check if new size should trigger a re-render
        let becameDesktop = (this.windowSize.width <= this.minimumDesktopSize && window.innerWidth >= this.minimumDesktopSize)
        let becameMobile = (this.windowSize.width >= this.minimumDesktopSize && window.innerWidth <= this.minimumDesktopSize)
        let shouldRender = becameMobile || becameDesktop

        // Update values
        this.windowSize = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        // Render if needed
        if (shouldRender)
            this.forceUpdate()

    }

    /** Returns true if on a mobile sized screen */
    get isMobile() {

        return this.windowSize.width < this.minimumDesktopSize

    }

    /** Returns true if on a desktop sized screen */
    get isDesktop() {

        return !this.isMobile

    }

}
