//
// Screen - Fullscreen component

import React from 'react'

export default function Screen(props) {

    // Screen style
    var style = {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden'
    }

    // Apply scrolling if requested
    if (props.canScroll) {

        style.overflowY = 'auto'
        style.webkitOverflowScrolling = 'touch'

    }

    // Apply extra style info
    style = Object.assign(style, props.style)

    // Render component
    return <div style={style}>
        {props.children}
    </div>

}
