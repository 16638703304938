import React from 'react'

const Inventory = ({
    style = {},
    stroke = '#888888',
    width = '16px',
    height = '16px',
    className = '',
    viewBox = '0 0 16 16'

}) => {

    return (
        <svg width={width} height={height} viewBox={viewBox} className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Inventory</title>
            <desc>Inventory Icon</desc>
            <defs>
                <path d="M0,2.00591905 C0,0.898080551 0.886554837,0 2.00591905,0 L4.99408095,0 C6.10191945,0 7,0.886554837 7,2.00591905 L7,4.99408095 C7,6.10191945 6.11344516,7 4.99408095,7 L2.00591905,7 C0.898080551,7 0,6.11344516 0,4.99408095 L0,2.00591905 Z M9,2.00591905 C9,0.898080551 9.88655484,0 11.0059191,0 L13.9940809,0 C15.1019194,0 16,0.886554837 16,2.00591905 L16,4.99408095 C16,6.10191945 15.1134452,7 13.9940809,7 L11.0059191,7 C9.89808055,7 9,6.11344516 9,4.99408095 L9,2.00591905 Z M0,11.0059191 C0,9.89808055 0.886554837,9 2.00591905,9 L4.99408095,9 C6.10191945,9 7,9.88655484 7,11.0059191 L7,13.9940809 C7,15.1019194 6.11344516,16 4.99408095,16 L2.00591905,16 C0.898080551,16 0,15.1134452 0,13.9940809 L0,11.0059191 Z M9,11.0059191 C9,9.89808055 9.88655484,9 11.0059191,9 L13.9940809,9 C15.1019194,9 16,9.88655484 16,11.0059191 L16,13.9940809 C16,15.1019194 15.1134452,16 13.9940809,16 L11.0059191,16 C9.89808055,16 9,15.1134452 9,13.9940809 L9,11.0059191 Z" id="path-1"></path>
                <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="16" height="16" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
            </defs>
            <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Grid-View" transform="translate(-136.000000, -27.000000)" strokeWidth="4" stroke={stroke}>
                    <g id="Top-Categories" transform="translate(112.000000, 0.000000)">
                        <g id="Group-2">
                            <g id="Inventory">
                                <g transform="translate(24.000000, 27.000000)" id="Group-5">
                                    <g>
                                        <use id="Combined-Shape" mask="url(#mask-2)" xlinkHref="#path-1"></use>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default Inventory
