/* global dataLayer */
import BLOCKv from '../Common/Blockv'
import _ from 'lodash'
import Errors from '../Common/Errors'
import Config from '../Common/Config'

/**
 *  Allows sending analytics data to Google Analytics.
 */
export default class Analytics {

    /**
   *  Call this on app startup to register global functions. For convenience, this also registers the Analytics class
   *  as a global variable, the same way Google's gtag library does. This means you can call `Analytics.event()` etc
   *  without importing this class in every file.
   */
    static setup() {

        if (Config.analytics.onInit)
            Config.analytics.onInit()

        // Check for tracking ID
        if (!Config.analyticsTrackingID)
            //return console.warn('No Google Analytics tracking ID provided.')
            console.log('No GA ID')

        // Make sure anlytics is registered on the window
        if (!window.dataLayer) {

            window.dataLayer = window.dataLayer || []
            window.gtag = function gtag() { dataLayer.push(arguments) }
            window.gtag('js', new Date())
            window.gtag('config', Config.analyticsTrackingID, {cookie_flags:'SameSite=None;Secure'})


        }

        // Register us as a global
        window.Analytics = Analytics

        // Dynamically load the gtag.js google library
        let elem = document.createElement('script')
        elem.src = 'https://www.googletagmanager.com/gtag/js?id=' + Config.analyticsTrackingID
        document.body.appendChild(elem)

    }

    static user() {

        return BLOCKv.UserManager.getCurrentUser()

    }

    /** Log a screen view */
    static screen(name) {

        // Check for tracking ID
        if (!Config.analyticsTrackingID)
            return console.warn('No Google Analytics tracking ID provided.')

        window.gtag('config', Config.analyticsTrackingID, { 'page_path': '/' + window.location.hash })
        Analytics.event('screen_view', { 'screen_name': name })
        Analytics.event('page_view')

    }

    /** Log an error */
    static error(error, extraFields = {}) {

        Analytics.event('exception', Object.assign({ description: error.message, fatal: false }, extraFields))

    }

    /** Log a login */
    static login(method = 'password') {

        Analytics.event('login', { method })

    }

    /** Log a user registration */
    static signUp(method = 'password') {

        Analytics.event('sign_up', { method })

    }

    /**
   *  Log a custom event. See this URL for known event types: https://developers.google.com/gtagjs/reference/event
   */
    static async event(name, args = {}, vatom = null) {

        if (typeof vatom !== 'object') {

            try {

                vatom = await BLOCKv.Vatoms.getUserVatoms(vatom)

            } catch (err) {

                Errors.show(err)

            }

        }
        let mandatoryStack = {}

        /**
         * ? these values are specific to the analytics passed through to varius.
         */
        if (vatom && vatom.private.state) {

            mandatoryStack = vatom.private.state['varius.behavior:varius.io:studio-info-v1'] || vatom.private.analytics_data || null
            args['templateVariationName'] = vatom.properties.template_variation || vatom.payload['vAtom::vAtomType'].template_variation

            args = Object.assign({}, args, mandatoryStack)

        }

        if (Config.analytics.onEvent)
            Config.analytics.onEvent(name, args)

        // Check for tracking ID
        if (!Config.analyticsTrackingID)
            return console.warn('No Google Analytics tracking ID provided.')

        // vlabs - add event label with vatom template
        try {
            if ( vatom && !args['event_label'] )
                args['event_label'] = vatom.properties.template_variation || vatom.payload['vAtom::vAtomType'].template_variation || ''
        } catch(err) {}

        window.gtag('event', name, args)

    }

}
