
import EventEmitter from '../Code/EventEmitter'

//
// This class manages storing and retrieving virtual tags.
export default class Tags extends EventEmitter {

    /** Singleton instance */
    static get shared() {

        // Create a new instance or return the existing one
        if (!this._instance) this._instance = new Tags()
        return this._instance

    }

    /** Get named tag for the specified item */
    get(id, name) {

        // Get tag value
        return localStorage.getItem(`vtag:${id}:${name}`)

    }

    /** Set named tag for the specified item */
    set(id, name, value) {

        // Set tag value
        localStorage.setItem(`vtag:${id}:${name}`, value)

    }

    /** Clear named tag for the specified item */
    remove(id, name) {

        // Clear tag value
        localStorage.removeItem(`vtag:${id}:${name}`)

    }

}
