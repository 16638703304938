import BaseFace from '@blockv/sdk/face/faces/BaseFace'
import { FaceSelection } from '@blockv/sdk/face'
import Errors from '../Common/Errors'
/** This face displays an image, and a video when activated. It also supports unlocking with a key if necessary. */
export default class VideoFace extends BaseFace {

    /** @override On load, refresh image */
    onLoad() {

        // Make sure required resources exist
        let image = this.vatom.properties.resources.find(r => r.name === this.face.properties.config?.image) || this.vatom.properties.resources.find(r => r.name === 'ActivatedImage')
        let cover = this.vatom.properties.resources.find(c => c.name === this.face.properties.config?.cover) || this.vatom.properties.resources.find(c => c.name === 'CoverImage')
        let video = this.vatom.properties.resources.find(v => v.name === this.face.properties.config?.video) || this.vatom.properties.resources.find(v => v.name === 'Video')
        if (!image) return Promise.reject(new Error('ActivatedImage resource missing.'))
        if (!video) return Promise.reject(new Error('Video resource missing.'))

        // Create activated image
        this.activatedImage = document.createElement('div')
        this.activatedImage.style.cssText = 'background-size: contain; background-position: center; background-repeat: no-repeat; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; '
        this.element.appendChild(this.activatedImage)

        // Get resource
        var resource = this.vatomView.fsp === FaceSelection.Engaged ? cover : null
        if (!resource)
            resource = image

        // Display URL
        this.activatedImage.style.backgroundImage = 'url(' + this.encodeResource(resource.value.value) + ')'

        // If activated, add click handler
        if (this.face.properties.constraints.view_mode === 'engaged' || this.vatomView.fsp === FaceSelection.Engaged) {

            // Prepare the video player
            this.prepareVideo()

            // Attach click handler
            this.activatedImage.addEventListener('click', e => {

                e.preventDefault()

                // Check if no key
                let unlockKey = this.face.config?.unlock_key || this.vatom.private.unlock_key
                if (unlockKey)
                    return console.warn('User clicked to activate the video, but we have an unlock key. Ignored.')

                // Activate the video
                this.activate()

            })

        }

        // Return promise
        return VideoFace.waitForImage(this.encodeResource(resource.value.value))

    }

    /** This returns a promise which resolves when the specified image URL has been downloaded by the browser. */
    static waitForImage(url) {

        return new Promise((resolve, reject) => {

            // Create new image tag to do the loading. Browsers cache requests together, so by
            // creating a new image tag and loading the same image in it, we can track the load
            // event of the background-image in the div above.
            var img = document.createElement('img')
            img.src = url

            // Add event handlers
            img.onerror = reject
            img.onload = resolve

        })

    }

    encodeResource(url) {

        return this.vatomView.blockv.UserManager.encodeAssetProvider(url)

    }

    /** Check if we can combine with the specified vatom */
    canCombineWith(otherVatom) {

        // Check for unlock key
        let unlockKey = (this.face.config && this.face.config.unlock_key) || (this.vatom.private && this.vatom.private.unlock_key)
        if (unlockKey)
            return otherVatom.properties.template_variation === unlockKey

        // No key, do default behaviour
        return this.vatom.canCombineWith(otherVatom)

    }

    /** Do the combine */
    combineWith(otherVatom) {

        // Check for unlock key, if none do default action
        let unlockKey = (this.face.config && this.face.config.unlock_key) || (this.vatom.private && this.vatom.private.unlock_key)
        if (!unlockKey)
            return this.vatomView.blockv.Vatoms.combineWith(this.vatom, otherVatom)

        // Activate the video
        this.activate()

    }

    /** Called when the face is loaded in engaged mode, bubt the user hasn't clicked it yet */
    prepareVideo() {

        // Stop if already prepared
        if (this.fullscreenContainer)
            return

        // Create fullscreen container
        this.fullscreenContainer = document.createElement('div')
        this.fullscreenContainer.style.cssText = 'position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: #FFF; z-index: 9998; opacity: 0; transition: opacity 0.3s; pointer-events: none; '
        document.body.appendChild(this.fullscreenContainer)

        // Create video element
        let videoResource = (this.face.config && this.face.config.video && this.vatom.properties.resources.find(vr => vr.name === this.face.config.video)) || this.vatom.properties.resources.find(vr => vr.name === 'Video')
        this.videoPlayer = document.createElement('video')
        this.videoPlayer.style.cssText = `z-index:9999; position: absolute; top: 0px; left: 0px; width: 100%; background-color: rgba(0,0,0,0.9); height: 100%; object-fit: ${(window.innerWidth > window.innerHeight) ? 'contain' : 'cover'}`
        this.videoPlayer.poster = this.vatom.properties.resources.find(cr => cr.name === 'CoverImage') && this.encodeResource(this.vatom.properties.resources.find(cr => cr.name === 'CoverImage').value.value)
        this.videoPlayer.playsInline = true
        this.videoPlayer.muted = true
        this.videoPlayer.autoplay = true
        this.videoPlayer.crossOrigin = 'anonymous'
        this.videoPlayer.src = this.encodeResource(videoResource.value.value)
        this.fullscreenContainer.appendChild(this.videoPlayer)

        // Add play on tap handler
        this.videoPlayer.addEventListener('click', e => {

            this.videoPlayer.play()

        })
        this.videoPlayer.addEventListener('touchstart', e => {

            this.videoPlayer.play()
            e.preventDefault()

        })

        // Add close button
        var skipBtn = document.createElement('div')
        skipBtn.style.cssText = 'z-index: 10000; position: absolute; top: 0px; right: 0px; padding: 20px; color: #08F; fontSize: 9px; fontFamily: Helvetica, Arial; cursor: pointer; textAlign: right; '
        skipBtn.innerText = 'Skip'
        this.fullscreenContainer.appendChild(skipBtn)

        // Add skip button handler
        skipBtn.addEventListener('click', e => {

            // Remove overlay
            this.fullscreenContainer.style.opacity = '0'
            setTimeout(e => {

                this.fullscreenContainer.parentNode && this.fullscreenContainer.parentNode.removeChild(this.fullscreenContainer)

            }, 500)

        })

        // Add handler when video is finished playing
        this.videoPlayer.addEventListener('ended', e => {

            // Remove element
            this.fullscreenContainer.style.cssText = 'opacity: 0; transition: opacity 0.3s;'
            setTimeout(e => {

                this.fullscreenContainer.parentNode && this.fullscreenContainer.parentNode.removeChild(this.fullscreenContainer)

            }, 500)

        })

    }

    /** Activate the video */
    activate() {

        this.prepareVideo()

        // Show it with animation
        this.fullscreenContainer.style.display = 'block'
        this.fullscreenContainer.style.pointerEvents = 'auto'

        // Start playing the video
        if (this.videoPlayer && this.videoPlayer.src) {

            setTimeout(e => this.fullscreenContainer.style.cssText = 'opacity: 1; transition: opacity 0.4s;', 100)
            this.videoPlayer.play()

        }

        // Do action
        var actionName = (this.face.config && this.face.config.activate_action) || this.vatom.properties.activate_action || 'Activate'
        return this.vatomView.blockv.Vatoms.performAction(this.vatom.id, actionName, null).catch(err => {

            // Immediately hide the video element
            this.fullscreenContainer.parentNode && this.fullscreenContainer.parentNode.removeChild(this.fullscreenContainer)

            // Pass the error further down the promise chain
            Errors.show(err)

        })

    }

    onResize() {}

    onUnload() {}

    onVatomUpdated() {}

    static get url() {

        return 'native://video'

    }

}
