import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import ActivityItem from './ActivityItem'
import Config from '../Common/Config'
import Icon from '../Components/Header/icons'

const styles = theme => ({
    typography: {
        margin: theme.spacing(2)
    }
})

class ActivityContainer extends React.Component {

  state = {
      anchorEl: null
  }

  handleClick = event => {

      this.setState({
          anchorEl: event.currentTarget
      })

  };

  handleClose = () => {

      this.setState({
          anchorEl: null
      })

  };

  render() {

      const { classes } = this.props
      const { anchorEl } = this.state
      const open = Boolean(anchorEl)
      return (

          <div>
              <style dangerouslySetInnerHTML={{ __html: `
          #simple-popper > div:nth-child(3){
            right: 0 !important;
            display:block;
            max-height: 80vh;
            width: 300px !important;
            overflow: hidden auto;
          }
          @media screen and (min-width: 600px) {
            #simple-popper > div:nth-child(3){
              width: 600px !important;
            }
          }
        ` }} />
              <Button
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  style={{ color: 'white', height: '64px' }}
              >
                  <Icon name='notifications' stroke={Config.theme.header.textColor} />
              </Button>
              <Popover
                  id="simple-popper"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }}
              >
                  <ActivityItem />
              </Popover>
          </div>
      )

  }

}

ActivityContainer.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ActivityContainer)
