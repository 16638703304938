import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    }
})

class Loader extends React.Component {

    render() {

        const { classes } = this.props
        return (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress
                    className={classes.progress}
                    variant="indeterminate"
                />
            </div>
        )

    }

}

Loader.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Loader)
