/* global Analytics */
// Allows the user to select a vatom category

import React from 'react'
import Popup from '../Popup'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MediaQuery from 'react-responsive'
import BLOCKv from '../../Common/Blockv'
import Swal from 'sweetalert2'
import Errors from '../../Common/Errors'
import Loader from '../../Decorators/Loader'
import IncomingOverlay from '../../Components/IncomingOverlay'


const initialState = {
    token: '',
    token_type: '',
    password: '',
    showToken: true,
    showPassword: false,
    showOTP: false,
    additionalInfo: '',
    showMerge: false,
    resetProcess: false
}
export default class MergePopup extends Popup {
    
    constructor(props) {
        
        super(props)
        this.state = initialState

    }

    componentDidMount() {
        if (this.props.token) {
            this.setState({ token: this.props.token })
        }
    }

    startAgain() {

        this.setState(initialState)

    }

    @Loader async checkAccount(token, token_type) {

        try {

            await BLOCKv.UserManager.login(token, token_type)

        } catch (e) {

            // 2032 password required  PASSWORD_RESET otp
            if (e.code === 2032)
                this.setState({ showPassword: true, showToken: false })

            else if (e.code === 'PASSWORD_RESET')
                this.setState({ showOTP: true, showToken: false })

            else
                Errors.show(e)

        }

    }

    async nextStep() {

        // if token and password has been set, show merge button

        if (this.state.token && this.state.password) {

            this.setState({ showMerge: true, showPassword: false, showOTP: false })
            return

        }
        // check that the token is a email or a phone number then update the state properties
        let token = this.state.token
        let token_type = ''
        if (/^[ +()\-0-9]+$/.test(token)) {

            this.setState({ token_type: 'phone_number' })
            token_type = 'phone_number'

        } else if (/^[^\s]+@[^\s]+$/.test(token)) {

            this.setState({ token_type: 'email' })
            token_type = 'email'

        }

        this.checkAccount(token, token_type)

    }

    async resetPassword() {

        try {

            await BLOCKv.UserManager.resetPassword(this.state.token, this.state.token_type)
            this.setState({ additionalInfo: 'Password reset sent' })

        } catch (err) {

            this.setState({ additionalInfo: 'Error resetting password' })

        }

    }

    @Loader async doMerge() {

        let payload = {
            token: this.state.token,
            token_type: this.state.token_type,
            auth_data: {
                password: this.state.password
            }
        }
        try {

            // Begin merge
            await BLOCKv.client.request('POST', '/v1/user/merge_accounts', payload, true)

            // Wait until the specified token has been added to the account
            let timeout = Date.now() + 30000
            while (true) {

                // Get current tokens
                let tokens = await BLOCKv.UserManager.getCurrentUserTokens()
                if (tokens.find(t => t.properties.token.toLowerCase().trim() == this.state.token.toLowerCase().trim())) {

                    // Found, account merging is probably finished now
                    break

                }

                // Not found, check if timeout is exceeded
                if (Date.now() > timeout)
                    throw new Error('Could not find merged token. Please refresh the page and try again.')

                // Wait a bit
                await new Promise(c => setTimeout(c, 2000))

            }

            this.close()
            IncomingOverlay.ignore = true

            if (this.props.onMergeComplete) this.props.onMergeComplete()
            if (this.props.onComplete) this.props.onComplete()

            Swal.fire({
                title: 'Merge Successful',
                text: 'Your accounts have been merged successfully',
                type: 'success'
            })

        } catch (err) {
            
            Errors.show(err)

        }

    }

    render() {
        return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', textAlign: 'center', overflowX: 'hidden', overflowY: 'hidden', WebkitOverflowScrolling: 'touch' }}>

            {/* UI when entering phone number */}
            <div style={{ display: this.state.verifyStep ? 'none' : 'block' }}>

                {/* Logo */}
                <div style={{ padding: '40px 0px' }}>
                    <MediaQuery query="(min-width: 1024px)">
                        <img src={require('./users.svg')} style={{ height: 128 }} />
                    </MediaQuery>
                </div>

                {/* Title */}
                <div style={{ fontSize: 21, color: '#444', marginBottom: 10 }}>Merge Accounts</div>
                <div style={{ fontSize: 15, color: '#444', margin: '0px 40px 40px 40px' }}>Enter the credentials for the account merge. Once this is complete, it cannot be undone.</div>
                <div style={{ fontSize: 12, color: 'blue', margin: '0px 5px 5px 5px' }}>{this.state.additionalInfo}</div>

                {/* Fields */}
                <div style={{ padding: '0px 40px', display: (this.state.showToken) ? 'block' : 'none' }}>
                    <TextField key={ 'Phone / Email' } fullWidth autoFocus variant="outlined" margin="dense" label={ 'Phone / Email Address' } type='text' value={ this.state.token } onChange={e => this.setState({ token: e.target.value })} />
                </div>

                { /* Password field */}
                <div style={{ padding: '0px 40px', display: (this.state.showPassword) ? 'block' : 'none', textAlign: 'left' }}>
                    <TextField key={ this.state.showPassword } fullWidth autoFocus variant="outlined" margin="dense" label={ 'Password' } type='password' value={ this.state.password } onChange={e => this.setState({ password: e.target.value })} />
                    <small style={{ fontSize: '12px', marginLeft: 5 }}><a onClick={() => this.resetPassword()}>Reset Password</a></small>
                </div>

                { /* OTP field */}
                <div style={{ padding: '0px 40px', display: (this.state.showOTP) ? 'block' : 'none' }}>
                    <TextField key={ this.state.showOTP } fullWidth autoFocus variant="outlined" margin="dense" label={'OTP'} type='text' value={ this.state.password } onChange={e => this.setState({ password: e.target.value })} />
                    <small style={{ fontSize: '12px', marginLeft: 5 }}><a onClick={() => this.resetPassword()}>Resend OTP</a></small>
                </div>

                {/* Buttons */}
                <div style={{ textAlign: 'center', margin: 20 }}>
                    <Button variant="outlined" style={{ margin: 5 }} onClick={e => this.close()}>Cancel</Button>
                    <Button style={{ display: (this.state.showMerge) ? 'none' : 'inline', margin: 5 }} variant="outlined" color="default" onClick={e => this.nextStep()}>Next</Button>
                    <Button variant="contained" style={{ margin: 5, display: (this.state.showMerge && !this.state.resetProcess) ? 'inline' : 'none', backgroundColor: 'rgb(219, 80, 41)', color: 'white' }} onClick={e => this.doMerge()}>Merge Account</Button>
                    <Button variant="contained" style={{ margin: 5, display: (this.state.resetProcess) ? 'inline' : 'none' }} color="primary" onClick={e => this.startAgain()}>Try Again</Button>
                </div>

            </div>

        </div>

    }

}
