
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'
import React from 'react'
import TWEEN from '@tweenjs/tween.js'
import config from '../../Common/Config'

// Apply access token
mapboxgl.accessToken = config.mapBoxKey

export default class MapBox extends React.Component {

    constructor(props) {

        super(props)

        // Setup state
        this.state = {}

        // Native map component
        this.map = null

        // @private Markers
        this.markers = []

        // The user's current position
        this.userPosition = { latitude: 0, longitude: 0 }

    }

    render() {

        // Return container div
        return <div ref='container' style={Object.assign({ position: 'relative', minWidth: 64 }, this.props.style)} />

    }

    componentDidMount() {

        let style = config.features?.maps?.style
        if (style === 'dark')
            style = 'mapbox://styles/mapbox/dark-v10?optimize=true'
        else if (style === 'light')
            style = 'mapbox://styles/mapbox/light-v10?optimize=true'
        else if (style === 'street' || !style)
            style = 'mapbox://styles/mapbox/streets-v11?optimize=true'

        // Create mapbox element
        this.map = new mapboxgl.Map({
            container: this.refs.container,
            style
        })

        // Add navigation controls
        this.map.addControl(new mapboxgl.NavigationControl())

        // Add locate controls
        let geoControl = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
        geoControl.on('geolocate', pos => {

            this.userPosition = pos.coords
            let location = `${pos.coords.longitude}, ${pos.coords.latitude}`
            sessionStorage.setItem('mapbox_last_known_location', location)

        })
        this.map.addControl(geoControl)

        // Add markers
        this.refreshMarkers()

        // Add listener for when the map finishes moving
        this.map.on('moveend', e => {

            this.props.onMoved(this.map)
            this.setState({ moved: true })

        })

        // Start updating tween lib. This must only happen once, even if multiple map components are rendered.
        if (!window.tweenIsRunning) {

            window.tweenIsRunning = true
            let animate = function(time) {

                requestAnimationFrame(animate)
                TWEEN.update(time)

            }
            requestAnimationFrame(animate)

        }

        // Set initial location
        if (config.features?.maps?.start?.latitude && config.features?.maps?.start?.longitude) {

            // Config has a default map position for us to use
            this.map.setZoom(config.features.maps.start.zoom || 8)
            this.map.setCenter([ config.features.maps.start.longitude, config.features.maps.start.latitude ])

        } else {

            // Fetch location from GeoIP lookup
            fetch('https://geoip.vatomviewer.com/json/').then(r => r.json()).then(json => {

                // Update map, only if geolocation hasn't run yet
                if (json && json.latitude && !this.userPosition.latitude) {

                    this.map.setZoom(8)
                    this.map.setCenter([ json.longitude, json.latitude ])

                }

                // Start fetching actual location
                geoControl.trigger()

            })

        }

        // Notify loaded
        this.props.onLoad(this.map)

    }

    componentDidUpdate(oldProps, oldState) {

        // Stop if not loaded yet
        if (!this.map)
            return

        // Refresh markers
        this.refreshMarkers()

    }

    componentWillUnmount() {

        // Remove map
        this.map.remove()
        this.map = null

    }

    /** Add/update/remove markers to sync with the React Marker children */
    refreshMarkers() {

        // Stop if not loaded yet
        if (!this.map)
            return

        // Update all markers
        let children = React.Children.toArray(this.props.children)
        for (let child of children) {

            // Make sure type matches
            if (child.type !== Marker)
                continue

            // Find existing marker
            let marker = this.markers.find(m => m.key === child.key)
            if (!marker) {

                // Create DOM element
                let div = document.createElement('div')
                div.style.cssText = 'width: 40px; height: 40px; background-size: contain; background-position: center; background-repeat: no-repeat; cursor: pointer; '

                // Add click handler
                div.onclick = child.props.onClick

                // None found, create a new marker
                marker = new mapboxgl.Marker({
                    element: div
                })
                marker.element = div
                marker.key = child.key
                marker.setLngLat([child.props.longitude, child.props.latitude])

                marker.addTo(this.map)
                this.markers.push(marker)

            } else {

                // Stop if new position is equal to current position
                let currentPos = marker.getLngLat().toArray()
                if (currentPos[0] !== child.props.longitude || currentPos[1] !== child.props.latitude) {

                    // Stop existing tween animation
                    if (marker.currentTween)
                        marker.currentTween.stop()

                    // Get current and next position
                    let nextPos = [child.props.longitude, child.props.latitude]

                    // Get mapbox zoom level
                    let zoom = this.map.getZoom()

                    // Create tween
                    if (zoom >= 7)
                        marker.currentTween = new TWEEN.Tween(currentPos).to(nextPos, 750).onUpdate(values => marker.setLngLat(values)).start()

                }

            }

            // Update image if needed
            if (marker.img !== child.props.icon) {

                marker.img = child.props.icon
                marker.element.style.backgroundImage = 'url(' + child.props.icon + ')'

            }

            // Update click handler if needed
            if (marker.element.onclick !== child.props.onClick)
                marker.element.onclick = child.props.onClick

        }

        // Remove all markers that are no longer in the list
        for (let i = 0; i < this.markers.length; i++) {

            // Check that marker is still in list of children
            let marker = this.markers[i]
            let child = children.find(c => c.key === marker.key)
            if (child)
                continue

            // Remove marker
            this.markers[i].remove()
            this.markers.splice(i--, 1)

        }

    }

}

/** React placeholder for maker objects on the map */
export class Marker extends React.Component {

}

/**
 *  This function roughly calculates the meter distance between two coordinates using the Haversine formula.
 *  Adapted from: https://stackoverflow.com/a/1502821
 */
export function getDistance(p1, p2) {

    // Check if points are functions
    if (typeof p1.lat === 'function')
        p1 = { lat: p1.lat(), lng: p1.lng() }
    if (typeof p2.lat === 'function')
        p2 = { lat: p2.lat(), lng: p2.lng() }

    var R = 6378137 // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat)
    var dLong = rad(p2.lng - p1.lng)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d // returns the distance in meter

}

function rad(x) {

    return x * Math.PI / 180

}
