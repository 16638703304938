// Allows the user to pick a country

import React from 'react'
import Popup from './Popup'
import BLOCKv from '../Common/Blockv'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { ListItemIcon, ListItemText } from '@material-ui/core'
import Analytics from '../Code/Analytics'

export default class SearchPopup extends Popup {

    constructor() {

        super()

        // Setup vars
        this.state = {}
        this.state.vatoms = []
        this.state.input = ''

    }

    componentDidMount() {

        // Log analytics
        Analytics.screen('Popup:Search')

        // Focus input field
        setTimeout(e => {

            this.refs['inputField'].focus()

        }, 250)

    }

    render() {

        // Map to React list elements
        var vatoms = this.state.vatoms.map(vatom => {

            // Get icon
            var res = vatom.properties.resources.find(r => r.name === 'ActivatedImage')
            var icon = res && BLOCKv.UserManager.encodeAssetProvider(res.value.value)

            // Create list item
            return <ListItem button key={vatom.id} onClick={e => this.vatomSelected(vatom)}>
                <ListItemIcon>
                    <div style={{
                        backgroundImage: 'url(' + icon + ')',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: 32,
                        height: 32,
                        margin: 10
                    }} />
                </ListItemIcon>
                <ListItemText primary={vatom.properties.title} />
            </ListItem>

        })

        return <div>

            {/* Header */}
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 40, paddingTop: 30, display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>

                {/* Input field */}
                <input ref='inputField' placeholder='Search' value={this.state.input} onChange={this.searchChanged.bind(this)} style={{ flexGrow: '1', flexShrink: '1', fontSize: 16, color: '#4a4a4a', border: 'none', background: 'none', outline: 'none', width: 64, paddingLeft: 24 }} />

                {/* Cancel button */}
                <div style={{ padding: '10px 24px 10px 4px', fontSize: 16, color: '#5393c6', cursor: 'pointer', textTransform: 'uppercase', flexShrink: '0', flexGrow: '0' }} onClick={this.close.bind(this)}>Cancel</div>

            </div>

            {/* Scrollable content */}
            <div style={{ position: 'absolute', top: 71, left: 0, width: '100%', height: 'calc(100% - 71px)', overflowX: 'hidden', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>

                <List>

                    {/* List of vatoms */}
                    {vatoms}

                </List>

                {/* No items found label */}
                {vatoms.length === 0 && this.state.input ? <div style={{ padding: 40, paddingTop: 80, fontSize: 13, color: '#979797', textAlign: 'center' }}>No items found</div> : null}
                {vatoms.length === 0 && !this.state.input ? <div style={{ padding: 40, paddingTop: 80, fontSize: 13, color: '#979797', textAlign: 'center' }}>Please enter a search query above</div> : null}

            </div>

        </div>

    }

    /** @private Called when the user enters some text in the search field */
    searchChanged(e) {

        // Update state
        var input = e.target.value || ''
        this.setState({ input })
        input = input.toLowerCase()

        // Create list of vatoms
        if (input) {

            // Filter based on search query
            var vatoms = BLOCKv.dataPool.region('inventory').get(false).filter(v => v.properties.title.toLowerCase().indexOf(input) !== -1).sort((a, b) => {

                return a.properties.title.localeCompare(b.properties.title)

            })
            // Filter out user avatar from returned results
            vatoms = vatoms.filter(v => v.properties.template !== 'vatomic::v1::vAtom::Avatar')
            this.setState({ vatoms })

        } else {

            // No search text
            this.setState({ vatoms: [] })

        }

    }

    /** @private Called when the user selects a vatom from the list */
    vatomSelected(vatom) {

        // Close us
        this.close()

        // Show vatom
        window.location.hash = '/vatom/' + vatom.id

    }

}
