//
//

import Contact from './Contact'
import ContactsPlugin from './ContactsPlugin'
import BLOCKv from '../../Common/Blockv'

/** This Contacts plugin pulls contacts from the user's vAtoms */
export default class VatomInventoryPlugin extends ContactsPlugin {

    constructor(manager) {

        super(manager)

        // Setup properties
        this.id = 'com.blockv.vatomhistory'
        this.name = 'vAtom History'
        this.iconURL = ''
        this.connected = true
        this.profilePromises = {}

    }

    /** Refresh the inventory */
    async refresh() {

        // Stop if not logged in
        if (!BLOCKv.UserManager.isLoggedIn)
            return

        // Don't load if already loaded
        if (this.contacts.length > 0)
            return

        // Start updating
        this.updating = true

        // Go through vAtoms from the inventory
        await Promise.all(BLOCKv.dataPool.region('inventory').get(false).map(async vatom => {

            // Fetch previous user ID of vAtom
            let uid = vatom.properties.transferred_by
            if (!uid || uid === BLOCKv.store.userID)
                return

            let time = new Date(vatom.whenModified).getTime()

            // Fetch user details, if needed
            let promise = this.profilePromises[uid]
            if (!promise) {

                promise = BLOCKv.UserManager.getPublicUserProfile(uid)
                this.profilePromises[uid] = promise

            }

            let user = await promise

            // Skip if no name
            if (!user.properties.first_name && !user.properties.last_name)
                return

            // Add it
            let contact = new Contact()
            contact.plugin = this
            contact.id = uid
            contact.userID = uid
            contact.avatarURL = user.properties.avatar_uri
            contact.firstName = user.properties.first_name
            contact.lastName = user.properties.last_name
            contact.source = 'BLOCKv User'
            contact.date = time
            this.addContact(contact)

        })).catch(console.warn)

        // Finished updating
        this.updating = false

    }

}
