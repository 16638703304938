/* global Analytics */
// Allows the user to select a vatom category

import React from 'react'
import Popup from './Popup'
import BLOCKv from '../Common/Blockv'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AppsIcon from '@material-ui/icons/Apps'
import StarIcon from '@material-ui/icons/Star'
import ToysIcon from '@material-ui/icons/Toys'
import RoomIcon from '@material-ui/icons/Room'
import GamesIcon from '@material-ui/icons/Games'
import LinkIcon from '@material-ui/icons/Link'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import LocalActivityIcon from '@material-ui/icons/LocalActivity'
import SchoolIcon from '@material-ui/icons/School'
import StarsIcon from '@material-ui/icons/Stars'
import DescriptionIcon from '@material-ui/icons/Description'
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import RowingIcon from '@material-ui/icons/Rowing'
import BlurOnIcon from '@material-ui/icons/BlurOn'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import SettingsIcon from '@material-ui/icons/Settings'
import MoneyIcon from '@material-ui/icons/Money'
import FavoriteIcon from '@material-ui/icons/Favorite'

// Icon mapping
const IconMap = {
    'awards': <StarsIcon />,
    'collectable': <ToysIcon />,
    'collectables': <ToysIcon />,
    'collectible': <ToysIcon />,
    'collectibles': <ToysIcon />,
    'combinable': <LinkIcon />,
    'coupon': <LoyaltyIcon />,
    'coupons': <LoyaltyIcon />,
    'documents': <DescriptionIcon />,
    'education': <SchoolIcon />,
    'entertainment': <VideogameAssetIcon />,
    'food & drink': <FastfoodIcon />,
    'food & drinks': <FastfoodIcon />,
    'games': <GamesIcon />,
    'gaming': <GamesIcon />,
    'gifts': <CardGiftcardIcon />,
    'guides': <RoomIcon />,
    'lifestyle': <RowingIcon />,
    'miscellaneous': <BlurOnIcon />,
    'music': <AudiotrackIcon />,
    'redeemable': <LoyaltyIcon />,
    'ticket': <LocalActivityIcon />,
    'tickets': <LocalActivityIcon />,
    'token': <MoneyIcon />,
    'tokens': <MoneyIcon />,
    'utilities': <SettingsIcon />,
    'vouchers': <LoyaltyIcon />
}

export default class CategorySelector extends Popup {

    constructor() {

        super()

        // Setup vars
        this.state = {}
        this.state.categories = []
        this.state.category = ''

        // Stop if no inventory
        if (!BLOCKv.dataPool.region('inventory'))
            return

        // Get vatoms to use for the category discovery
        let vatoms = BLOCKv.dataPool.region('inventory').get(false).filter(v => v.properties.template !== 'vatomic::v1::vAtom::Avatar' && v.properties.template !== 'vatomic::v1::vAtom::CoinWallet')

        // Get categories
        this.state.categories = vatoms.map(v => v.properties.category)

        // Filter out duplicates
        this.state.categories = this.state.categories.filter((elem, pos, arr) => arr.findIndex(elem2 => elem.toLowerCase() === elem2.toLowerCase()) === pos)

        // Remove empty items
        this.state.categories = this.state.categories.filter(e => !!e)

        // Sort by name, case insensitive
        this.state.categories = this.state.categories.sort(function compare(a, b) {

            if (a.toLowerCase() < b.toLowerCase())
                return -1

            if (a.toLowerCase() > b.toLowerCase())
                return 1

            return 0

        })

    }

    render() {

        return <div>
            {/* Scrollable content */}
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>
                <List>

                    {/* All vAtoms */}
                    <ListItem button onClick={e => this.onSelect('')} >
                        <ListItemIcon>
                            <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary='All Vatoms' />
                    </ListItem>

                    {/* Favorite vAtoms */}
                    <ListItem button onClick={e => this.onSelect('special:favorite')} >
                        <ListItemIcon>
                            <FavoriteIcon />
                        </ListItemIcon>
                        <ListItemText primary='Favorites' />
                    </ListItem>
                    <Divider />

                    {/* Category list */}
                    {this.state.categories.map(category =>
                        <ListItem button key={category} onClick={e => this.onSelect(category)}>
                            <ListItemIcon>
                                {IconMap[category.toLowerCase()] || <StarIcon />}
                            </ListItemIcon>
                            <ListItemText primary={category} />
                        </ListItem>
                    )}

                </List>

                {/* No items label */}
                {this.state.categories.length === 0 ? <div style={{ padding: 40, paddingTop: 80, fontSize: 13, color: '#979797', textAlign: 'center' }}>No categories found</div> : null}

            </div>
        </div>

    }

    /** @private Called when the user selects a category */
    onSelect(category) {

        // Log analytics
        Analytics.event('select_category', { name: category })

        // Close us
        this.close()

        // Notify listener
        this.props.onSelect(category)

    }

}
