import React from 'react'
import BLOCKv from '../../Common/Blockv'

//
// Displays a message while the inventory is synchronizing
export default class SynchronizingMessage extends React.PureComponent {

    constructor() {
        super()
        this.state = {
            show: false,
            objectCount: 0
        }
    }

    /** State */
    

    /** Render */
    render = e => <div style={{ position: 'fixed', zIndex: 9999, bottom: 16, right: 16, backgroundColor: '#666', borderRadius: 12, height: 24, display: 'flex', alignItems: 'center', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)', pointerEvents: 'none', opacity: this.state.show ? 1 : 0, transition: 'opacity 0.25s' }}>
        <img src={require('./SynchronizingLoader.svg')} style={{ margin: '0px 4px'}} />
        <div style={{ fontSize: 11, color: '#AAA', margin: '0px 10px 0px 4px' }}><font style={{ color: '#DDD' }}>Synchronizing</font> - {this.state.objectCount} items</div>
    </div>

    /** On mount */
    componentDidMount() {

        // Add listener
        this.inventory = BLOCKv.dataPool.region('inventory')
        this.inventory.addEventListener('updated', this.onInventoryChanged)

    }

    /** On unmount */
    componentWillUnmount() {
        
        // Remove listener
        this.inventory.removeEventListener('updated', this.onInventoryChanged)

    }

    /** On inventory state changed */
    onInventoryChanged = e => {

        // Check if synchronizing. TODO: Update SDK region to have a way of checking state, instead of accessing private vars like this
        this.setState({
            show: !!this.inventory._syncPromise,
            objectCount: this.inventory.objects.size
        })

    }

}