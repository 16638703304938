import React from 'react'
import BLOCKv from '../Common/Blockv'
import { FaceSelection } from '@blockv/sdk/face'
import LiveVatomView from '../Common/LiveVatomView'
import InContract from './InContract'

const HeavyFaces = [
    'native://generic-3d',
    'native://generic-3d-folder'
]
export default class LiveVatomViewContainer extends React.Component {

    constructor(props) {

        super(props)
        this.container = React.createRef()
        this.vv = null

    }

    render() {

        return <div ref={this.container} style={Object.assign({ position: 'relative' }, this.props.style)}>
            {(this.props.vatom?.properties?.in_contract) ? <InContract /> : null }
        </div>

    }

    componentDidMount() {

        this.vv = new LiveVatomView(BLOCKv, this.props.vatom, this.props.fsp || FaceSelection.Icon, { 'excludedFaces': this.props.noHeavyFaces ? HeavyFaces : [] })
        this.vv.element.style.cssText += 'postion: absolute; top:0px; left:0px; width:100%; height:100%'
        this.vv.onMessage = this.props.onMessage
        this.container.current.append(this.vv.element)

    }

    componentWillUnmount() {

        this.container.current.removeChild(this.vv.element)

        // This can crash due to a bug in the SDK: https://trello.com/c/7bfTVeoL
        try {

            this.vv.free()

        } catch (err) {

            console.warn('SDK bug: Calling free() after logout causes an error.', err)

        }

        // Done
        this.vv = null

    }

}
