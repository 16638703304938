import Blockv, { VatomView } from '@blockv/sdk/face'
import config from '../Common/Config'

// Import config
export default new Blockv({
    'appID': config.appID,
    'server': config.server,
    'websocketAddress': config.websocketServer,
    'prefix': config.prefix,
    'disableSyncV2': config.disableSyncV2
})

// Register external native faces
for (let Face of config.nativeFaces || []) {

    // Extract ES6 class if necessary
    if (Face.default)
        Face = Face.default

    // Register it
    VatomView.registerFace(Face)

}
