//
//

import GoogleContactsPlugin from './GoogleContactsPlugin'
import VatomInventoryPlugin from './VatomInventoryPlugin'
import EventEmitter from './EventEmitter'
import RecentContactsPlugin from './RecentContactsPlugin'

/**
 *  This class manages access to the user's contacts. It uses different plugins to access contacts
 *  from multiple sources.
 *
 *  @singleton
 */
export default new class Contacts extends EventEmitter {

    /** Called automatically when this class is accessed the first time */
    constructor() {

        super()

        // Setup plugins
        this.plugins = []
        this.loadPlugin(new GoogleContactsPlugin(this))
        this.loadPlugin(new VatomInventoryPlugin(this))
        this.loadPlugin(new RecentContactsPlugin(this))

    }

    /** Loads a plugin */
    loadPlugin(plugin) {

        // Add it
        this.plugins.push(plugin)

        // Done
        console.debug(`[Contacts] Loaded plugin ${plugin.name}`)

    }

    /** @internal Called by plugins when there has been a change */
    pluginChanged(plugin) {

        // Ensure we don't spam the event, if a plugin changes multiple times in one loop
        if (this._updateEventTimer) return
        this._updateEventTimer = setTimeout(e => {

            // Clear timer
            this._updateEventTimer = null

            // Send event
            this.trigger('change')

        }, 1)

    }

    /** Gets the list of contacts from all plugins */
    getAll() {

        // Get contacts from all plugins
        let allContacts = []
        for (let plugin of this.plugins) {

            for (let contact of plugin.contacts)
                allContacts.push(contact)

        }

        // Sort
        allContacts.sort((a, b) => {

            // Sort by recent
            if (a.priority !== b.priority)
                return b.priority - a.priority

            // Sort by last name
            let result = (a.lastName || '').localeCompare(b.lastName || '')
            if (result !== 0)
                return result

            // Last names match, sort by first name
            return (a.firstName || '').localeCompare(b.firstName || '')

        })

        // Done
        return allContacts

    }

    /** Begin loading data from all plugins */
    load() {

        // Refresh all plugins
        for (let plugin of this.plugins)
            plugin.refresh()

    }

    /** True if any plugins are still loading */
    get loading() {

        // Find any plugins which are still loading
        for (let plugin of this.plugins) {

            if (plugin.updating)
                return true

        }

        // Nothing is loading
        return false

    }

}()
