
import React from 'react'
import Popup from '../Popup'
import BLOCKv from '../../Common/Blockv'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import swal from 'sweetalert'
import { InputAdornment } from '@material-ui/core'
import Countries from '../../Code/Countries'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
import CountryPickerPopup from '../CountryPickerPopup'
import Errors from '../../Common/Errors'
import MediaQuery from 'react-responsive'
import MergePopup from '../MergePopup'
export default class AddPhonePopup extends Popup {

    constructor(props) {

        super(props)

        // Setup vars
        this.state = {}
        this.state.phone = props.preloadPhone || ''
        this.state.phoneError = ''
        this.state.country = Countries.detected || { code: 'us', phonePrefix: '+1', name: 'United States' }
        this.state.verifyStep = props.verifyStep || false
        this.state.verifyCode = ''
        this.state.verifyCodeError = ''
        this.state.skipVerify = props.skipVerify || false

    }

    async componentDidMount() {

        // Detect country
        let country = await Countries.detect()
        if (country)
            this.setState({ country })

        // Send verify code if we're starting on that screen
        if (this.state.verifyStep)
            this.verify()

    }

    render() {

        const { mainText, secondaryText } = this.props;

        return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', textAlign: 'center', overflowX: 'hidden', overflowY: 'hidden', WebkitOverflowScrolling: 'touch' }}>

            {/* UI when entering phone number */}
            <div style={{ display: this.state.verifyStep ? 'none' : 'block' }}>

                {/* Logo */}
                <div style={{ padding: '40px 0px' }}>
                    <MediaQuery query="(min-width: 1024px)">
                        <img src={require('./smartphone-2.svg')} style={{ height: 128 }} />
                    </MediaQuery>
                </div>

                {/* Title */}
                <div style={{ fontSize: 21, color: '#444', marginBottom: 10 }}>{mainText ||'Add your phone'}</div>
                <div style={{ fontSize: 15, color: '#444', margin: '0px 40px 40px 40px' }}>{secondaryText || 'Enter your phone number below.'}</div>

                {/* Fields */}
                <div style={{ padding: '0px 40px' }}>
                    <TextField fullWidth autoFocus variant="outlined" margin="dense" label={this.state.phoneError || 'Phone number'} type='phone' error={!!this.state.phoneError} value={this.state.phone} onChange={e => this.setState({ phone: e.target.value, phoneError: '' })} InputProps={{
                        startAdornment: <InputAdornment position="start" onClick={e => this.selectCountry()} style={{ cursor: 'pointer' }}>{this.state.country.phonePrefix}</InputAdornment>
                    }} />
                </div>

                {/* Buttons */}
                <div style={{ textAlign: 'center', margin: 20 }}>
                    <Button variant="outlined" style={{ marginRight: 20 }} onClick={e => this.close()}>Cancel</Button>
                    <Button variant="outlined" color="primary" onClick={e => this.verify()}>Send code</Button>
                </div>

            </div>

            {/* UI when verifying phone number */}
            <div style={{ visibility: this.state.verifyStep ? 'visible' : 'hidden' }}>

                {/* Logo */}
                <div style={{ padding: '40px 0px' }}>
                    <MediaQuery query="(min-width: 1024px)">
                        <img src={require('./smartphone-3.svg')} style={{ height: 128 }} />
                    </MediaQuery>
                </div>

                {/* Title */}
                <div style={{ fontSize: 21, color: '#444', marginBottom: 10 }}>Verify your phone</div>
                <div style={{ fontSize: 15, color: '#444', margin: '0px 40px 40px 40px' }}>Enter the code we sent you.</div>

                {/* Fields */}
                <div style={{ padding: '0px 40px' }}>
                    <TextField fullWidth autoFocus variant="outlined" margin="dense" label={this.state.verifyCodeError || 'Enter code'} error={!!this.state.verifyCodeError} value={this.state.verifyCode} onChange={e => this.setState({ verifyCode: e.target.value, verifyCodeError: '' })} />
                </div>

                {/* Buttons */}
                <div style={{ textAlign: 'center', margin: 20 }}>
                    <Button variant="outlined" style={{ margin: 5 }} onClick={e => this.setState({ verifyStep: false })}>Back</Button>
                    <Button variant="outlined" style={{ margin: 5 }} onClick={e => this.verify()}>Resend</Button>
                    <Button style={{margin: 5}} variant="outlined" color="primary" onClick={e => this.verifyCode()}>Verify</Button>
                </div>

            </div>

        </div>

    }

    /** Show a country picker */
    selectCountry() {

        CountryPickerPopup.show({ selectedCountry: this.state.country, onSelect: country => this.setState({ country }) })

    }

    /** Verify phone */
    verify() {

        var convertedPhone = null
        try {

            // Convert to international format
            var phoneUtil = PhoneNumberUtil.getInstance()
            var pnf = phoneUtil.parse(this.state.phone, this.state.country && (this.state.country.code || 'US'))
            convertedPhone = phoneUtil.format(pnf, PhoneNumberFormat.E164)

        } catch (err) {

            console.warn(err)
            return this.setState({ phoneError: 'Not valid' })

        }

        // Add the phone number to the profile
        swal({ buttons: false, text: 'Sending verification code...' })
        BLOCKv.client.request('POST', '/v1/user/tokens', { token: convertedPhone, token_type: 'phone_number' }, true).catch(err => {

            // Check if the phone already existed on the profile
            if (err.code == 521) {

                // Check if this token exists on our account already
                let ourTokens = this.props.allTokens || []
                let isOurToken = ourTokens.find(t => t.properties.token == convertedPhone)
                if (isOurToken) {

                    // Token is on our account, the user wants to verify it, send the verification code
                    return BLOCKv.UserManager.sendTokenVerification(convertedPhone, 'phone_number')

                } else {

                    // Token is on another account, take the user to the merge account popup
                    MergePopup.show({ token: convertedPhone, onComplete: this.props.onComplete })
                    this.close()

                }

            } else {

                // Rethrow error
                throw err

            }

        }).then(e => {

            // Move to verify screen
            swal.close()

            // vlabs
            if ( this.state.skipVerify ) {
                this.close()
                if (this.props.onComplete) this.props.onComplete()
                return
            }

            this.setState({ convertedPhone, verifyStep: true })

        }).catch(err => {

            // Failed!
            swal.close()
            if (err.code === 2572)
                this.setState({ phoneError: 'Not valid' })
            else
                Errors.show(err)

        })

    }

    /** Verify code */
    verifyCode() {

        // Check code
        if (this.state.verifyCode.length < 4)
            return this.setState({ verifyCodeError: 'Too short' })

        // Send verify code
        swal({ buttons: false, text: 'Verifying phone...' })
        BLOCKv.UserManager.verifyUserToken({ 'token': this.state.convertedPhone, 'token_type': 'phone_number', 'verify_code': this.state.verifyCode }).then(e => {

            // Success
            swal.close()
            this.close()

            // Show success
            swal('Phone number added', 'Your phone number was verified successfully.', 'success')

            // Notify complete
            if (this.props.onComplete)
                this.props.onComplete()

        }).catch(err => {

            // Failed!
            swal.close()
            Errors.show(err)

        })

    }

}
