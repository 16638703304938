const merge = require('lodash.merge')
import Cookies from 'js-cookie'
import Vlabs from './vlabs'

// Check if running locally
const DEBUG = location.hostname === 'localhost' || location.hostname === '127.0.0.1'

/* global analytics */
module.exports = {

    prefix: 'viewer',
    server: Vlabs.api_url || 'https://api.vl.vatom.network',
    websocketServer: Vlabs.ws_url || 'wss://ws.vatomviewer.com',
    oauthServer: 'https://login.blockv.io',
    oauthRedirect: window.location.origin === 'https://vatom.com' ? 'https://vatom.com/oauth-send-event.html' : null,

    // Keys
    mapBoxKey: process.env.MAPBOX, // MapBox API Key. See https://docs.mapbox.com/mapbox-gl-js/overview/#quickstart to obtain a key
    googleMapsKey: process.env.GOOGLE_MAPS, // Google Maps Key
    googleContactsClientID: process.env.GOOGLE_CONTACTS, // Google contacts client ID
    catchoomToken: process.env.CATCHOOM, // Catchoom Token for image recognition. See https://catchoom.com/image-recognition/ to obtain a token
    firebaseVapidKey: process.env.FIREBASE, // Firebase key
    firebase: {}, // Firebase config options
    appKey8thWall: '', // Optional 8th wall app key, provides 6DoF movement in AR

    // Register native faces
    nativeFaces: [
        require('@blockv/3d-face'),
        require('./Faces/VideoFace'),
        require('./Faces/SpriteFace'),
        require('./Faces/WebScannerFace')
    ],
    vatomWarningIcons: false,

    // Disable V2 inventory synchronization. With this set to `false`, it will use the /hash and /sync APIs to
    // sync with the server. With this set to `true`, it will pull _all_ vatoms from the server on startup via
    // the /inventory API
    disableSyncV2: false,

    // Consent version of the app
    consentDocumentVersion: 1,

    // Custom error message replacer. This allows you to replace the text that appears for certain errors.
    // Example:
    //
    //      { code: 1015, text: "You need to verify your account first.", title: "Verify required" }
    //
    customErrors: [ {
        code: 1082,
        text: `You have reached the limit to pick up a {{title}}. You may try a different location or try again at a later time.`,
        title: 'Oops!'
    }],

    // Custom external links (displayed in profile)
    /**
     * @title text to be displayed for the link
     * @url url of the link
     *
     * eg. { title: 'google', url: 'https://www.google.com'}
     */
    customLinks: [],

    /**
     * Array of custom html to be displayed on the consent screen
     * @param text is a html string
     * This will be parsed on the consent popup
     *
     * eg
     * {
     *   text: '<p>Some text here: <a href="http://www.google.com">Google</a></p>'
     * }
     */
    consentHTML: '',

    /**
     *  This is a custom analytics option
     *  @method onInit()  sets up the tracking js file
     *
     *  @method onEvent() catches all events and sends them to
     *  the desired platform for tracking
     *
     *  TODO: add google analytics tracking info
    */
    analytics: {
        onInit: () => {
            /*
            let segmentElement = document.createElement('script')
            segmentElement.innerText = '!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";analytics.load("JWyM9LmA33hSKzp0tcMeLdJXlaerc7AB");analytics.page();}}();'
            document.body.appendChild(segmentElement)
            */
        },
        onEvent: (name, args) => {
            if (typeof analytics === 'undefined') return
            if(name == 'userLogin') {
                analytics.identify(args.userId)
                const firstDotIndex = window.location.hostname.indexOf('.');
                const domain = `.${window.location.hostname.substring(firstDotIndex + 1)}`;
                Cookies.set('uid', args.userId, { expires: 7, domain, secure: true })
            }
            analytics.track(name, args)
        }
    },

    // Options relating to the external config file. Note, these options can't be changed from within the remote config.
    remoteConfig: {

        // Remote config URL. Leave blank to disable the remote config. Any keys specified will override
        // keys from this file. You can also use these placeholders inside the URL:
        //
        // - $HOSTNAME : eg. "vatom.com"
        //
        // Examples:
        // - "https://s3.amazon.com/mybucket/$HOSTNAME.json"
        //
        //url: 'https://s3.amazon.com/mybucket/$HOSTNAME.json',
        //url: 'https://beta.vatomviewer.com/login/beta/viewer.json',
        //url: 'https://dev.vatomlabs.com/viewer_config.php',
        url: '',

        // Amount of time between checks for a new config version. Set to 0 to disable.
        //checkInterval: 1000 * 60 * 5,
        checkInterval: 0,

        // How to update the remote config once a new version is found. Options are:
        // - 'notify' : A banner in the inventory is shown to the user, asking them to install the update.
        // - 'reload' : The app is immediately reloaded. Note, the user could lose unsaved work.
        // - 'force' : The update is applied while the app is running. Note, this may result in unexpected behaviour, use with caution.
        //updateMode: 'notify'
        updateMode: 'force'

    },


    // App Settings
    appID: Vlabs.app,
    pubFQDN: Vlabs.publisher,
    appTitle: Vlabs.title || 'Vatom Viewer',
    appIcon: Vlabs.favicon || 'https://vatomviewer.com/v/vlabs/vatomlabs_icon.png',
    feedbackEmail: Vlabs.feedback_email || 'Vatom Labs Support <help@vatomlabs.com>',
    feedbackSubject: Vlabs.feedback_subject || 'Vatom Labs Feedback',
    faqURL: Vlabs.faq_link || 'https://bit.ly/BuiltOnTheLabFAQ',
    termsOfUseURL: 'https://www.builtonthelab.io/termsofuse',
    privacyPolicyURL: 'https://www.builtonthelab.io/privacy',
    analyticsTrackingID: Vlabs.ga_tracking_id,
    redirectToNewViewer: Vlabs.upgraded_viewer || false,

    // Filter all vatoms by a specified field and value. Examples:
    // - { field: "vAtom::vAtomType.template", value: "..." } will only show vatoms with a specific template
    // - { field: "vAtom::vAtomType.author", value: "..." } will only show vatoms created by a specific user
    // - { field: "private.campaign_id", value: "..." } will only show vatoms in a specific campaign (custom field)
    filter: {
        field: "",
        value: ""
    },

    // Enabled features
    features: {

        // Account options
        accounts: {
            autoCreateGuest: false,
            disableRegistration: true,
            hasStarterPack: true
        },

        // Inventory options
        inventory: {

            // Comma separated list of fqdn's to whitelist. The filtering is done client-side.
            fqdn_whitelist: [],

            // Show a "Synchronizing..." alert while the inventory is loading.
            synchronizing_alert: true,

            // Force scanner to redirect to inventory when clicking on close button
            close_scan_to_inventory: true,

        },

        // Map options
        maps: {
            style: Vlabs.map_style || 'street', // can be 'dark' or 'light' or 'street' or a MapBox style URL
            fqdn_whitelist: '', // single fqdn to filter by
            pickup_radius: 500, // user is only allowed to pick up vatoms within this radius (meters)
            max_items: 20 // Maximum vatoms to load in AR by default. Using a high number could cause crashes.
        },

        // AR options
        ar: {

            ar_distance: Vlabs.ar_distance,
            ar_height: Vlabs.ar_height,
            ar_scale: Vlabs.ar_scale,

            // Radius around the user to fetch vatoms, in meters
            visible_radius: 500,

            // Should we move objects closer to / farther from the camera, and what distance multiplier should we use? 1 = off
            distance_multiplier: 0.05,

            // Minimum distance from the camera, in meters. If an object is closer than this, it will be moved this far away.
            min_distance: 5,

            // A single FQDN to whitelist. This performs filtering server-side.
            fqdn_whitelist: '',

            // Attempt to synchronise the camera feed to the 3D renderer. Requires more processing power.
            camera_sync: false,

            // Display debug info in the AR feed.
            debug_info: DEBUG ? true : false,

            // Display a debug floor grid
            floor_grid: false

        },

        /**
         * @enable_country_restriction set to false will ignore all country specific actions
         */
        localization: {

            enable_country_restriction: false,
            eligible_countries: [
                'US'
            ],
            eligible_states: [
                'California'
            ],
            eligible_state_text: '',
            eligible_state_subtext: ''

        },

        legalities: {
            agegate: false,
            agegate_text: '',
            agegate_subtext: ''
        },

        // header options
        header: {
            inventory: !Vlabs.hide_inventory,
            coins: !Vlabs.hide_coins,
            map: !Vlabs.hide_map,
            scanner: !Vlabs.hide_scanner,
            ar: true,
            activity: true,
            profile: true,
            logo: false,
            category: !Vlabs.hide_category_search,
            search: !Vlabs.hide_category_search,
            sort: !Vlabs.hide_category_search
        },

        tabs: [

          // Main inventory that shows all vatoms except coins
          {
              "title": "wallet",
              "excludes": Vlabs.hide_tpl || []
          },

          // Coins tab with counter
          {
              "title": "coins",
              "includes": Vlabs.coins_tpl || [],
              "counter": Vlabs.coins_value || {}
          }

        ],

        // general
        confetti: false,
        bookmark: false,
        showClientVatomsOnly: Vlabs.client_vatoms_only || false,
        publisherFqdn: Vlabs.publisher || '',
        showPortraitLockMessage: Vlabs.vatom_portrait || false,
        login_redirect: Vlabs.login_redirect

    },

    // Theme
    theme: {
        css: Vlabs.css || '',
        header: {
            backgroundColor: Vlabs.header_color || '#FFFFFF',
            logo: Vlabs.header_logo,
            logoHeight: Vlabs.header_height || '32px',
            logoWidth: Vlabs.header_width || '32px',
            selectedTextColor: Vlabs.header_tab_active || '#000000',
            textColor: Vlabs.header_tab || '#C0C0C0',
            altInventoryName: Vlabs.tab_inventory || 'WALLET',
            altHomeButtonName: Vlabs.btn_home || 'HOME',
            altCoinsName: Vlabs.tab_coins || 'COINS',
            altMapName: Vlabs.tab_map || 'MAP',
            altScannerName: Vlabs.tab_scanner || 'SCAN',
            tabs: {
              fontFamily: Vlabs.style.button_font || 'sans-serif',
              fontSize: '16px',
              fontWeight: '600',
              fontColor: Vlabs.header_tab || '#C0C0C0',
              borderColor: Vlabs.header_tab_border || '#C0C0C0',
            },
            selectedTabs: {
              fontFamily: Vlabs.style.button_font || 'sans-serif',
              fontSize: '16px',
              fontWeight: '600',
              fontColor: Vlabs.header_tab_active || '#000000',
              accentColor: Vlabs.header_tab_accent || '#000000',
              accentHeight: '4px'
          },
        },
        search_folder: {
            title_background: '#000000',
            title_color: '#FFFFFF',
            title_font: Vlabs.style.button_font || 'sans-serif',
        },
        vatom: {
            iconColor: Vlabs.vatom_icons || '#000000',
            src: Vlabs.vatom_logo,
            logoHeight: Vlabs.vatom_logo_height || '32px',
            logoWidth: Vlabs.vatom_logo_width || '32px',
            hideVatomMenu: Vlabs.hide_vatom_menu || false
        },
        vatomPopUp: {
            buttonColor: Vlabs.style.button_background_color || '#000000',
            buttonFont: Vlabs.style.button_font || 'sans-serif',
            buttonFontColor: Vlabs.style.button_font_color || '#FFFFFF',
        }
    }

}
