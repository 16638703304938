/* global google */
// Allows the user to drop a vAtom on the map
import React from 'react'
import Popup from './Popup'
import MapBox from '../Components/MapBox'
import BLOCKv from '../Common/Blockv'
import Config from '../Common/Config'
import Errors from '../Common/Errors'
import Analytics from '../Code/Analytics'

export default class DropPopup extends Popup {

    constructor() {

        super()
        // Setup vars
        this.state = {}

        // The user's coordinates
        this.state.userLocation = null
        this.mapBox = React.createRef()

        // The user's address
        this.state.place1 = ''
        this.state.place2 = ''

        // Location error if any
        this.state.locationError = null

    }

    render() {

        // Get label text content
        let label1 = 'Loading...'
        let label2 = '...'
        if (this.state.place1) {

            // Use place name
            label1 = this.state.place1
            label2 = this.state.place2

        } else if (this.state.userLocation) {

            // Use GPS coordinates
            label1 = 'No street name'
            label2 = `${this.state.userLocation.latitude.toFixed(6)}, ${this.state.userLocation.longitude.toFixed(6)}`

        } else if (this.state.locationError) {

            // Show error details
            label1 = 'Location Error'
            label2 = this.state.locationError.message

        }

        return <div>
            {/* Header */}
            <div style={{
                position: 'absolute',
                zIndex: '2',
                top: 0,
                left: 0,
                width: '100%',
                height: 40,
                paddingTop: 30,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFF',
                boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.1)'
            }}>

                {/* Input field */}
                <div style={{
                    flexGrow: '1',
                    flexShrink: '1',
                    fontSize: 16,
                    color: '#4a4a4a',
                    border: 'none',
                    background: 'none',
                    outline: 'none',
                    width: 64,
                    paddingLeft: 24 }} >
            Drop vAtom
                </div>

                {/* Cancel button */}
                <div style={{
                    padding: '10px 24px 10px 4px',
                    fontSize: 16,
                    color: '#5393c6',
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                    flexShrink: '0',
                    flexGrow: '0'
                }} onClick={this.close.bind(this)}>Cancel</div>
            </div>

            {/* Map */}
            <MapBox ref={this.mapBox}
                style={{
                    position: 'absolute',
                    zIndex: '1',
                    top: 70,
                    left: 0,
                    width: '100%',
                    height: 'calc(100% - 70px - 88px)'
                }}
                onLoad={this.onMapMoved.bind(this)} onMoved={this.onMapMoved.bind(this)} />

            {/* Info area */}
            <div style={{
                position: 'absolute',
                zIndex: '2',
                top: 'calc(100% - 88px)',
                left: 0,
                width: '100%',
                height: 88,
                backgroundColor: '#FFF',
                boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.1)'
            }}>

                {/* Text */}
                <div style={{
                    position: 'absolute',
                    top: 24,
                    left: 16,
                    fontSize: 16,
                    color: 'rgb(74, 74, 74)'
                }}>
                    {label1}
                </div>
                <div style={{
                    position: 'absolute',
                    top: 48,
                    left: 16,
                    fontSize: 14,
                    color: 'rgb(151, 151, 151)'
                }}>
                    {label2}
                </div>

            </div>

            {/* Drop button */}
            <div onClick={this.onDropClick.bind(this)} style={{
                position: 'absolute',
                zIndex: '3',
                bottom: 68,
                right: 16,
                width: 56,
                height: 56,
                borderRadius: 28,
                backgroundColor: this.mapBox?.current?.userPosition?.latitude ? '#fd5959' : '#CCC',
                backgroundSize: '16px 16px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: 'url(' + require('./DropPopup.down.svg') + ')',
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
                cursor: this.mapBox?.current?.userPosition?.latitude ? 'pointer' : 'default'
            }} />

            <div onClick={this.onDropClick.bind(this)} style={{
                position: 'absolute',
                zIndex: '3',
                bottom: 46,
                right: 16,
                width: 56,
                textAlign: 'center',
                textTransform: 'uppercase',
                color: this.mapBox?.current?.userPosition?.latitude ? '#fd5959' : '#CCC',
                fontSize: 12,
                cursor: this.mapBox?.current?.userPosition?.latitude ? 'pointer' : 'default'
            }}>Drop</div>
        </div>

    }

    componentDidMount() {

        // Log analytics
        Analytics.screen('Popup:Drop')

    }

    /** @private Called when the map moves */
    onMapMoved(map) {

        this.forceUpdate()
        let userP = this.mapBox?.current?.userPosition
        if (!userP)
            return

        let longitude = userP.longitude
        let latitude = userP.latitude
        if ((longitude !== 0) && (latitude !== 0))
            this.getReverseGeocode(latitude, longitude)

    }

    async getReverseGeocode(lat, lon) {

        // Send request
        let response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${Config.mapBoxKey}`)
        let geo = (await response.json()).features?.[0]

        // Store response in state
        this.setState({
            place1: geo?.address ? `${geo.address} ${geo.text}` : 'Unknown street',
            place2: geo?.context?.[0].text || ''
        })

    }

    onMapUpdated() {

    }

    /** @private Called when the user clicks the Drop button */
    async onDropClick() {

        Analytics.event('initiateDrop', {
            'event': 'initiateDrop',
            'eventValue': 1,
            'userId': BLOCKv.store.userID,
            'digitalObjectId': this.props.vatom.id,
            'vatomId': this.props.vatom.id,
            'source': 'ObjectMenu'
        }, this.props.vatom)
        // Check if got location
        if (!this.mapBox?.current?.userPosition)
            return

        this.close()
        if (this.props.onComplete)
            this.props.onComplete()

        // Catch errors
        try {

            let dropType = (this.props?.pindrop) ? "DropPin" : "Drop"

            // Do the drop
            await BLOCKv.Vatoms.performAction(this.props.vatom.id, dropType, {
                'geo.pos': {
                    lat: this.mapBox?.current?.userPosition?.latitude,
                    lon: this.mapBox?.current?.userPosition?.longitude
                }
            })
            // Call completion handler if there is one

            // Log analytics
            Analytics.event('performAction', {
                'event': 'performAction',
                'eventValue': 1,
                'actionUri': dropType,
                'userId': BLOCKv.store.userId,
                'digitalObjectId': this.props.vatom.id,
                'vatomId': this.props.vatom.id,
                'templateVariationName': this.props.vatom.properties.template_variation,
                'location': {
                    'longitude': this.mapBox?.current?.userPosition?.longitude,
                    'latitude': this.mapBox?.current?.userPosition?.latitude
                }
            }, this.props.vatom)

        } catch (err) {

            Errors.show(err)

        }

    }

}
