import React from 'react'

export default props => <div style={{
    backgroundSize: '16px 16px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(' + require('../images/VatomView.InExchange.svg') + ')',
    top: '10px',
    left: '10px',
    width: '16px',
    height: '16px',
    zIndex: '10100',
    padding: '2px',
    backgroundColor: '#fff',
    borderRadius: '18px' }} />
