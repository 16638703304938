
import React, { Component } from 'react'
import BLOCKv from '../../Common/Blockv'
import TableCell from './TableCell'
import QRCode from 'qrcode.react'
import '../../Common/styles.css'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { readAndCompressImage } from 'browser-image-resizer'
import config from '../../Common/Config'
import CacheComponent from '../../Decorators/CacheComponent'
import AuthorisedOnly from '../../Decorators/AuthorisedOnly'
import CacheState from './CacheState'
import SetNamePopup from '../../Popups/SetNamePopup'
import SetPasswordPopup from '../../Popups/SetPasswordPopup'
import AddPhonePopup from '../../Popups/AddPhonePopup'
import AddEmailPopup from '../../Popups/AddEmailPopup'
import UserAvatarButton from '../../Components/UserAvatarButton'
import Firebase from '../../Common/Firebase'
import Loader from '../../Decorators/Loader'
import Errors from '../../Common/Errors'
import Analytics from '../../Code/Analytics'
import dayjs from 'dayjs'
import dayjsUtils from '@date-io/dayjs'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import InputBase from '@material-ui/core/InputBase'
import MergePopup from '../../Popups/MergePopup'
import Swal from 'sweetalert2'
import RefreshOnConfigUpdate from '../../Decorators/RefreshOnConfigUpdate'
import DownArrow from '../../images/chevron-down.svg'

export default @CacheComponent('profile') @RefreshOnConfigUpdate @AuthorisedOnly class ProfileScreen extends Component {

    constructor(props) {

        super(props)
        this.state = {
            profile: '',
            password: '',
            firstName: '',
            lastName: '',
            birthday: '',
            nonPushNotifications: '',
            pushNotifications: localStorage['push'] === 'on',
            menuAnchors: {},
            anchorEl: null,
            toggleIcon: 'profile',
            showAdvanced: false
        }

    }

    componentDidMount() {

        // Go to login screen if not logged in
        if (!BLOCKv.UserManager.isLoggedIn) {

            window.location.hash = '/login'
            return

        }

        // Load current user's profile
        this.refreshUserProfile()
        this.refreshUserTokens()

        // Check if browser supports push notifications
        Firebase.setupMessaging().then(e => this.setState({ pushNotificationsSupported: true }))

    }

    refreshUserProfile() {

        BLOCKv.UserManager.getCurrentUser().then((e) => {

            this.setState({ profile: e })
            this.setState({ first_name: e.firstName })
            this.setState({ last_name: e.lastName })
            this.setState({ newName: e.firstName + ' ' + e.lastName })
            this.setState({ smsEmailNotifications: e.nonPushNotification })
            this.setState({ bulkNotifications: e.rawPayload.properties.notification_settings.bulk_notifications.allow_notifications })
            BLOCKv.client.request('GET', '/v1/user/accounts', null, true).then(uac => {

                // Store Ethereum address if it exists
                let account = uac.find(a => a.type === 'system')
                if (account && account.address)
                    this.setState({ ethereumAddress: account.address })

            })

            // Store birthday if there is one, apply time zone offset and make sure the hours is in the middle of the day
            if (e.birthday) {

                // Append timezone offset
                let date = new Date(e.birthday)
                date.setTime(date.getTime() + date.getTimezoneOffset() * 1000 * 60)
                date.setHours(12)
                this.setState({ birthday: date.getTime() ? dayjs(date) : null })

            }

        })
        this.refreshUserTokens()

    }

    // gets the current users tokens
    refreshUserTokens() {

        BLOCKv.UserManager.getCurrentUserTokens().then(t => {

            this.setState({ tokens: t })

        })

    }

    // updates the users profile
    updateProfile() {

        let payload = {}
        if (this.state.firstName)
            payload.first_name = this.state.firstName

        if (this.state.lastName)
            payload.last_name = this.state.lastName

        if (this.state.password)
            payload.password = this.state.password

        if (this.state.birthday)
            payload.birthday = this.state.birthday

        BLOCKv.UserManager.updateUser(payload).then((e) => { window.location.reload() })

    }

    /** @private Called when the user changes the avatar image */
    onAvatarSelected(e) {

        const config = {
            maxHeight: 512,
            maxWidth: 512,
            autoRotate: true
        }

        // Get new image file
        var file = e.target.files[0]

        // Ensure a file was selected
        if (!file)
            return

        readAndCompressImage(file, config).then(resizedImage => {

            // Upload file to some Web API
            const formData = new FormData()
            formData.append('avatar', resizedImage)

            if (window.URL)
                this.setState({ avatar: URL.createObjectURL(resizedImage) })

            return BLOCKv.client.request('POST', '/v1/user/avatar', formData, true)

        }).then(e => {

            // Update UI on other screens as well
            UserAvatarButton.activeComponents.forEach(c => c.userChanged())

        }).catch(err => {

            console.error(err.message)

        })

    }

    back() {

        window.history.back()

    }

    /** Called when a menu item in a token popup menu is clicked */
    async handleMenuItemClick(item, type, token) {

        // Close menu
        this.setState({ ['menu-' + item]: false })

        // Catch all errors
        try {

            // Check which button was pressed
            if (type === 'primary') {

                // Confirm with user
                let confirm = await Swal.fire({
                    title: 'Default Token',
                    text: 'Make this your primary token?',
                    showCancelButton: true,
                    showConfirmButton: true
                })
                if (confirm.dismiss)
                    return

                // Set it
                await BLOCKv.UserManager.setDefaultToken(item)

                // Done
                Swal.fire({
                    title: 'Primary Token',
                    type: 'success',
                    text: 'Successfully set ' + token + ' as your primary token.'
                })
                this.refreshUserTokens()

            } else if (type === 'verify') {

                // Show verification popup
                if (token.indexOf('@') === -1)
                    AddPhonePopup.show({ allTokens: this.state.tokens, verifyStep: true, preloadPhone: token, onComplete: e => this.refreshUserTokens() })
                else
                    AddEmailPopup.show({ allTokens: this.state.tokens, verifyStep: true, preloadEmail: token, onComplete: e => this.refreshUserTokens() })

            } else if (type === 'remove') {

                // Confirm with user
                let c = await Swal.fire({
                    title: 'Confirm',
                    text: 'Are you sure you want to remove this token?',
                    showCancelButton: true,
                    showConfirmButton: true
                })
                if (c.dismiss)
                    return

                // Do it
                await BLOCKv.UserManager.deleteUserToken(item)

                // Done
                Swal.fire({
                    title: 'Removed Token',
                    text: 'Successfully removed ' + token,
                    type: 'success'
                })
                this.refreshUserTokens()

            }

        } catch (err) {

            Errors.show(err)

        }

    }

    tokenMenu(token) {

        const open = !!this.state['menu-' + token.id]// Boolean(anchorEl)
        const elemToOpen = document.getElementById(token.id)
        const tID = token.id
        return <div>
            <IconButton
                id={token.id}
                aria-label='More'
                aria-owns={'long-menu'}
                aria-haspopup='true'
                onClick={e => this.setState({ ['menu-' + token.id]: true })}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='long-menu'
                onClose={e => this.setState({ ['menu-' + token.id]: false })}
                open={open}
                anchorEl={elemToOpen}
            >
                <MenuItem style={{ display: (token.properties.is_default) ? 'none' : 'block' }} onClick={ this.handleMenuItemClick.bind(this, tID, 'primary', token.properties.token)}>Make Primary</MenuItem>
                <MenuItem style={{ display: (token.properties.confirmed) ? 'none' : 'block' }} onClick={ this.handleMenuItemClick.bind(this, tID, 'verify', token.properties.token)}>Verify</MenuItem>
                <MenuItem onClick={ this.handleMenuItemClick.bind(this, tID, 'remove', token.properties.token)}>Remove Token</MenuItem>
            </Menu>
        </div>

    }

    toggleJumbo() {

        let _currentScreen = this.state.toggleIcon
        if (_currentScreen === 'profile')
            this.setState({ toggleIcon: 'qr' })
        else if (_currentScreen === 'qr')
            this.setState({ toggleIcon: 'profile' })

    }

    logout() {

        Analytics.event('Logout',{'event_category':'profile'})

        Swal.fire({
            title: 'Logging Out',
            text: 'Logging out of your profile...',
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false // There won't be any confirm button
        })
        CacheComponent.clearCache()
        BLOCKv.UserManager.logout().catch(e => e).then(() => {

            Swal.close()
            window.location.hash = '/login'

        })

    }

    privacyPolicy() {

        window.open(config.privacyPolicyURL, '_blank')

    }

    termsOfUser() {

        window.open(config.termsOfUseURL, '_blank')

    }

    faq() {

        window.open(config.faqURL, '_blank')

    }

    feedBack() {

        // Create template email
        var to = config.feedbackEmail
        var subject = config.feedbackSubject
        var body = '\n\nTechnical details\n------------------------------'
        body += '\nUser ID: ' + BLOCKv.store.userID
        body += '\nBrowser: ' + navigator.userAgent
        body += '\nScreen size: ' + window.innerWidth + ' x ' + window.innerHeight

        window.location = 'mailto:' + encodeURIComponent(to) + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body)
        // window.location = 'mailto:' + config.feedbackEmail

    }

    onBirthdayChange = date => {

        // Update state
        this.setState({ birthday: date })

        // Update server
        BLOCKv.UserManager.updateUser({
            birthday: date.format('YYYY-MM-DD')
        }).catch(Errors.show)

    }

    onKeyPress(e) {

        // If Enter is pressed, hide the keyboard
        if (e.keyCode === 13)
            e.target.blur()

    }

    onSave(e) {

        e.preventDefault()

        // Show alert
        Swal.fire({
            title: 'Saving Profile!',
            text: 'Changes are being saved',
            showCancelButton: false,
            showConfirmButton: false
        })

        // List of pending promises
        var promises = []

        // Update birthday
        promises.push(BLOCKv.UserManager.updateUser({
            'birthday': this.state.birthday
        }))

        // Wait for promises to finish
        Promise.all(promises).then(e => {

            // Log analytics
            Analytics.event('Action:SaveProfile')

            // Done
            Swal.fire({
                title: 'Sucess',
                text: 'Profile Updated Successfully!',
                type: 'success'
            })

            // Go back to the inventory
            window.location.hash = '/inventory'

        }).catch(Errors.show)

    }

    /** Called when the user clicks the Copy button next to the Ethereum address */
    copyEthereumAddress() {

        // Stop if no address
        if (!this.state.ethereumAddress)
            return

        // Create fake input field - From https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
        let field = document.createElement('textarea')
        field.value = this.state.ethereumAddress
        document.body.appendChild(field)
        field.select()
        document.execCommand('copy')
        document.body.removeChild(field)

        // Done
        alert('Your Ethereum address has been copied.')

    }

    toggleAdvanced() {

        (this.state.showAdvanced == true) ? this.setState({ showAdvanced: false }) : this.setState({ showAdvanced: true })
        this.forceUpdate()
        
    }

    async clearCache() {

        Analytics.event('Profile:ClearCache',{'event_category':'profile'})

        // Delete all caches
        if (typeof caches !== 'undefined') {

            for (let key of await caches.keys())
                await caches.delete(key)

        }

        // Remove service worker
        if (navigator.serviceWorker) {

            for (let reg of await navigator.serviceWorker.getRegistrations())
                await reg.unregister()

        }

        // Clear data pool cache
        BLOCKv.dataPool.clearCache()

        // Done, refresh the page
        location.hash = '/inventory'
        location.reload()

    }

    // Enable or disable SMS/email notifications
    async toggleSMSEmailNotifications() {

        let smsState = this.state.smsEmailNotifications

        if(smsState === true) {

            let disableNotification = await Swal.fire({
                title: "Are you sure?",
                text: "You will no longer receive email or SMS notifications when receiving vatoms. This includes the notification when receiving the winning sweepstakes vatom. Are you sure you would like to disable notifications? ",
                icon: "info",
                showCancelButton: true
            })

            if(disableNotification.dismiss == "cancel")
              return

            // Send API call
            await BLOCKv.UserManager.updateUser({ nonpush_notification: !this.state.smsEmailNotifications })

            Swal.fire({
                title: "Notifications Updated",
                icon: "success",
                showCancelButton: false
            })

            this.setState({ smsEmailNotifications: !this.state.smsEmailNotifications })
            
        }

        if(smsState === false) {

            
            // Send API call
            await BLOCKv.UserManager.updateUser({ nonpush_notification: !this.state.smsEmailNotifications })

            Swal.fire({
                title: "Notifications Updated",
                text: "You will receive email or SMS notifications to your primary address when receiving vatoms.",
                icon: "success",
                showCancelButton: false
            })

            this.setState({ smsEmailNotifications: !this.state.smsEmailNotifications })
            
        }
        

        
        

    }

    getClientName(hostname){
           
            var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
            var urlParts = regexParse.exec(hostname);
            let c = hostname.replace(urlParts[0],'').slice(0, -1) || 'vatom viewer'
            return c
    }
            

    // Enable to disable push notifications
    async togglePushNotifications() {

        // Check what to do
        if (this.state.bulkNotifications === true) {

            let disablePush = await Swal.fire({
                title: "Are you sure?",
                text: "You will no longer receive email or SMS notifications about the brand’s vatom offers and promotions. Are you sure you would like to disable notifications?",
                icon: "info",
                showCancelButton: true
            })

            if(disablePush.dismiss == 'cancel')
              return

            await BLOCKv.UserManager.updateUser({
                
                notification_settings: {
                    bulk_notifications: {
                        allow_notifications: false
                    }
                }
                  
            })

            // Done
            this.setState({ bulkNotifications: false })
            this.setState({ pushNotifications: false })
            Swal.fire({
                title: 'Notifications updated',
                text: 'You will no longer receive email or SMS notifications about the brand’s vatom offers and promotions.',
                icon: 'success',
                showCancelButton: false
            })

        } else {


            await BLOCKv.UserManager.updateUser({
                
                notification_settings: {
                    bulk_notifications: {
                        allow_notifications: true
                    }
                }
                  
            })

            // Done
            this.setState({ bulkNotifications: true })
            this.setState({ pushNotifications: true })
            Swal.fire({
                title: 'Notifications updated',
                text: 'You will now receive email or SMS notifications to your primary address about the brand’s vatom offers and promotions.',
                icon: 'success',
                showCancelButton: false
            })

        }

    }

    async deleteProfile() {

        let del = await Swal.fire({
            title: "Are you sure?",
            text: "You will no longer be able to log in to your account. Are you sure you want to delete your profile? ",
            showConfirmButton: true,
            showCancelButton: true
        })

        // user pressed cancel
        if(del.dismiss == 'cancel')
          return

        await BLOCKv.client.request('DELETE', '/v1/user', null, true)

        this.logout()        

    }

    render() {
        
        if (!this.state.profile)
            return <div style={{ margin: '160px 20px', fontSize: 21, fontStyle: 'italic', color: '#AAA', textAlign: 'center' }}>Loading profile...</div>

        // Get user info
        let eArray = this.state.tokens || []
        let username = eArray.find(a => a.properties.token_type === 'username') || ''
        let has_email = eArray.find(a => a.properties.token_type === 'email') || ''
        let has_phone = eArray.find(a => a.properties.token_type === 'phone_number') || ''
        let show_email = ( has_email || (!has_email && !has_phone) ) ? 1 : 0
        let show_phone = ( has_phone || (!has_email && !has_phone) ) ? 1 : 0

        let user = this.state.profile || {}
        let avatarURL = this.state.avatar || BLOCKv.UserManager.encodeAssetProvider(this.state.profile.avatarUri) || require('./Profile.addAvatar.svg')
        let avatarSizing = (user.avatarUri) ? 'cover' : 'default'

        let clientName = this.getClientName(window.location.hostname)

        // Birthday field
        let birthdayField = <MuiPickersUtilsProvider utils={dayjsUtils}>
            <DatePicker value={this.state.birthday || null} disableFuture placeholder='Select' format='DD MMMM YYYY' openTo='year' TextFieldComponent={InputBase} onFocus={(e) => e.target.readOnly = true} onChange={this.onBirthdayChange} />
        </MuiPickersUtilsProvider>

        const profileLinks = config.customLinks

        return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'auto', WebkitOverflowScrolling: 'touch'}}>

            {/* Header bar */}
            <div style={{ display: 'block', position: 'relative', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.9)', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)' }}>

                {/* Header container */}
                <div style={{ position: 'fixed', zIndex: '10', top: 0, left: 0, width: '100%', height: 48, backgroundColor: 'rgba(255, 255, 255, 1.0)' }}>

                    {/* Back button */}
                    <div onClick={e => this.back()} style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 48,
                        height: 48,
                        cursor: 'pointer',
                        backgroundImage: `url(${require('./Profile.back.svg')})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }} />

                    {/* Title label */}
                    <div style={{ position: 'absolute', top: 0, left: 48, width: 'calc(100% - 48px - 48px)', height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial', fontSize: 16, textAlign: 'center' }}>
                            { (this.state.profile && this.state.profile.firstName) + ' ' + (this.state.profile && this.state.profile.lastName) || 'Profile' }
                        </div>
                    </div>

                    {/* Save button */}
                    <div onTouchEnd={this.onSave.bind(this)} onClick={this.onSave.bind(this)} style={{
                        display: this.state.hasChanges ? 'inline-block' : 'none',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        margin: 16,
                        fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
                        fontSize: '15px',
                        color: '#56c2c9',
                        cursor: 'pointer',
                        textTransform: 'uppercase'
                    }}>Save</div>

                    {/* Profile image */}
                    <div style={{
                        display: this.state.hasChanges ? 'none' : 'block',
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        width: 24,
                        height: 24,
                        borderRadius: (avatarURL && this.state.toggleIcon === 'profile') ? '0%' : '50%',
                        overflow: 'hidden',
                        backgroundColor: '#f8f8f8',
                        backgroundImage: (avatarURL && this.state.toggleIcon === 'profile') ? `url(${require('./qrcode.svg')})` : `url(${avatarURL})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: `${avatarSizing}`
                    }} onClick={ this.toggleJumbo.bind(this)} />

                </div>

                <div style={{
                    display: (this.state.toggleIcon === 'profile') ? 'block' : 'none',
                    position: 'absolute',
                    top: 72,
                    left: 'calc(50% - 60px)',
                    width: 120,
                    height: 120,
                    backgroundImage: `url(${avatarURL})`,
                    backgroundSize: avatarSizing,
                    backgroundPosition: 'center'
                }} onClick={this.onAvatarSelected}>

                    {/* Hidden file selector component */}
                    <input type='file' accept=".png,.gif,.jpg,.jpeg" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} onChange={this.onAvatarSelected.bind(this)} />

                </div>
                {/* QR code */}
                <QRCode style={{
                    display: (this.state.toggleIcon === 'qr') ? 'block' : 'none',
                    position: 'absolute',
                    top: 72,
                    left: 'calc(50% - 60px)',
                    width: 120,
                    height: 120
                }} value={'https://viewer.blockv.io/#/receive/' + this.state.profile.id} />

                {/* Spacing for the above components */}
                {/* TODO: Make the above components not absolutely positioned? */}
                <div style={{ height: 216 }} />

                {/* User's userID */}
                <div style={{ marginTop: 16, fontSize: 12, textAlign: 'center' }}>
                    <div style={{ color: '#666', fontWeight: 'bold', paddingBottom: 5 }}>User ID</div>
                    <div style={{ color: '#666', userSelect: 'text', paddingBottom: 10 }}>{(this.state.profile && this.state.profile.id)}</div>
                    { (username) ? <div style={{ color: '#666', userSelect: 'text', paddingBottom: 10 }}>{username.properties.token}</div> : ''}
                </div>

                {/* Section divider */}
                <TableGroupHeader title={'Account Information'} />
                <TableCell.Value1 title={'Name'} rightComponent={`${this.state.first_name} ${this.state.last_name}`.trim()} onClick={e => SetNamePopup.show({ preloadFirstName: this.state.first_name, preloadLastName: this.state.last_name, onComplete: e => this.refreshUserProfile() })} />
                <TableCell.Divider />
                <TableCell.Value1 title={'Password'} rightComponent={'••••••••'} onClick={e => SetPasswordPopup.show()} />
                <TableCell.Divider />
                <TableCell.Value1 title={'Birthday'} rightComponent={birthdayField} />

                {/* Section divider */}
                <TableGroupHeader title={'Email Addresses'} style={{ display: (show_email) ? 'block' : 'none' }}/>
                {eArray.map(t => {

                    if (t.properties.token_type !== 'email')
                        return

                    let tokenName = `${t.properties.token} ${t.properties.is_default ? '(Default)' : ''}`
                    return <div>

                        <TableCell title={tokenName} accessory={this.tokenMenu(t)} />
                        <TableCell.Divider />
                        <div style={{
                            display: t.properties.confirmed ? 'none' : 'block',
                            padding: 15,
                            color: '#FFFFFF',
                            backgroundColor: '#db5029',
                            textAlign: 'center',
                            fontSize: 14
                        }}>{'Email not verified'}</div>

                        <div>

                        </div>

                    </div>

                })}

                <div onClick={ () => AddEmailPopup.show({ skipVerify: true, onComplete: e => this.refreshUserTokens() }) } style={{
                    display: (show_email && !has_email) ? 'block' : 'none',
                    padding: '10px 14px 10px 24px',
                    fontSize: '16px',
                    color: '#72c0c7',
                    cursor: 'pointer',
                    flexShrink: 0,
                    flexGrow: 0
                }}>{'+ New Email Address'}</div>
                {/* Unverified email alert */}


                {/* Section divider */}
                <TableGroupHeader title={'Phone Numbers'} style={{ display: (show_phone) ? 'block' : 'none' }} />
                {eArray.map(t => {

                    if (t.properties.token_type !== 'phone_number')
                        return

                    let tokenName = `${t.properties.token} ${t.properties.is_default ? '(Default)' : ''}`
                    return <div>
                        <TableCell title={tokenName} accessory={this.tokenMenu(t)} />
                        <TableCell.Divider />
                        <div style={{
                            display: t.properties.confirmed ? 'none' : 'block',
                            padding: 15,
                            color: '#FFFFFF',
                            backgroundColor: '#db5029',
                            textAlign: 'center',
                            fontSize: 14
                        }}>{'Phone not verified'}</div>
                    </div>

                })}

                <div onClick={() => AddPhonePopup.show({ skipVerify: true, onComplete: e => this.refreshUserTokens() })} style={{
                    display: (show_phone && !has_phone) ? 'block' : 'none',
                    padding: '10px 14px 10px 24px',
                    fontSize: '16px',
                    color: '#72c0c7',
                    cursor: 'pointer',
                    'flexShrink': 0,
                    'flexGrow': 0
                }}>{'+ New Phone Number'}</div>


                {/* Merge Accounts Popup */}
                {/*
                <TableGroupHeader title={'Merge Accounts'} />
                <TableCell title={'Merge Accounts'} accessory='arrow' onClick={e => MergePopup.show({
                    onComplete: e => this.refreshUserProfile()
                })} />
                */}
                
                {/* Notifications */}
                <TableGroupHeader style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 64,
                    backgroundColor: this.state.highlighted ? '#FAFAFA' : '#FFFFFF',
                    transition: 'background-color 0.1s',
                    cursor: 'default',
                    textAlign: 'left',
                    textTransform: 'capitalize'
                }} title="Advanced Settings" onClick={ this.toggleAdvanced.bind(this)} opened={this.state.showAdvanced} accessory="arrow" />
                { 
                    (this.state.showAdvanced == true) ?
                    <>
                        <TableCell.Value2 title="SMS/Email Vatom Notifications" text={this.state.smsEmailNotifications ? 'Enabled' : 'Disabled'} onClick={this.toggleSMSEmailNotifications.bind(this)} />    
                        { /*this.state.pushNotificationsSupported ? <TableCell.Value2 title={this.getClientName(window.location)} text={this.state.pushNotifications ? 'Enabled' : 'Disabled'} onClick={this.togglePushNotifications.bind(this)} />  : null */}   
                        <TableCell.Value2 title={`${clientName} Notifications`} text={this.state.bulkNotifications ? 'Enabled' : 'Disabled'} onClick={this.togglePushNotifications.bind(this)} />
                        <TableCell.Value2 title="Delete Profile" onClick={ () => this.deleteProfile()} accessory='arrow' />  
                        
                    </>
                    : null
                
                }
                {/* Ethereum Address */}
                <TableGroupHeader title="Addresses" />
                <TableCell.Value2 title="Ethereum" rightComponent={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: '1 1 auto', fontSize: 11, fontFamily: 'monospace', wordBreak: 'break-all' }}>{this.state.ethereumAddress || '-'}</div>
                        <img src={require('./copy.svg')} style={{ flex: '0 0 auto', height: 16, padding: '16px 8px 16px 16px', cursor: 'pointer' }} onClick={e => this.copyEthereumAddress()}/>
                        <img src={require('./qr-code.svg')} style={{ flex: '0 0 auto', height: 16, padding: '16px 16px 16px 8px', cursor: 'pointer' }} onClick={e => this.setState({ showEthereumCode: true })} />
                    </div>
                } />
                <div onClick={e => this.setState({ showEthereumCode: false })} style={{ position: 'fixed', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.75)', display: this.state.showEthereumCode ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ backgroundColor: '#FFF', borderRadius: 4, padding: 20 }}>
                        <QRCode value={'ethereum:' + this.state.ethereumAddress} />
                    </div>
                </div>

                {/* Cache information */}
                <TableGroupHeader title="Cache" />
                <CacheState />
                <TableCell.Divider />
                <TableCell title={'Clear Cache'} onClick={this.clearCache.bind(this)} />

                {/* Section Divider */}
                { profileLinks.length ? <TableGroupHeader title={'Custom Links'} /> : null}
                { profileLinks.map(pl => {

                    return <>
                        <TableCell title={pl.title} accessory='arrow' onClick={() => window.location.href = pl.url} />
                        <TableCell.Divider />
                    </>

                }) }

                {/* Section divider */}
                <TableGroupHeader title={'About'} />
                <TableCell title={'FAQ'} accessory='arrow' onClick={this.faq} />
                <TableCell.Divider />
                <TableCell title={'Feedback'} accessory='arrow' onClick={this.feedBack} />
                <TableCell.Divider />
                <TableCell title={'Terms of Use'} accessory='arrow' onClick={this.termsOfUser} />
                <TableCell.Divider />
                <TableCell title={'Privacy Policy'} accessory='arrow' onClick={this.privacyPolicy} />
                <TableCell.Divider />
                <TableCell.Value2 title={'Version'} text={`${require('../../../package.json').version} - SDK ${require('@blockv/sdk/package.json').version}`} />
                <TableCell.Divider />
                <TableCell title={'Logout'} accessory='arrow' onClick={this.logout} />
            </div>
        </div>

    }

}

function TableGroupHeader(props) {

    let disp = (props.style && props.style.display) ? props.style.display : 'block'

    let dropped = props.opened
    let action = null

    if(props.accessory && dropped === false)
      action = <img src={require('./TableCell.arrow.svg')}/>
    else if (props.accessory && dropped === true)
      action = <img src={DownArrow} />
    else 
      action = null

    return <div onClick={props.onClick} style={{ display: disp, padding: '23px 16px 8px 16px', fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial', fontSize: '16px', textAlign: 'left', backgroundColor: 'rgb(244, 244, 244)', color: 'rgb(151, 151, 151)' }}>
        
        <div style={{
            margin: 0,
            fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
            fontSize: '16px',
            textTransform: 'capitalize',
            color: 'rgb(74, 74, 74)',
            flexGrow: 1,
            flexShrink: 1
        }}>
        {props.title}
        </div>
        <div style={{
            position: 'relative',
            right: 5,
            top: 0,
            margin: 0,
            fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
            fontSize: '16px',
            color: 'rgb(74, 74, 74)',
            flexShrink: 1,
            flexGrow: 1,
            textAlign: 'right'
        }}>
            { action }
        </div>
    </div>

}
