import React from 'react'

const Map = ({
    style = {},
    stroke = '#888888',
    width = '20px',
    height = '16px',
    className = '',
    viewBox = '0 0 22 23'
}) => {

    return (
        <svg width={width} height={height} viewBox={viewBox} className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Page-2" stroke={stroke} stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Desktop---Option-One" transform="translate(-707.000000, -26.000000)" fill={stroke} fill-rule="nonzero">
                  <g id="Group" transform="translate(475.000000, 0.000000)">
                      <g id="Map-Icon" transform="translate(232.000000, 26.000000)">
                          <path d="M22.7184,13.9414258 C22.6758,12.848832 21.7599,11.9727484 20.64,11.9727484 L17.2023,11.9727484 L18.0615,10.8916743 L18.1503,10.7540293 C18.8373,9.64873438 19.2,8.37861995 19.2,7.07985411 C19.2,3.19596235 15.972,0.019790155 11.9958,0 C8.028,0.0200855305 4.8,3.19596235 4.8,7.07985411 C4.8,8.37832458 5.1627,9.648439 5.8497,10.7540293 L6.8142,11.9727484 L3.36,11.9727484 C2.2401,11.9727484 1.3242,12.848832 1.2816,13.9414258 L0.0129,20.8112679 L0,20.952162 C0,22.0813824 0.933,23 2.0799,23 L21.9198,23 C23.067,23 24,22.0813824 24,20.952162 L22.7184,13.9414258 Z M11.9958,1.57553264 C15.0858,1.59089216 17.5998,4.06023091 17.5998,7.07985411 C17.5998,8.0929919 17.3154,9.08368115 16.7775,9.9444052 L12.2655,15.6271334 C12.1785,15.7373085 12.0615,15.7535541 12,15.7535541 C11.9385,15.7535541 11.8215,15.7373085 11.7342,15.6268381 L7.2375,9.97010287 L7.2222,9.94470058 C6.6843,9.08397652 6.3999,8.09328727 6.3999,7.08014949 C6.3999,4.06023091 8.9142,1.59089216 11.9958,1.57553264 Z M21.9201,21.4247627 L2.0799,21.4247627 C1.8336,21.4247627 1.6305,21.2413346 1.6029,21.0062157 L2.8671,14.1614805 L2.88,14.0205864 C2.88,13.7600652 3.0954,13.5479857 3.36,13.5479857 L8.0607,13.5479857 L10.4739,16.5974418 C10.8429,17.0623627 11.3991,17.3287914 11.9997,17.3287914 C12.6003,17.3287914 13.1565,17.0623627 13.5255,16.5977372 L15.9498,13.5479857 L20.64,13.5479857 C20.9046,13.5479857 21.12,13.7600652 21.12,14.0205864 L22.3968,21.0062157 C22.3695,21.2413346 22.1661,21.4247627 21.9201,21.4247627 Z" id="Shape"></path>
                          <circle id="Oval" cx="12" cy="8" r="2"></circle>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
    )

}

export default Map
