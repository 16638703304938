//
// TableCell - Represents a cell in a table

import React from 'react'

export default class TableCell extends React.Component {

    constructor(props) {

        super(props)

        // Set state
        this.state = {
            highlighted: false
        }

    }

    render() {

        // Create cell
        return <div
            onClick={this.onClick.bind(this)}
            onMouseOver={e => this.setState({ highlighted: true })}
            onMouseOut={e => this.setState({ highlighted: false })}

            onTouchStart={e => this.setState({ highlighted: true })}
            onTouchEnd={e => this.setState({ highlighted: false })}
            onTouchCancel={e => this.setState({ highlighted: false })}

            style={{
                display: 'flex',
                alignItems: 'center',
                height: 64,
                backgroundColor: this.state.highlighted ? '#FAFAFA' : '#FFFFFF',
                transition: 'background-color 0.1s',
                cursor: 'default',
                textAlign: 'left',
                textTransform: 'capitalize'
            }}>

            {/* Components */}
            {this.renderLeftAccessory()}
            {this.renderLeftText()}
            {this.renderRightText()}
            {this.renderRightAccessory()}

        </div>

    }

    onClick(e) {

        // Prevent default action
        e.preventDefault()

        // Trigger handler
        if (this.props.onClick)
            this.props.onClick()

    }

    renderLeftAccessory() {

    }

    renderLeftText() {

        return <div style={{
            margin: 16,
            fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
            fontSize: '16px',
            textTransform: (this.props.title.indexOf('@') !== -1) ? 'none' : 'capitalize',
            color: 'rgb(74, 74, 74)',
            flexGrow: 1,
            flexShrink: 1
        }}>
            {this.props.title}
        </div>

    }

    renderRightText() {

    }

    renderRightAccessory() {

        // Check if accessory is an image URL
        if (this.props.accessory === 'arrow')
            return <img src={require('./TableCell.arrow.svg')} style={{ margin: 16 }}/>

        // Use it directly
        return this.props.accessory

    }

}

TableCell.Divider = function(props) {

    return <div style={{ display: 'flex', width: '100%', height: 1, backgroundColor: '#FFFFFF' }} >
        <div style={{ marginLeft: 16, flexGrow: 1, flexShrink: 1, backgroundColor: 'rgb(241, 241, 241)' }} />
    </div>

}

TableCell.Value1 = class TableCellValue1 extends TableCell {

    renderLeftText() {

        return <div style={{
            width: 80,
            margin: 16,
            fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
            fontSize: '16px',
            color: 'rgb(74, 74, 74)'
        }}>
            {this.props.title}
        </div>

    }

    renderRightText() {

        return <div style={{
            position: 'relative',
            margin: 16,
            fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
            fontSize: '16px',
            color: 'rgb(74, 74, 74)',
            flexShrink: 1,
            flexGrow: 1
        }}>
            {this.props.rightComponent || this.props.text}
        </div>

    }

}

TableCell.Value2 = class TableCellValue1 extends TableCell {

    renderLeftText() {

        return <div style={{
            margin: 16,
            fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
            fontSize: '16px',
            color: 'rgb(74, 74, 74)',
            flexGrow: 1,
            flexShrink: 1
        }}>
            {this.props.title}
        </div>

    }

    renderRightText() {

        return this.props.rightComponent || <div style={{
            margin: 16,
            fontFamily: 'ProximaNova, Helvetica Neue, Helvetica, Arial',
            fontSize: '16px',
            fontWeight: '400',
            textAlign: 'right',
            color: 'rgb(151, 151, 151)',
            flexShrink: 1,
            flexGrow: 1
        }}>
            {this.props.text}
        </div>

    }

}
