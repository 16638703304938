import JSAlert from 'js-alert'
import Errors from '../Common/Errors'

//
// This decorator shows a loader to the user while the function is running, and if there's an error
// it will show the error.
//
// Use like:
//
//      @Loader
//      async function X() { ... }

export default function Loader(target, key, descriptor) {

    // Get original function
    var func = descriptor.value

    // Create replacement function
    descriptor.value = function() {

        // Show loader
        let loader = JSAlert.loader('Please wait...')

        // Run the function
        return Promise.resolve(func.apply(this, arguments)).then(async e => {

            // Done, remove loader and pass on the value
            loader.dismiss()
            return e

        }).catch(async err => {

            // Failed, remove loader and show the error
            loader.dismiss()
            Errors.show(err)

        })

    }

}
