import React from 'react'

const Coins = ({
    style = {},
    stroke = '#888888',
    width = '30px',
    height = '18px',
    className = '',
    viewBox = '0 0 24 24'

}) => {

    return (
    <svg width={width} height={height} viewBox={viewBox} className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
      <g stroke={stroke} stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-956.000000, -66.000000)" fill={stroke} fill-rule="nonzero">
              <g id="Group" transform="translate(956.000000, 66.000000)">
                  <path d="M10.9714286,0 C4.91207589,0 0,4.91207589 0,10.9714286 C0,17.0307813 4.91207589,21.9428571 10.9714286,21.9428571 C17.0307813,21.9428571 21.9428571,17.0307813 21.9428571,10.9714286 C21.9363023,4.91479305 17.0280641,0.0065548061 10.9714286,0 Z M10.9714286,20.1142857 C5.921968,20.1142857 1.82857143,16.0208891 1.82857143,10.9714286 C1.82857143,5.921968 5.921968,1.82857143 10.9714286,1.82857143 C16.0208891,1.82857143 20.1142857,5.921968 20.1142857,10.9714286 C20.1083411,16.0184247 16.0184247,20.1083411 10.9714286,20.1142857 Z" id="Shape"></path>
                  <path d="M21.0285714,0 C27.0852069,0.0065548061 31.9934452,4.91479305 32,10.9714286 C32,17.0307813 27.0879241,21.9428571 21.0285714,21.9428571 C20.0362821,21.9428571 19.0747603,21.8111258 18.16054,21.564197 C18.8161224,21.1175126 19.4280647,20.6124842 19.9887634,20.0559036 C20.3293268,20.0943999 20.6766268,20.1142857 21.0285714,20.1142857 C26.0755676,20.1083411 30.1654839,16.0184247 30.1714286,10.9714286 C30.1714286,5.921968 26.078032,1.82857143 21.0285714,1.82857143 C20.6762835,1.82857143 20.3286492,1.84849602 19.9867339,1.88727979 C19.4273328,1.32976903 18.8157304,0.825078019 18.1606383,0.379570086 C19.0747603,0.13173138 20.0362821,0 21.0285714,0 Z" id="Combined-Shape"></path>
              </g>
          </g>
      </g>
    </svg>
    )

}

export default Coins
