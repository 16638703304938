import React from 'react'
import Config from '../Common/Config'

//
// This decorator re-renders the React component when the app's config changes.
export default function RefreshOnConfigUpdate(OriginalComponent) {

    // Return new component
    return class extends React.PureComponent {

        // State used to trigger original component re-render
        state = {
            updateCount: 0
        }

        // Add listener on component mount
        componentDidMount() {
            Config.addEventListener('updated', this.refresh)
        }

        // Remove listener on component unmount
        componentWillUnmount() {
            Config.removeEventListener('updated', this.refresh)
        }

        // On refresh, update the count and re-render original component
        refresh = e => {
            this.setState({ updateCount: this.state.updateCount + 1 })
        }

        // Render original component
        render() {
            return <OriginalComponent configUpdateCount={this.state.updateCount} {...this.props} />
        }

    }

}