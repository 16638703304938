import React from 'react'
import Header from '../../Components/Header'
import VatomGrid from './VatomGrid'
import SizeReactiveComponent from '../../Components/SizeReactiveComponent'
import BLOCKv from '../../Common/Blockv'
import CategorySelector from '../../Popups/CategorySelector'
import SearchPopup from '../../Popups/SearchPopup'
import AppInstallManager from '../../Code/AppInstallManager'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import config from '../../Common/Config'
import CacheComponent from '../../Decorators/CacheComponent'
import AuthorisedOnly from '../../Decorators/AuthorisedOnly'
import Firebase from '../../Common/Firebase'
import NetworkManager from '../../NetworkManager'
import Loader from '../../Decorators/Loader'
import Errors from '../../Common/Errors'
import Analytics from '../../Code/Analytics'
import SortPopup from './SortPopup'
import MergePopup from '../../Popups/MergePopup'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import RefreshOnConfigUpdate from '../../Decorators/RefreshOnConfigUpdate'
import SynchronizingMessage from './SynchronizingMsg'

const theme = require('../../theme/theme')
/** The latest consent document version */
const LatestConsentDocumentVersion = config.consentDocumentVersion

export default class InventoryScreen extends SizeReactiveComponent {

    constructor(props) {

        // Setup superclass
        super(props)

        // Properties
        this.state = {
            mobile: false,
            scroll: true,
            displaySettings: {},
            category: localStorage.getItem('ui.category') || '',
            isOnline: true,
            showConsentPrompt: false,
            sortPopupVisible: false,
            titles: localStorage['ui.titles'] === 'on',
            sort: localStorage['ui.sort'] || 'newest',
            isGuest: false,
            coinValue: '',
            tab: 'inventory',
            gridKey: 2
        }

        // Bind functions
        this.onNetworkStateChange = this.onNetworkStateChange.bind(this)
        this.onAppInstallReady = this.onAppInstallReady.bind(this)

    }

    toggleMobileSettings() {

        this.setState({ mobile: !this.state.mobile })

    }

    /* Get total coin value from vatom grid */

    receiveCoinValue(value) {
      this.setState({coinValue: value})
      localStorage.setItem('coinValue', value)
    }

    /** @private Called on screen load */
    async componentDidMount() {

        this.setState({ gridKey: this.props.walletTab })

        super.componentDidMount()

        // Log analytics
        Analytics.screen('Inventory')

        // Go back if not logged in
        if (!BLOCKv.UserManager.isLoggedIn) {

            CacheComponent.clearCache()
            window.location.hash = '/'
            return

        }

        // Send the user's refresh token to the host app, if any. NOTE: Make sure we only
        // send the event to host apps on our same domain (second param).
        if (window.opener) {

            /* vlabs
            window.opener.postMessage({
                action: 'com.blockv.viewer:LoggedIn',
                refreshToken: BLOCKv.store.getAccessToken()
            }, window.location.href)
            */

        }

        // Listen for other events
        NetworkManager.addEventListener('statechange', this.onNetworkStateChange)
        AppInstallManager.addEventListener('readyToInstall', this.onAppInstallReady)
        window.addEventListener('online', this.onNetworkStateChange)
        window.addEventListener('offline', this.onNetworkStateChange)

        // Do network check now
        this.onNetworkStateChange()

        // If the user clicked on an Acquire link which took them through the login process, do the acquire now
        let newHash = window._hashAfterLogin
        if (newHash) {

            window._hashAfterLogin = null
            window.location.hash = newHash

        }

        // Check if user has consented to the terms
        BLOCKv.UserManager.getCurrentUser().then(user => {

            if (user.guestId)
                this.setState({ isGuest: true })

            // Check if consented already
            let consent = user.consents.find(c => c.app_id === BLOCKv.store.APPID || c.fqdn === config.pubFQDN)
            let consentVersion = consent?.version || -1
            if (!LatestConsentDocumentVersion || consentVersion >= LatestConsentDocumentVersion)
                return

            // Not consented, show prompt
            console.log('User has not consented yet, showing prompt')
            this.setState({ showConsentPrompt: true })

        }).catch(err => {

            // Failed
            console.warn('Unable to fetch user profile to check consent.', err)

        })

        // Send push token to server if needed
        this.updatePushToken()

    }



    async updatePushToken() {

        // Stop if disabled
        if (localStorage['push'] !== 'on')
            return

        // Wait for firebase to init
        await Firebase.setupMessaging()

        // Check if got a token
        let token = await Firebase.messaging.getToken()
        if (!token)
            return

        // Send it to the server
        console.log('[Inventory] Sending Firebase token to the server...', token)
        await BLOCKv.client.request('POST', '/v1/user/pushnotification', {
            platform_id: 'web',
            fcm_token: token,
            on: true
        }, true)

    }

    /** @private Called on screen unload */
    componentWillUnmount() {

        super.componentWillUnmount()

        // Remove event listeners
        NetworkManager.removeEventListener('statechange', this.onNetworkStateChange)
        AppInstallManager.removeEventListener('readyToInstall', this.onAppInstallReady)
        window.removeEventListener('online', this.onNetworkStateChange)
        window.removeEventListener('offline', this.onNetworkStateChange)

    }

    /** Called when the browser has deemed us worthy to be installed onto the device home screen */
    onAppInstallReady() {

        this.forceUpdate()

    }

    /** Called when the user clicks the install button */
    installApp() {

        // Do install
        AppInstallManager.install().then(e => {

            // Log analytics
            Analytics.event('AppAction:Install')

        }).catch(Errors.show).then(e => {

            // Done
            this.forceUpdate()

        })

    }

    /** Called when the user cancels the app install request */
    hideInstall() {

        // Don't show it again for 2 days
        AppInstallManager.hideUntil = Date.now() + 1000 * 60 * 60 * 24
        this.forceUpdate()

        // Log analytics
        Analytics.event('AppAction:HideInstall')

    }

    /** Called when the network cache state changes */
    onNetworkStateChange() {

        // Store state so that it triggers a UI refresh
        this.setState({ serviceWorkerState: NetworkManager.state })

    }

    /** Called when the user wants to install the app update downloaded by the service worker */
    @Loader async installUpdate() {

        // Wait for service worker to activate
        await NetworkManager.installUpdates()

    }

    /** Called when the user clicks on the Accept Terms button */
    async acceptTerms() {

        // Hide the dialog
        this.setState({ showConsentPrompt: false })

        // Update user
        await BLOCKv.UserManager.updateUser({
            user_consent: LatestConsentDocumentVersion
        })

    }

    /** Called when the user clicks on the Deny Terms button */
    async denyTerms() {

        // Hide the dialog
        this.setState({ showConsentPrompt: false })

        // Log out, ignore errors since the session is cleared either way in the SDK
        try {

            await BLOCKv.UserManager.logout()

        } catch (err) {

            console.warn('Error during logout', err)

        }

        // Go back to login screen
        location.hash = '/'

    }

    /** @private Render components */
    render() {

        localStorage.setItem('tab', this.state.gridKey)

        if (!BLOCKv.UserManager.isLoggedIn) {

            CacheComponent.clearCache()
            window.location.hash = '/'
            return <div>Not logged in</div>

        }

        // Create list of possible popups.
        let alertPopups = []

        // If app is checking for updates, show alert
        alertPopups.push({
            visible: NetworkManager.state === NetworkManager.State.Checking,
            content: <React.Fragment>
        Checking for update...
            </React.Fragment>
        })

        // If app is downloading updates, show alert
        alertPopups.push({
            visible: NetworkManager.state === NetworkManager.State.Downloading && NetworkManager.servedFromCache,
            content: <React.Fragment>
        Downloading update...
            </React.Fragment>
        })
        alertPopups.push({
            visible: this.state.isGuest,
            style: {
                backgroundColor: theme.guestRegistrationBar.backgroundColor,
                color: theme.guestRegistrationBar.textColor,
                textAlign: theme.guestRegistrationBar.textAlign
            },
            content: <React.Fragment>
                <a onClick={ () => MergePopup.show({ onMergeComplete: e => this.setState({ isGuest: false }) }) }>{theme.guestRegistrationBar.text}</a>
            </React.Fragment>

        })
        alertPopups.push({
            visible: NetworkManager.state === NetworkManager.State.Downloading && !NetworkManager.servedFromCache,
            content: <React.Fragment>
        Preparing offline support...
            </React.Fragment>
        })

        // If app has an update ready, show alert
        alertPopups.push({
            visible: (NetworkManager.state === NetworkManager.State.Waiting && navigator.serviceWorker && navigator.serviceWorker.controller) || config.hasPendingUpdate,
            content: <React.Fragment>
        An update is ready! <a style={{ textDecoration: 'none', color: '#08F', cursor: 'pointer' }} onClick={this.installUpdate.bind(this)}>Click here</a> to activate it.
            </React.Fragment>
        })

        // If guest, show merge account bar
        alertPopups.push({
            visible: this.state.isGuest,
            content: <React.Fragment>
        Already Registered? To merge with existing account <a style={{ textDecoration: 'none', color: '#08F', cursor: 'pointer' }} onClick={() => MergePopup.show()}>Click here</a>.
            </React.Fragment>
        })

        // If app is ready to be installed natively, show alert
        alertPopups.push({
            visible: AppInstallManager.shouldShowInstallPrompt,
            onClose: this.hideInstall.bind(this),
            content: <React.Fragment>
                <a style={{ textDecoration: 'none', color: '#08F', cursor: 'pointer' }} onClick={this.installApp.bind(this)}>Click here </a>
                to add to your home screen.
            </React.Fragment>
        })

        // Show an alert to the user if they're offline
        alertPopups.push({
            visible: !this.state.isOnline,
            style: { backgroundColor: '#fff7ea' },
            content: <React.Fragment><b>You are currently offline. </b> Some features of the app may be disabled.</React.Fragment>
        })

        // If on iOS, show a popup that tells the user how to add a bookmark
        alertPopups.push({
            visible: config.features.bookmark && /(iPad|iPhone|iPod)/g.test(navigator.platform) && !localStorage['do-not-show.ios-bookmark'] && !/(CriOS)/g.test(navigator.userAgent),
            onClose: e => {

                localStorage['do-not-show.ios-bookmark'] = 'true'
                this.forceUpdate()

                // Log analytics
                Analytics.event('AppAction:HideiOSBookmarkMessage')

            },
            content: <React.Fragment>
                Hold down the <img alt='bookmark' src={require('./ios-bookmark.svg')} style={{ display: 'inline-block', width: 16, height: 16, padding: '0px 2px', verticalAlign: 'text-bottom' }} /> button to add a bookmark.
            </React.Fragment>
        })

        // Filter out hidden alerts and alerts the user has closed
        let alertPopup = alertPopups.find(a => a.visible)

        let customHTML = ReactHtmlParser(config.consentHTML)

        // Render view container
        return <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

            {/* Show consent prompt */}
            <Dialog open={this.state.showConsentPrompt} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{'Accept Terms and Privacy Policy'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
            By using this app, you agree to our <a target='_blank' href={config.termsOfUseURL}>Terms of Use</a> and our <a target='_blank' href={config.privacyPolicyURL}>Privacy Policy</a>.
                    </DialogContentText>
                    {customHTML}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.denyTerms.bind(this)} color="primary">
              Disagree
                    </Button>
                    <Button onClick={this.acceptTerms.bind(this)} color="primary">
              Agree
                    </Button>
                </DialogActions>

            </Dialog>

            {/* Header */}
            <Header style={{ flex: '0 0' }} tab = {this.props.walletTab === 'inventory' ? 'inventory' : this.props.walletTab === 'coins' ? 'coins' : null} indicatorValue={this.state.coinValue}  />

            {/* Subheader */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: this.isMobile ? '16px' : '6%',
                    marginRight: this.isMobile ? '16px' : '6%'
                }}
            >

                {/* Logo */}
                {(config.features.header.logo) ? <img alt='logo' src={theme.images.inventoryLogo} style={{ margin: this.isMobile ? '24px 24px 24px 0px' : '50px 40px 50px 0px', height: this.isMobile ? 22 : 36 }} /> : null }

                {/* Category */}
                {(config.features.header.category) ? <div style={{ fontSize: this.isMobile ? 21 : 25, cursor: 'pointer' }} onClick={this.showCategorySelector.bind(this)}>
                    {(e => {

                        // Output category name
                        if (this.state.category === 'special:favorite')
                            return 'Favorites'
                        else if (!this.state.category)
                            return 'All Vatoms'
                        else
                            return this.state.category

                    })()}
                    <img style={{ paddingLeft: 6, verticalAlign: 'middle' }} src={require('../../images/chevron-down.svg')} alt='down' />
                </div>
                    : null }

                {/* Divider */}
                <div style={{ flexGrow: '1' }} />

                {/* Right-side buttons */}
                {(config.features.header.sort) ? <img alt='more' src={require('./more.svg')} style={{ cursor: 'pointer', padding: 8 }} onClick={e => this.setState({ sortPopupVisible: true })} /> : null }
                {(config.features.header.search) ? <img alt='search' src={require('../../images/Search.svg')} style={{ cursor: 'pointer', padding: 8, paddingRight: 0 }} onClick={this.showSearch.bind(this)} /> : null }

            </div>

            {/* Show alert, if any */}
            {alertPopup ? <div style={Object.assign({ display: 'flex', flex: '0 0 auto', alignItems: 'center', height: 44, backgroundColor: '#FAFAFA', fontSize: 13, textAlign: 'center' }, alertPopup.style)}>
                <div style={{ flex: '1 1 auto', color: '#AAA', margin: '0px 20px' }}>{alertPopup.content}</div>
                <div style={{ flex: '0 0 auto', width: 44, height: 44, display: alertPopup.onClose ? 'inline-block' : 'none', backgroundSize: '16px 16px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: 'url(' + require('./close.svg') + ')', opacity: '0.25', cursor: 'pointer' }} onClick={alertPopup.onClose} />
            </div> : null}

            {/* Vatoms */}
            <VatomGrid ref='grid' inventory={BLOCKv.dataPool.region('inventory')} getCoinValue={this.receiveCoinValue.bind(this)}
                key={this.state.gridKey}
                page={this.state.gridKey}
                scrollKey='main'
                displaySettings={this.state.displaySettings}
                onVatomActivated={this.onVatomActivated.bind(this)}
                iconSize={128}
                style={{ position: 'relative', flex: '1 1', paddingLeft: this.isMobile ? '0px' : '5%', paddingRight: this.isMobile ? '0px' : '5%' }}
                category={this.state.category}
                titles={this.state.titles}
                sort={this.state.sort} />

            {/* If downloading updates, show progress text and a progress bar */}
            {this.state.downloadingUpdate ? <div style={{ flex: '0 0' }}>
                <div style={{ margin: '30px 30px 10px 30px', textAlign: 'center', fontSize: 11, color: '#444' }}>Downloading new version...</div>
                <div style={{ position: 'relative', margin: '0px 20% 30px 20%', backgroundColor: '#EEE', overflow: 'hidden', borderRadius: 3, height: 6 }}>
                    <div style={{ position: 'absolute', top: 0, left: 0, width: Math.floor(this.state.downloadProgress * 100) + '%', height: '100%', backgroundColor: '#41749d', transition: 'width 0.2s' }} />
                </div>
            </div> : null}

            {/* Sort popup */}
            <SortPopup visible={this.state.sortPopupVisible} onHide={e => this.setState({ sortPopupVisible: false })}
                sort={this.state.sort} onSortChanged={this.setSort}
                titles={!!this.state.titles} onTitlesChanged={this.setTitlesVisible} />

            {/* Synchronizing alert */}
            {config.features?.inventory?.synchronizing_alert ?
                <SynchronizingMessage />
            : null}

        </div>

    }

    /** @private Called when the user toggles the sort */
    setSort = mode => {

        // Set state
        this.setState({ sort: mode })

        // Store value for next visit
        localStorage['ui.sort'] = mode

    }

    /** @private Called when the user toggles the titles */
    setTitlesVisible = e => {

        // Set state
        this.setState({ titles: e.target.checked })

        // Store value for next visit
        localStorage['ui.titles'] = e.target.checked ? 'on' : 'off'

    }

    /** @private Called when the user clicks the category dropdown */
    showCategorySelector() {

        // Show category selector
        CategorySelector.show({ onSelect: category => {

            // Set state
            this.setState({ category })

            // Store category for loading in the next session
            localStorage.setItem('ui.category', category)

        } })

    }

    /** @private Called when the user clicks the search icon */
    showSearch() {

        // Show search popup
        SearchPopup.show({ })

    }

    /** Called when the user changes the display settings */
    onDisplaySettingsChanged(settings) {

        // Update display setting state
        this.setState({
            displaySettings: settings
        })

    }

    /** @private Called when the user clicks on a vatom */
    onVatomActivated(vatom) {

        // Check if vatom is a search folder
        if (vatom.faces.find(f => f.properties.display_url == "native://search-folder")) {
            window.location.hash = '/search-folder/' + vatom.id
            return
        }

        // Show activated screen
        window.location.hash = '/vatom/' + vatom.id

    }

}
