//
// This file is responsible for loading the local config, and optionally merging
// the remote config in.

import merge from 'lodash.merge'
import EventEmitter from '../Code/EventEmitter'

// Config class
module.exports = new class Config extends EventEmitter {

    /** @private Constructor */
    constructor() {
        super()

        // Merge fields from local config
        merge(this, require('../config'))

        // Bind functions
        this.canShowVatom = this.canShowVatom.bind(this)

    }

    /** Call to load the remote config. @returns Promise. */
    async loadRemoteConfig() {

        // Get config URL, replace variables
        let configURL = module.exports.remoteConfig?.url || ''
        configURL = configURL.replace(/\$HOSTNAME/g, location.hostname)

        // Stop if there is no remote config
        if (!configURL)
            return

        // Start update timer if necessary
        let checkInterval = parseInt(module.exports.remoteConfig?.checkInterval)
        if (checkInterval) {
            this._remoteConfigURL = configURL
            this._remoteConfigString = ''
            this._remoteConfigTimer = setInterval(this.checkRemoteConfigForUpdates.bind(this), checkInterval)
        }

        // Fetch config
        try {

            // Fetch it
            let str = await fetch(configURL).then(r => r.text())
            let json = JSON.parse(str)

            // Merge it in
            merge(this, json)

            // Store in local cache
            localStorage['config.cache.' + this.prefix] = str

            // Store latest config string
            this._remoteConfigString = str

        } catch (err) {

            // Attempt to load config from local cache instead
            console.warn('Failed to load remote config, using cached copy... ' + err.message)
            this.loadRemoteConfigFromCache()

        }

    }

    /** Load remote config from cached copy */
    loadRemoteConfigFromCache() {

        // Catch errors
        try {

            // Parse config
            let str = localStorage['config.cache.' + this.prefix]
            let json = JSON.parse(str)
            merge(this, json)

            // Store latest config string
            this._remoteConfigString = str

        } catch (err) {

            // Throw error
            throw new Error("Unable to load config from local cache. " + err.message)

        }

    }

    /** Checks for config updates on a schedules interval */
    async checkRemoteConfigForUpdates() {

        // Catch errors
        try {

            // Fetch it
            let str = await fetch(this._remoteConfigURL).then(r => r.text())

            // Check for change, stop if none
            if (str == this._remoteConfigString || !str)
                return

            // Store new config locally
            this._remoteConfigString = str
            localStorage['config.cache.' + this.prefix] = str

            // New config available!
            if (module.exports.remoteConfig?.updateMode == 'reload') {

                // Force reload the page
                console.log(`[Config] New config found, reloading the page...`)
                location.reload()

            } else if (module.exports.remoteConfig?.updateMode == 'force') {

                // Force install the config into the existing app
                let json = JSON.parse(str)
                console.log(`[Config] New config found, installing into existing instance...`)
                merge(this, json)
                this.emit('updated')

            } else {

                // Just notify the user of an update
                console.log(`[Config] New config found, showing notification to user...`)
                this.hasPendingUpdate = true
                this.emit('updated')

            }

        } catch (err) {

            // Throw error
            console.warn('[Config] Unable to check for update. ' + err.message)

        }

    }

    /** Returns true if the specified vatom is allowed to be shown based on the config's filter */
    canShowVatom(vatom) {

        // Check if a filter exists
        if (!this.filter?.field)
            return true

        // Compare values
        let vatomValue = vatomField(vatom.payload, this.filter?.field)
        return vatomValue == this.filter?.value

    }

}

/** Get a vatom field based on a string path */
function vatomField(vatom, path) {

    // Split path into components
    let components = path.split(/\.(?=(?:[^"]*"[^"]*")*[^"]*$)/g)
    let current = vatom
    for (let nextComponent of components) {

        if (typeof current[nextComponent] !== 'undefined')
            current = current[nextComponent]

    }

    // Got value
    return current

}
