
import Config from './Config'
import * as firebase from 'firebase/app'
import 'firebase/messaging'
import SingleThreadedPromise from '../Decorators/SingleThreadedPromise'
import BLOCKv from './Blockv'

// Export class
export default new class Firebase {

    setup() {

        // Check if firebase is supported
        if (!Config.firebase)
            return

        // Setup Firebase
        firebase.initializeApp(Config.firebase)

        // Setup firebase messaging
        if (Config.firebaseVapidKey)
            this.setupMessaging().catch(console.warn)

    }

    /** Get Firebase messaging module */
    @SingleThreadedPromise
    async setupMessaging() {

        // Stop if already set up
        if (this.messaging)
            return this.messaging

        // Stop if no messaging config
        if (!Config.firebaseVapidKey)
            throw new Error('Messaging is not configured.')

        // Find existing servcie worker registration
        let registration = navigator.serviceWorker && await navigator.serviceWorker.getRegistration()
        if (!registration)
            throw new Error('Could not setup messaging, no service worker registration found.')

        // Set it up
        this.messaging = firebase.messaging()
        this.messaging.useServiceWorker(registration)
        this.messaging.usePublicVapidKey(Config.firebaseVapidKey)

        // Add new token listener
        this.messaging.onTokenRefresh(async newToken => {

            // Check if push is enabled
            if (localStorage['push'] !== 'on')
                return console.log('Received a new Firebase push token, but push was disabled by the user. We are not sending it to the server.')

            // Check if logged in
            if (!BLOCKv.UserManager.isLoggedIn)
                return console.log("Received a new Firebase push token, but we're not logged in yet. Waiting until logged in...")

            // Send to server
            await BLOCKv.client.request('POST', '/v1/user/pushnotification', {
                platform_id: 'web',
                fcm_token: newToken,
                on: true
            }, true)

        })

    }

}()
