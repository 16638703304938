import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Config from './Common/Config'

// Load remote config first
Config.loadRemoteConfig().then(e => {

    // Render app
    const App = require('./App').default
    ReactDOM.render(<App />, document.getElementById('root'))

    // Register service worker
    const NetworkManager = require('./NetworkManager').default
    NetworkManager.register()

}).catch(err => {

    // This should hopefully never happen... Failed to load the remote config. Show the user.
    console.error(err)
    alert("There was a problem loading the remote config.")

})