
import React from 'react'
import BLOCKv from '../../Common/Blockv'

/** This component renders cache information. */
export default class CacheState extends React.Component {

    constructor() {

        super()

        // Setup state fields
        this.state = {}
        this.state.capacity = 0
        this.state.appBytes = 0
        this.state.appCount = 0
        this.state.resourceBytes = 0
        this.state.resourceCount = 0
        this.state.otherBytes = 0
        this.state.datapoolBytes = BLOCKv.dataPool.stats().estimatedSize

    }

    render() {

        // Get values
        let appPercent = Math.ceil(this.state.appBytes / this.state.capacity * 100) || 0
        let resourcePercent = Math.ceil(this.state.resourceBytes / this.state.capacity * 100) || 0
        let datapoolPercent = Math.ceil(this.state.datapoolBytes / this.state.capacity * 100) || 0
        let otherPercent = Math.ceil(this.state.otherBytes / this.state.capacity * 100) || 0

        return <div style={{ position: 'relative', height: 133 }}>

            {/* Progress bar */}
            <div style={{ position: 'absolute', top: 16, left: 16, width: 'calc(100% - 32px)', height: 8, overflow: 'hidden', borderRadius: 4, backgroundColor: '#DDD' }}>

                {/* Other files */}
                <div style={{ position: 'absolute', top: 0, left: 0, width: (appPercent + resourcePercent + datapoolPercent + otherPercent) + '%', height: '100%', backgroundColor: '#AAAAAA' }} />

                {/* Data pool */}
                <div style={{ position: 'absolute', top: 0, left: 0, width: (appPercent + resourcePercent + datapoolPercent) + '%', height: '100%', backgroundColor: '#d97e00' }} />

                {/* Resource files */}
                <div style={{ position: 'absolute', top: 0, left: 0, width: (appPercent + resourcePercent) + '%', height: '100%', backgroundColor: '#2c9e3e' }} />

                {/* App files */}
                <div style={{ position: 'absolute', top: 0, left: 0, width: appPercent + '%', height: '100%', backgroundColor: '#2c579e' }} />

                {/* Error bar */}
                <div style={{ position: 'absolute', top: 0, left: 0, width: this.state.capacity <= 0 ? '100%' : '0%', height: '100%', backgroundColor: '#db5029' }} />

            </div>

            {/* Size information */}
            <div style={{ position: 'absolute', top: 40, left: 16, width: 8, height: 8, borderRadius: 4, backgroundColor: '#2c579e' }} />
            <div style={{ position: 'absolute', top: 38, left: 32, fontSize: 12, color: '#888' }}>
                <b>App:</b> {this.state.appCount} files
            </div>

            <div style={{ position: 'absolute', top: 58, left: 16, width: 8, height: 8, borderRadius: 4, backgroundColor: '#2c9e3e' }} />
            <div style={{ position: 'absolute', top: 56, left: 32, fontSize: 12, color: '#888' }}>
                <b>Vatom resources:</b> {this.state.resourceCount} files
            </div>

            <div style={{ position: 'absolute', top: 76, left: 16, width: 8, height: 8, borderRadius: 4, backgroundColor: '#d97e00' }} />
            <div style={{ position: 'absolute', top: 74, left: 32, fontSize: 12, color: '#888' }}>
                <b>Database:</b> {Math.floor(this.state.datapoolBytes / 1024)} KB
            </div>

            <div style={{ position: 'absolute', top: 94, left: 16, width: 8, height: 8, borderRadius: 4, backgroundColor: '#AAAAAA' }} />
            <div style={{ position: 'absolute', top: 92, left: 32, fontSize: 12, color: '#888' }}>
                <b>Other:</b> {Math.floor(this.state.otherBytes / 1024 / 1024)} MB
            </div>

        </div>

    }

    componentDidMount() {

        // Fetch cache state
        this.fetchCacheState()

        // Keep fetching every so often
        this.timer = setInterval(this.fetchCacheState.bind(this), 30 * 1000)

    }

    componentWillUnmount() {

        // Stop fetching
        clearInterval(this.timer)

    }

    async fetchCacheState() {

        // Get size info
        let estimate = { quota: 5 * 1024 * 1024, usage: 0 }
        if (navigator.storage)
            estimate = await navigator.storage.estimate()

        // Go through each cached app file and calculate the size
        let appBytes = 0
        let appCount = 0
        for (let cacheKey of await caches.keys()) {

            // Only look at workbox cache
            if (!cacheKey.includes('workbox'))
                continue

            // Get values
            appBytes += await fetchCacheSize(cacheKey)
            appCount += await fetchCacheCount(cacheKey)

        }

        // Go through resource cache
        let resourceBytes = await fetchCacheSize('vatom-resources')
        let resourceCount = await fetchCacheCount('vatom-resources')

        // Ensure capacity isn't lower than our calculated amount. This can happen because the browser doesn't
        // give us accurate sizes for "opaque" cached responses, so we estimate the size.
        let capacity = estimate.quota
        if (capacity < appBytes + resourceBytes)
            capacity = appBytes + resourceBytes

        // Calculate "other"
        let otherBytes = Math.max(0, estimate.usage - appBytes - resourceBytes)

        // Update UI
        this.setState({ appBytes, appCount, resourceBytes, resourceCount, otherBytes, capacity })

    }

}

// Fetch the byte size of a cache with the specified name
async function fetchCacheSize(cacheKey) {

    let bytes = 0
    let cache = await caches.open(cacheKey)
    for (let key of await cache.keys()) {

        // Get response size
        let response = await cache.match(key)
        response = await response.clone()
        let blob = await response.blob()
        bytes += blob.size

        // Estimate size if this is an opaque response. Chrome adds 7MB of padding to the quota for every cached opaque response.
        if (blob.size <= 0)
            bytes += 1024 * 1024 * 7.5

    }

    return bytes

}

// Fetch the number of items in a cache
async function fetchCacheCount(cacheKey) {

    let cache = await caches.open(cacheKey)
    let keys = await cache.keys()
    return keys.length

}
