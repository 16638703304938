// This component renders vatoms in a grid
//
// Note about scroll state restoration: A user of this comopnent can specify the `scrollKey="..."` property.
// If specified, the scroll state will be restored when this component is recreated.

import React from 'react'
import BLOCKv from '../../../Common/Blockv'
import LiveVatomViewContainer from '../../../Components/LiveVatomViewContainer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import Drawer from '@material-ui/core/Drawer'
import { MobileOnly, DesktopOnly } from '../../../Components/MediaQueries'
import SendPopup from '../../../Popups/SendPopup'
import DropPopup from '../../../Popups/DropPopup'
import ThreeDotLoader from '../../../Components/ThreeDotLoader'
import { FaceSelection } from '@blockv/sdk/face'
import { ListItemText, ListItemIcon } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import EditIcon from '@material-ui/icons/Edit'
import ShareIcon from '@material-ui/icons/Share'
import CartIcon from '@material-ui/icons/ShoppingCart'
import DropIcon from '@material-ui/icons/PinDrop'
import DeleteIcon from '@material-ui/icons/Delete'
import SplitIcon from '@material-ui/icons/CallSplit'
import JSAlert from 'js-alert'
import Errors from '../../../Common/Errors'
import Analytics from '../../../Code/Analytics'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Icon from '@material-ui/core/Icon'
import Tags from '../../../Common/Tags'
import Swal from 'sweetalert2'
import config from '../../../Common/Config'
import MediaQuery from 'react-responsive'

// Vatom faces to exclude. We don't want to render any heavy duty faces in the icon list,
// it'll slow the device down too much.
const HeavyFaces = [
    'native://generic-3d',
    'native://generic-3d-folder'
]

// Stores the last scroll state if using scroll state restoration.
var scrollStates = {}

export default class VatomGrid extends React.Component {

    constructor(props) {

        super(props)

        // Vars
        this.inventory = props.inventory || BLOCKv.dataPool.region('inventory')
        this.scrollStateRestored = false
        this.state = {}
        this.state.vatoms = []
        this.state.contextMenuVatom = null
        this.state.showContextMenu = false
        this.state.hoveringVatom = null
        this.state.maxVatoms = 50
        this.state.maxVatomsSidebar = 20
        this.state.totalCoins = 0
        this.state.templateCount = {}

        // The speed (in pixels per second) that the inventory is scrolling. Used during the drag operation.
        this.scrollVelocity = 0

        // Bind listeners
        this.onInventoryChanged = this.onInventoryChanged.bind(this)
        this.scrollAdjustLoop = this.scrollAdjustLoop.bind(this)

    }

    /** @private Called by React when the component is mounted */
    componentDidMount() {
        // Stop if no inventory. This can happen if the user was not logged in.
        if (!this.inventory)
            return

        // Update inventory
        this.onInventoryChanged()
        // this.refresh()

        // Add listener
        this.inventory.addEventListener('updated', this.onInventoryChanged)

    }

    /** @private Called when the component is removed from the screen */
    componentWillUnmount() {

        // Stop if no inventory. This can happen if the user was not logged in.
        if (!this.inventory)
            return

        // Remove listener
        this.inventory.removeEventListener('updated', this.onInventoryChanged)

    }

    /** @private Called by React when the component has updated */
    componentDidUpdate(prevProps, prevState, snapshot) {

        // Do scroll state restoration. Check if already restored.
        if (this.scrollStateRestored)
            return

        // Check if enabled
        if (!this.props.scrollKey)
            return

        // Check if component has rendered yet
        if (!this.refs.container)
            return

        // Check if there's enough content to scroll
        let containerHeight = this.refs.container.clientHeight
        let contentHeight = this.refs.container.scrollHeight
        if (contentHeight < containerHeight)
            return

        // Check if got a state to restore to
        let scrollState = scrollStates[this.props.scrollKey]
        if (!scrollState)
            return

        // Restore scroll state
        this.refs.container.scrollTop = scrollState.y
        this.scrollStateRestored = true

    }

    /** @private Called when the user scrolls the inventory */
    onScroll() {

        // Check if scroll state restoration is enabled
        if (!this.props.scrollKey)
            return

        // Store current scroll position
        scrollStates[this.props.scrollKey] = {
            x: this.refs.container.scrollLeft,
            y: this.refs.container.scrollTop
        }

        // If scroll state restoration is delayed somehow, prevent a jump after the user has manually scrolled
        this.scrollStateRestored = true

    }

    /** Refreshes the inventory */
    refresh() {

        // If no inventory, stop
        if (!this.inventory)
            return this.setState({ loading: false, error: new Error('No inventory found.') })

        // Start refreshing
        this.setState({ loading: true, error: null })
        this.inventory.refresh().then(e => {

            // Done, show vatoms
            this.onInventoryChanged()
            this.setState({
                loading: false
            })

        }).catch(err => {

            // Show error
            console.warn('Unable to fetch inventory', err)
            this.setState({
                loading: false,
                error: err
            })

        })

    }

    /** @private Called when the inventory notifies us that it has changed */
    onInventoryChanged() {
        // Get all vatoms
        var coinCount = {}
        var vatoms = this.inventory && this.inventory.get(false)

        // Check folder type
        let searchFolderFace = this.props.folderVatom && this.props.folderVatom.faces.find(f => f.properties.display_url == "native://search-folder")
        if (searchFolderFace) {
            
            // Inside a search folder, filter to only show vatoms that match the search queries
            let queries = searchFolderFace.properties?.config?.queries || []
            
            vatoms = vatoms.filter(v => queries.includes(v.id) && v.properties.parent_id == '.' || queries.includes(v.properties.template) && v.properties.parent_id == '.'  || queries.includes(v.properties.template_variation) && v.properties.parent_id == '.')

        } else if (this.props.folderVatom) {

            // Inside a normal folder, filter to only show vatoms inside this folder
            vatoms = vatoms.filter(v => v.properties.parent_id === this.props.folderVatom.id)

        } else {

            // Outside a search folder, filter to only show vatoms at the root level
            vatoms = vatoms.filter(v => v.properties.parent_id === '.')

            // Filter out vatoms from search folders, first find all search folders that have hide_outside = true, then filter by queries
            let searchFolderVatoms = vatoms.filter(v => v.faces.find(f => f.properties.display_url == "native://search-folder" && f.properties.config?.hide_outside))
            let searchFolderQueries = searchFolderVatoms.map(v => v.faces.find(f => f.properties.display_url == "native://search-folder" && f.properties.config?.hide_outside)).flatMap(f => f.properties.config.queries || [])
            vatoms = vatoms.filter(v => !(searchFolderQueries.includes(v.id) || searchFolderQueries.includes(v.properties.template) || searchFolderQueries.includes(v.properties.template_variation)))

        }

        // Filter out vatoms based on the config
        vatoms = vatoms.filter(config.canShowVatom)

        coinCount = this.inventory && this.inventory.get(false)
          let coinArray = config.features.tabs[1].includes
          if (coinArray?.length > 0)
            coinCount = coinCount.filter(v => coinArray.includes(v.properties.template) || coinArray.includes(v.properties.template_variation) || coinArray.includes(v.id))
            this.setState({ totalCoins: coinCount })
            this.props.getCoinValue(coinCount.length)

        // Filter out special vatoms
        if(this.props.page === 'inventory'){
          vatoms = vatoms.filter(v => v.properties.template !== 'vatomic::v1::vAtom::Avatar')
          vatoms = vatoms.filter(v => v.properties.template !== 'vatomic::v1::vAtom::CoinWallet')
          for(let i=0; i < config.features.tabs[0].excludes.length; i++){
            vatoms = vatoms.filter(v => v.properties.template_variation !== config.features.tabs[0].excludes[i])
          }
          for(let i=0; i < config.features.tabs[1].includes.length; i++){
            vatoms = vatoms.filter(v => v.properties.template_variation !== config.features.tabs[1].includes[i])
          }
        }else if(this.props.page === 'coins'){
          vatoms = this.inventory && this.inventory.get(false)
          let includesArray = config.features.tabs[1].includes
          if (includesArray?.length > 0){
            vatoms = vatoms.filter(v => includesArray.includes(v.properties.template) || includesArray.includes(v.properties.template_variation) || includesArray.includes(v.id)) || []
          } else {
            vatoms = []
          }

        }

        // grouping
        let templateCount = {}
        if(localStorage.getItem('tab') === 'coins'){
          vatoms = vatoms.filter(v => {
            let isFirst = !templateCount[v.properties.template_variation]
            templateCount[v.properties.template_variation] = (templateCount[v.properties.template_variation] || 0) + 1
            this.setState({ templateCount: templateCount })
            return isFirst
          })
        }

        // Filter out dropped vatoms
        vatoms = vatoms.filter(v => !v.properties['dropped'])

        if (config.features.showClientVatomsOnly) {
            let whitelist = config.features.publisherFqdn
            vatoms = vatoms.filter(v => whitelist.includes(v.properties.publisher_fqdn))
        }

        // Update
        this.setState({ vatoms })

    }

    canPerformAction(vatom, action) {

        // vlabs
        var regex = new RegExp(':' + action + '$')
        return vatom.actions.find(a => regex.exec(a.name) && a.properties)
        // return vatom.actions.find(a => a.name.indexOf(action) !== -1)

    }

    render() {

        // Filter vatoms based on category
        let vatoms = this.state.vatoms
        if (this.props.category === 'special:favorite')
            vatoms = vatoms.filter(v => !!Tags.shared.get(v.id, 'favorite'))
        else if (this.props.category)
            vatoms = vatoms.filter(v => (v.properties.category || '').toLowerCase() === this.props.category.toLowerCase())

        // Sort vatoms
        if (this.props.sort === 'oldest') {

            // Sort by oldest
            vatoms = vatoms.sort((v1, v2) => (new Date(v2.whenModified) < new Date(v1.whenModified) ? -1 : new Date(v2.whenModified) > new Date(v1.whenModified)) * -1)

        } else if (this.props.sort === 'atoz') {

            // Sort by A to Z
            vatoms = vatoms.sort((v1, v2) => (v1.properties.title || '').localeCompare(v2.properties.title || ''))

        } else if (this.props.sort === 'ztoa') {

            // Sort by Z to A
            vatoms = vatoms.sort((v1, v2) => (v2.properties.title || '').localeCompare(v1.properties.title || ''))

        } else {

            // Sort by newest (default)
            vatoms = vatoms.sort((v1, v2) => (new Date(v1.whenModified) < new Date(v2.whenModified) ? -1 : new Date(v1.whenModified) > new Date(v2.whenModified)) * -1)

        }

        // Limit to max count
        let hasMoreVatoms = false
        if (vatoms.length > this.state.maxVatoms) {

            vatoms = vatoms.slice(0, this.state.maxVatoms)
            hasMoreVatoms = true

        }

        // Check if the user is hovering a vatom over the edge, meaning if they let go the vatom will be removed from the folder
        let isHoveringOverEdge =
            this.props.folderVatom && // Only if we're displaying a folder vatom's content
            !this.state.hoveringVatom && // Only if the user is not hovering over another vatom
            this.state.isDraggingNearEdge // Only if the user is hovering near the edge

        // Get the vatoms that the context menu vatom (if any) can combine with, and limit it by the maximum vatoms count
        let contextMenuCombineables = this.getContextMenuVatomCombineables()
        let contextMenuCombineablesHasMore = false
        if (contextMenuCombineables.length > this.state.maxVatomsSidebar) {

            contextMenuCombineables = contextMenuCombineables.slice(0, this.state.maxVatomsSidebar)
            contextMenuCombineablesHasMore = true

        }

        // Dirty hack: In order to left-align the last row, add a bunch of filler items with no height
        let fillerItems = []
        for (let i = 0; i < 25; i += 1)
            fillerItems.push(<div key={i} style={{ width: this.props.iconSize || 128, height: 0, margin: '0px 10px' }} />)

        let fillerMenuItems = []
        for (var i = 0; i < 25; i += 1)
            fillerMenuItems.push(<div key={i} style={{ width: 90, height: 0, margin: '0px 10px' }} />)

        return <div ref='container' onScroll={this.onScroll.bind(this)} style={Object.assign({
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            overflowX: 'hidden',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch'
        }, this.props.style)}>

            {/* Header component, if specified */}
            <React.Fragment>
                {this.props.header ? this.props.header : null}
            </React.Fragment>

            {/* Vatoms */}
            {vatoms.map(vatom =>
                <div key={vatom.id} style={{ position: 'relative', display: 'flex' }}>
                  {this.props.page === 'coins' ? <div style={{ position: 'absolute', display: 'flex', alignContent: 'center', justifyContent: 'center', right: 10, top: 10, zIndex: 2, display: 'block', width: 32, height: 32, borderRadius: '50%', backgroundColor: config.theme.header.selectedTabs.accentColor, color: '#fff' }}><div style={{ marginTop: '50%', transform: 'translateY(-50%)' }}>{(config.features.tabs[1].counter[vatom.properties.template_variation]||1) * this.state.templateCount[vatom.properties.template_variation]}</div></div> : null}
                    <VatomIcon ref={vatom.id} style={{ zIndex: 1 }} isNearEdge={this.state.isDraggingNearEdge} vatom={vatom} hovering={this.state.hoveringVatom === vatom} onClick={e => this.vatomClicked(vatom)} iconSize={this.props.iconSize} onContextMenu={e => this.showContextMenu(vatom)} onHover={this.onHover.bind(this, vatom)} onHoverEnd={this.onHoverEnd.bind(this, vatom)} onDrop={this.onDrop.bind(this, vatom)} />
                    <div style={{ display: this.props.titles ? 'block' : 'none', color: '#888', fontSize: '12px', paddingTop: '4px', textAlign: 'center', overflowX: 'ellipses', wordWrap: 'normal', maxWidth: '148px', WebkitTouchCallout: 'none', WebkitUserSelect: 'none' }}>
                        {Tags.shared.get(vatom.id, 'title') || vatom.properties.title}
                    </div>
                </div>
            )}
            {fillerItems}

            {/* If we have more vatoms available, show the MORE button */}
            <div onClick={e => this.setState({ maxVatoms: this.state.maxVatoms + 50 })} style={{ display: hasMoreVatoms ? 'block' : 'none', width: '100%', textAlign: 'center', padding: '30px 0px', color: '#08F', cursor: 'pointer', fontSize: 15, fontWeight: 'bold' }}>Load More</div>

            {/* If no items and loading, show loader */}
            {(vatoms.length === 0 && this.state.loading) ? <div style={{ textAlign: 'center', position: 'absolute', top: '30%', left: 0, width: '100%', color: '#4a4a4a' }}><ThreeDotLoader /></div> : null}

            {/* If no items and an error, show it */}
            {vatoms.length === 0 && this.state.error
                ? <div style={{ textAlign: 'center', position: 'absolute', top: '30%', left: '20%', width: '60%', color: '#4a4a4a' }}>
                    <div style={{ display: 'inline-block', width: 64, height: 64, paddingBottom: 40, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: 'url(' + require('./error.svg') + ')' }} />
                    <div>{this.state.error.message || 'There was an unknown problem fetching your vAtoms.'}</div>
                </div>

                : null}

            {/* If no items and no error and not loading, show empty state */}
            {vatoms.length === 0 && !this.state.error && !this.state.loading
                ? <div style={{ textAlign: 'center', position: 'absolute', top: '5%', left: '10%', width: '80%', color: '#4a4a4a' }}>
                    {/* Make the image smaller if on mobile */}
                    <DesktopOnly>
                        <div style={{ display: 'inline-block', width: 375, height: 463, paddingBottom: 0, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: 'url(' + require('./empty.svg') + ')' }} />
                    </DesktopOnly>
                    <MobileOnly>
                        <div style={{ display: 'inline-block', width: 200, height: 230, marginTop: '50%', paddingBottom: 0, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: 'url(' + require('./empty.svg') + ')' }} />
                    </MobileOnly>
                    <p>
                        {(e => {

                            // Output category name
                            if (this.props.category === 'special:favorite')
                                return 'Right-click or hold on a vatom to add it to your favorites.'
                            else if (!this.state.category)
                                return 'No vatoms found.'
                            else
                                return 'No vatoms in this category.'

                        })()}
                    </p>
                </div>
                : null}

            {/* Drag out overlay */}
            <div id="dragOutOverlay" style={{ position: 'fixed', zIndex: '2', top: 10, left: 10, width: 80, height: 'calc(100% - 20px)', display: isHoveringOverEdge ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 8 }}>
                <div style={{ padding: 20, fontSize: 11, color: 'white', textAlign: 'center', lineHeight: '1.2' }}>Remove from folder</div>
            </div>

            {/* Context menu drawer, shows when a user right-clicks a vatom */}

            <Drawer open={this.state.showContextMenu} onClose={e => this.setState({ showContextMenu: false })}>
                <div style={{ width: 250 }}>
                    {/* Show vatom */}
                    <LiveVatomViewContainer vatom={this.state.contextMenuVatom} fsp={FaceSelection.Icon} style={{
                        display: 'block',
                        margin: 20,
                        height: 220
                    }} />

                    {/* Vatom title */}
                    <div style={{ margin: '0px 20px 20px 20px', fontSize: 17, color: '#333', textAlign: 'center' }}>{this.state.contextMenuVatom && this.state.contextMenuVatom.properties.title}</div>
                    {/* Menu actions */}
                    <List>
                        <Divider />
                        {this.state.contextMenuVatom && this.canPerformAction(this.state.contextMenuVatom, 'Transfer')
                            ? <ListItem button caption='Send' onClick={e => this.sendMenuVatom()}>
                                <ListItemIcon>
                                    <SendIcon />
                                </ListItemIcon>
                                <ListItemText primary='Send' />
                            </ListItem>
                            : null }
                        {this.state.contextMenuVatom && this.canPerformAction(this.state.contextMenuVatom, 'Clone')
                            ? <ListItem button caption='Share' onClick={e => this.shareMenuVatom()}>
                                <ListItemIcon>
                                    <ShareIcon />
                                </ListItemIcon>
                                <ListItemText primary='Share' />
                            </ListItem>
                            : null}
                        {this.state.contextMenuVatom && this.canPerformAction(this.state.contextMenuVatom, 'Redeem') && !(this.state.contextMenuVatom.private.viewer && this.state.contextMenuVatom.private.viewer.hide_menu_redeem)
                            ? <ListItem button caption='Redeem' onClick={e => this.redeemMenuVatom()}>
                                <ListItemIcon>
                                    <CartIcon />
                                </ListItemIcon>
                                <ListItemText primary='Redeem' />
                            </ListItem>
                            : null}
                        {this.state.contextMenuVatom && this.canPerformAction(this.state.contextMenuVatom, 'Drop')
                            ? <ListItem button caption='Drop' onClick={e => this.dropMenuVatom()}>
                                <ListItemIcon>
                                    <DropIcon />
                                </ListItemIcon>
                                <ListItemText primary='Drop' />
                            </ListItem>
                            : null}
                        {this.state.contextMenuVatom && this.state.contextMenuVatom.isFolder
                            ? <ListItem button caption ='Split' onClick={e => this.splitMenuVatom()}>
                                <ListItemIcon>
                                    <SplitIcon />
                                </ListItemIcon>
                                <ListItemText primary='Split' />
                            </ListItem>
                            : null}

                        {/* Move vatom up a folder */}
                        {this.props.folderVatom
                            ? <ListItem button onClick={e => this.moveMenuVatomUp()}>
                                <ListItemIcon>
                                    <Icon>
                                        <img src={require('./upload-folder.svg')} style={{ height: '100%' }} />
                                    </Icon>
                                </ListItemIcon>
                                <ListItemText primary='Move Up' />
                            </ListItem>
                            : null}
                        {this.state.contextMenuVatom && this.state.contextMenuVatom.faces.find(f => f.properties.display_url === 'native://folder')
                            ? <ListItem button caption='Rename' onClick={e => this.renameFolder()}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary='Rename Folder' />
                            </ListItem>
                            : null
                        }
                        {/* Favorite menu option */}
                        {Tags.shared.get(this.state.contextMenuVatom?.id, 'favorite')
                            ? <ListItem button onClick={e => this.unfavoriteMenuVatom()}>
                                <ListItemIcon>
                                    <FavoriteBorderIcon />
                                </ListItemIcon>
                                <ListItemText primary='Remove favorite' />
                            </ListItem>
                            : <ListItem button onClick={e => this.favoriteMenuVatom()}>
                                <ListItemIcon>
                                    <FavoriteIcon />
                                </ListItemIcon>
                                <ListItemText primary='Favorite' />
                            </ListItem>
                        }
                        {this.state.contextMenuVatom && !(this.state.contextMenuVatom.private.viewer && this.state.contextMenuVatom.private.viewer.hide_menu_trash)
                            ? <ListItem button caption='Delete' onClick={e => this.deleteMenuVatom()}>
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary='Delete' />
                            </ListItem>
                            : null
                        }
                        <Divider />
                    </List>

                    {/* List of combineable vatoms */}
                    <div style={{ margin: '10px 20px 10px 20px', fontSize: 14, color: '#333', textAlign: 'center' }}>Combinable vAtoms</div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', alignContent: 'flex-start', flexWrap: 'wrap' }}>
                        {contextMenuCombineables.map(vatom =>
                            <div key={vatom.id} onClick={e => this.combineMenuVatomWith(vatom)}>
                                <LiveVatomViewContainer vatom={vatom} fsp={FaceSelection.Icon} noHeavyFaces style={{
                                    display: 'inline-block',
                                    width: 90,
                                    height: 90,
                                    margin: 10,
                                    cursor: 'pointer'
                                }} />
                            </div>
                        )}
                        {fillerMenuItems}
                    </div>
                    {/* If no combinable vatoms found, show message */}
                    {contextMenuCombineables.length === 0
                        ? <div style={{ padding: 20, color: '#888', fontStyle: 'italic', fontSize: 13, textAlign: 'center' }}>No Combinable Items</div>
                        : null}

                    {/* If we have more vatoms available, show the MORE button */}
                    <div onClick={e => this.setState({ maxVatomsSidebar: this.state.maxVatomsSidebar + 20 })} style={{ display: contextMenuCombineablesHasMore ? 'block' : 'none', width: '100%', textAlign: 'center', padding: '30px 0px', color: '#08F', cursor: 'pointer', fontSize: 15, fontWeight: 'bold' }}>Load More</div>

                    {/* Padding on bottom, for iPhones which cut off a bit */}
                    <div style={{ height: 100 }} />
                </div>
            </Drawer>

        </div>

    }

    /** @private Gets the list of vatoms that the context menu vatom can combine with */
    getContextMenuVatomCombineables() {

        // Check we have a context menu vatom
        if (!this.state.contextMenuVatom)
            return []

        var vatomView = this.refs[this.state.contextMenuVatom.id] && this.refs[this.state.contextMenuVatom.id].refs['VatomView'].vv
        if (!vatomView)
            return []
        // List them
        return this.state.vatoms.filter(v => this.state.contextMenuVatom.canCombineWith(v))

    }

    /** @private Called when the user clicks on a vatom icon */
    vatomClicked(vatom) {

        // Notify parent
        this.props.onVatomActivated(vatom)

    }

    /** @private Called when the user right-clics a vatom or long-touches it */
    showContextMenu(vatom) {

        this.setState({ showContextMenu: true, contextMenuVatom: vatom })

    }

    /** @private Called when the user clicks the Send button in the menu */
    sendMenuVatom() {

        if (!this.state.showContextMenu)
            return

        // Close menu
        this.setState({ showContextMenu: false })
        // Show send screen
        SendPopup.show({ vatom: this.state.contextMenuVatom })

    }

    /**
     * @private Called when the user wants to rename a generic folder
     * !alert This is a temporary solution until native folders are added
     * */
    async renameFolder() {

        // Hide menu
        this.setState({ showContextMenu: false })

        // Ask user for new name
        let rename = await Swal.fire({
            type: 'question',
            title: 'Rename Folder',
            html: `Enter a new name for the folder`,
            input: 'text',
            inputPlaceholder: Tags.shared.get(this.state.contextMenuVatom.id, 'title') || this.state.contextMenuVatom.properties.title,
            showCancelButton: true
        })

        if (!rename)
            return

        if (rename && rename.value) {

            Tags.shared.set(this.state.contextMenuVatom.id, 'title', rename.value)
            Swal.fire('Renamed Sucessfully', `Folder renamed successfully to ${rename.value}`, 'success')

        }

        this.forceUpdate()

    }

    /** @private Called when the user clicks the Share button in the menu */
    shareMenuVatom() {

        if (!this.state.showContextMenu)
            return

        // Close menu
        this.setState({ showContextMenu: false })

        // Show send screen
        SendPopup.show({ shouldShare: true,
            vatom: this.state.contextMenuVatom,
            onSendComplete: e => {
                // Send complete
            }
        })

    }

    /** @private Called when the user clicks the Redeem button in the menu */
    redeemMenuVatom() {

        if (!this.state.showContextMenu)
            return

        // Close menu
        this.setState({ showContextMenu: false })

        // Show send screen
        SendPopup.show({ shouldRedeem: true,
            vatom: this.state.contextMenuVatom,
            onSendComplete: e => {
                // Send complete
            }
        })

    }

    /** @private Called when the user clicks the Drop button in the menu */
    dropMenuVatom() {

        if (!this.state.showContextMenu)
            return

        // Close menu
        this.setState({ showContextMenu: false })

        // Show drop screen
        DropPopup.show({ vatom: this.state.contextMenuVatom,
            onComplete: e => {
                // DON'T show an alert, since with pre-emptive if it fails it would result in "Vatom dropped successfully" and then "Vatom failed to drop." straight after.
            }
        })

    }

    /** @private Called when the user clicks the Split button in the menu */
    splitMenuVatom() {

        // Make sure it's a folder
        if (!this.state.contextMenuVatom || !this.state.contextMenuVatom.isFolder)
            return

        // Close menu
        this.setState({ showContextMenu: false })

        // Do split
        BLOCKv.Vatoms.split(this.state.contextMenuVatom).then(e => {

            // Log analytics
            Analytics.event('Action:Split')

        }).catch(Errors.show)

    }

    /** @private Called when the user clicks the Split button in the menu */
    combineMenuVatomWith(otherVatom) {

        // Check we have a context menu vatom
        if (!this.state.contextMenuVatom)
            return console.warn('Logic error: No context menu vatom found!')

        // Close menu
        this.setState({ showContextMenu: false })

        // Get context menu vatom's vatomView
        var vatomView = this.refs[this.state.contextMenuVatom.id].refs['VatomView'].vv

        if (!vatomView)
            return console.warn('Logic error: Unable to locate VatomView from the grid which is associated with the context menu vatom!')

        // Close menu
        this.setState({ showContextMenu: false })

        // Do combine
        vatomView.combineWith(otherVatom).then(e => {

            // Log analytics
            Analytics.event('Action:Combine')

        }).catch(Errors.show)

    }

    /** @private Called when the user clicks the Delete button in the menu */
    deleteMenuVatom() {

        // Close menu
        this.setState({ showContextMenu: false })

        // Confirm with user
        JSAlert.confirm('Are you sure you want to delete this vAtom?', 'Delete vAtom').then(c => {

            // Stop if no
            if (!c)
                return

            // Do it
            return BLOCKv.Vatoms.trashVatom(this.state.contextMenuVatom.id)

        }).then(e => {

            // Log analytics
            Analytics.event('Action:Trash')

        }).catch(Errors.show)

    }

    /** Adds this vatom to the user's favorites */
    favoriteMenuVatom() {

        // Close menu
        this.setState({ showContextMenu: false })

        // Add tag
        Tags.shared.set(this.state.contextMenuVatom.id, 'favorite', 'y')

    }

    /** Removes this vatom from the user's favorites */
    unfavoriteMenuVatom() {

        // Close menu
        this.setState({ showContextMenu: false })

        // Add tag
        Tags.shared.remove(this.state.contextMenuVatom.id, 'favorite')

    }

    /** Move context menu vatom up to the parent folder */
    moveMenuVatomUp() {

        // Close menu
        this.setState({ showContextMenu: false })

        // Extract this vatom from the folder
        BLOCKv.Vatoms.setParentID(this.state.contextMenuVatom.id, this.props.folderVatom.properties.parent_id || '.')

    }

    /** @private Called when the user hovers over an item */
    onHover(draggingVatom, x, y) {

        // Get position of our container
        let containerRect = this.refs.container.getBoundingClientRect()

        // If close to top or bottom, scroll
        this.scrollVelocity = 0
        if (y < containerRect.top + 50)
            this.scrollVelocity = -Math.abs(y - (containerRect.top + 50)) * 15
        else if (y > containerRect.bottom - 50)
            this.scrollVelocity = Math.abs(y - (containerRect.bottom - 50)) * 15

        // Run scroll animation if needed
        if (!this.draggingVatomContainer)
            this.draggingScrollTop = this.refs.container.scrollTop

        this.draggingVatomContainer = this.refs[draggingVatom.id]
        if (!this.scrollLoopIsRunning) {

            this.lastFrameTime = Date.now()
            this.scrollAdjustLoop()

        }

        // Check if dragging near the edge
        let nearEdge = x < containerRect.left + 80
        if (nearEdge && !this.state.isDraggingNearEdge)
            this.setState({ isDraggingNearEdge: true })
        else if (!nearEdge && this.state.isDraggingNearEdge)
            this.setState({ isDraggingNearEdge: false })

        // Find hovering vatom
        let vatom = null
        for (let v of this.state.vatoms) {

            // Skip if ours
            if (v.id === draggingVatom.id)
                continue

            // Find vatom view
            let container1 = this.refs[v.id]
            let container2 = container1 && container1.refs['VatomView']
            let vatomView = container2 && container2.vv
            if (!vatomView)
                continue

            // Check if can combine
            if (!vatomView.canCombineWith(draggingVatom))
                continue

            // Get display rect
            let rect = vatomView.element.getBoundingClientRect()

            // Check if inside rect
            if (x > rect.left && y > rect.top && x < rect.left + rect.width && y < rect.top + rect.height) {

                vatom = v
                break

            }

            // Optimization: If rect.y is below y, the rest of them are below the user's pointer. Stop here.
            if (rect.top > y)
                break

        }

        // Check if found
        if (!vatom) {

            if (this.state.hoveringVatom != null)
                this.setState({ hoveringVatom: null })

            return

        }

        // Set as hovering vatom
        if (this.state.hoveringVatom !== vatom)
            this.setState({ hoveringVatom: vatom })

    }

    /** @private Called when hovering ends */
    onHoverEnd(draggingVatom) {

        // Remove hovering vatom
        this.draggingVatomContainer = null
        this.setState({ hoveringVatom: null })

        // Stop scrolling
        this.scrollVelocity = 0

    }

    /** @private Called when the user drops a vatom */
    onDrop(draggingVatom, clientX, clientY) {

        // Check if should remove from folder, ie. we are displaying a folder vatom's content, the user is
        // NOT hovering over another combinable vatom, and the user is hovering near the edge
        if (this.props.folderVatom && !this.state.hoveringVatom && this.state.isDraggingNearEdge) {

            // Remove from folder
            BLOCKv.Vatoms.setParentID(draggingVatom.id, this.props.folderVatom.properties.parent_id || '.').catch(Errors.show)

        }

        // Clear hovering near edge state
        this.setState({ isDraggingNearEdge: false })

        // Check if hovering over a vatom
        if (this.state.hoveringVatom) {

            // Find vatom view
            let vatomView = this.refs[this.state.hoveringVatom.id].refs['VatomView'].vv
            if (!vatomView)
                return

            // Do combine
            return vatomView.combineWith(draggingVatom).then(e => {
                
               // Log analytics
                Analytics.event('Action:Combine')

            }).catch(Errors.show)

        }

    }

    /** @private Called in a loop to scroll the container */
    scrollAdjustLoop(timestamp) {

        // Stop if no velocity
        if (this.scrollVelocity === 0) {

            this.scrollLoopIsRunning = false
            return

        }

        // We are running
        this.scrollLoopIsRunning = true

        // Request another frame (bound in constructor)
        /* eslint-disable-next-line */
    requestAnimationFrame(this.scrollAdjustLoop)

        // Calculate delta in seconds
        let deltaMs = Date.now() - (this.lastFrameTime || Date.now())
        let delta = Math.min(1, Math.max(0, deltaMs / 1000))
        this.lastFrameTime = Date.now()

        // Scroll
        this.refs.container.scrollTop += this.scrollVelocity * delta

        // If got a dragging container, update it's scroll position
        if (this.draggingVatomContainer)
            this.draggingVatomContainer.setScrollOffset(0, this.refs.container.scrollTop - this.draggingScrollTop)

    }

}

class VatomIcon extends React.Component {

    constructor() {

        super()

        // Setup state vars
        this.touchID = null
        this.state = {}
        this.state.dragging = false

        // Drag state vars
        this.dragStartX = 0
        this.dragStartY = 0
        this.dragOffsetX = 0
        this.dragOffsetY = 0
        this.scrollOffsetX = 0
        this.scrollOffsetY = 0

        // Bind mouse and touch event listeners. One glorious day, we'll be able to
        // implement the native drag-n-drop APIs. Today is not that day unfortunately.
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)
        this.onTouchStart = this.onTouchStart.bind(this)
        this.onTouchMove = this.onTouchMove.bind(this)
        this.onTouchEnd = this.onTouchEnd.bind(this)
        this.onTouchCancel = this.onTouchCancel.bind(this)

    }

    componentDidMount() {

        // Bind to mouse and touch events
        this.refs['container'].addEventListener('mousedown', this.onMouseDown, { passive: false })
        this.refs['container'].addEventListener('touchstart', this.onTouchStart, { passive: false })
        window.addEventListener('mousemove', this.onMouseMove, { passive: false })
        window.addEventListener('mouseup', this.onMouseUp, { passive: false })
        this.refs['container'].addEventListener('touchmove', this.onTouchMove, { passive: false })
        this.refs['container'].addEventListener('touchend', this.onTouchEnd, { passive: false })
        this.refs['container'].addEventListener('touchcancel', this.onTouchCancel, { passive: false })

    }

    componentWillUnmount() {

        // Unbind events
        this.refs['container'].removeEventListener('mousedown', this.onMouseDown, { passive: false })
        this.refs['container'].removeEventListener('touchstart', this.onTouchStart, { passive: false })
        window.removeEventListener('mousemove', this.onMouseMove, { passive: false })
        window.removeEventListener('mouseup', this.onMouseUp, { passive: false })
        this.refs['container'].removeEventListener('touchmove', this.onTouchMove, { passive: false })
        this.refs['container'].removeEventListener('touchend', this.onTouchEnd, { passive: false })
        this.refs['container'].removeEventListener('touchcancel', this.onTouchCancel, { passive: false })

    }

    render() {

        // Display vatom
        return <div ref='container' onContextMenu={this.onContextMenu.bind(this)} style={{ position: 'relative', zIndex: this.state.dragDidMove ? '2' : '1', opacity: this.state.dragDidMove ? '0.85' : '1', cursor: 'pointer' }}>

            {/* Render the vatom */}
            <LiveVatomViewContainer ref='VatomView' fsp={FaceSelection.Icon} vatom={this.props.vatom} noHeavyFaces style={{
                display: 'inline-block',
                width: this.props.iconSize || 128,
                height: this.props.iconSize || 128,
                margin: 10,
                transition: 'opacity 0.05s',
                userSelect: 'none',
                WebkitUserSelect: 'none'
            }} options={{ exclude: HeavyFaces }} />

            {/* Combine overlay */}
            { this.props.hovering ? <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.35)',
                backgroundSize: '32px 32px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: 'url(' + require('./add.svg') + ')',
                borderRadius: 5,
                zIndex: '9999'
            }} /> : null }

        </div>

    }

    /** @private It's possible for the container to scroll during the drag operation.
   *  This will offset the view so it doesn't appear to move away from the cursor. */
    setScrollOffset(x, y) {

        this.scrollOffsetX = x
        this.scrollOffsetY = y
        this.setDragOffset(this.dragOffsetX, this.dragOffsetY)

    }

    /** @private Moves the view while dragging */
    setDragOffset(x, y) {

        this.dragOffsetX = x
        this.dragOffsetY = y
        // this.refs['container'].style.top = y.toFixed(0) + "px"
        // this.refs['container'].style.left = x.toFixed(0) + "px"
        this.refs['container'].style.transform = `translate(${x + this.scrollOffsetX}px, ${y + this.scrollOffsetY}px)`

    }

    /** @private Called when the user right-clicks the icon */
    onContextMenu(e) {

        // Prevent usual action
        e.preventDefault()
        e.stopPropagation()

        // Ignore if within a touch gesture, we're doing this manually ourselves
        if (this.touchID !== null)
            return

        // Show menu
        this.props.onContextMenu()

    }

    /** @private Called when the user presses the mouse button */
    onMouseDown(e) {

        // Make sure they're pressing the right mouse button
        if (e.button !== 0)
            return

        // Prevent usual action
        e.preventDefault()
        e.stopPropagation()

        // Dragging is possibly about to start
        this.dragMoved = 0
        this.dragStartX = e.clientX
        this.dragStartY = e.clientY
        this.setScrollOffset(0, 0)
        this.setState({
            dragging: true,
            dragDidMove: false
        })

    }

    /** @private Called when the user moves the mouse */
    onMouseMove(e) {

        // Only do this if dragging
        if (!this.state.dragging)
            return

        // Prevent usual action
        e.preventDefault()
        e.stopPropagation()

        // Drag is happening, update positions
        this.dragMoved += Math.abs(e.clientX - this.dragStartX)
        this.dragMoved += Math.abs(e.clientY - this.dragStartY)
        this.setDragOffset(e.clientX - this.dragStartX, e.clientY - this.dragStartY)
        if (!this.state.dragDidMove)
            this.setState({ dragDidMove: true })

        // Notify hover
        this.props.onHover(e.clientX, e.clientY)

    }

    /** @private Called when the user releasees the mouse button */
    onMouseUp(e) {

        // Only do this if dragging (should always be true really)
        if (!this.state.dragging)
            return

        // Prevent usual action
        e.preventDefault()
        e.stopPropagation()

        // Check if this was just a click
        if (this.dragMoved < 10) {

            // Didn't move, this was just a click
            this.props.onClick()

        }

        // Reset dragging
        this.resetDrag()

        // Notify hover
        this.props.onDrop(e.clientX, e.clientY)
        this.props.onHoverEnd()

    }

    /** @private Called when the user puts a finger on us */
    onTouchStart(e) {

        // We only response to a single finger gesture
        if (e.touches.length !== 1)
            return

        // Start touch timer
        clearTimeout(this.dragTouchTimer)
        this.touchID = e.changedTouches[0].identifier
        this.touchDidMove = false
        this.touchDidShowMenu = false
        this.touchDidScroll = false
        this.touchIsInteraction = false
        this.touchMoved = 0
        this.dragTouchTimer = setTimeout(e => {

            // Start dragging
            this.touchIsInteraction = true

            // Dragging has started
            this.setState({
                dragging: true,
                dragDidMove: true
            })

            // If the user just continues holding down forever, show context menu after a while
            this.dragTouchTimer = setTimeout(e => {

                // Check if touch is still happening, and there's been no movement
                if (this.touchID === null || this.touchMoved > 10)
                    return

                //Hide drop indicator if it exists

                if(this.props.isNearEdge)
                  document.getElementById('dragOutOverlay').style.display='none'

                // Cancel touch and show context menu
                this.resetDrag()
                this.props.onContextMenu()

            }, 500)

        }, 350)

        // Update positions
        this.dragStartX = e.changedTouches[0].clientX
        this.dragStartY = e.changedTouches[0].clientY
        this.setScrollOffset(0, 0)

    }

    /** @private Called when the user moves their finger across the screen */
    onTouchMove(e) {

        // Ignore if not our touch
        if (this.touchID !== e.changedTouches[0].identifier)
            return

        // If the user is moving due to scrolling, allow it
        if (!this.touchIsInteraction || this.touchDidScroll) {

            this.touchDidScroll = true
            clearTimeout(this.dragTouchTimer)
            return

        }

        // Prevent usual action (no scrolling)
        e.preventDefault()
        this.touchDidMove = true

        // Store amount moved
        this.touchMoved += Math.abs(e.changedTouches[0].clientX - this.dragStartX)
        this.touchMoved += Math.abs(e.changedTouches[0].clientY - this.dragStartY)

        // Drag is happening, update positions
        this.setDragOffset(e.changedTouches[0].clientX - this.dragStartX, e.changedTouches[0].clientY - this.dragStartY)
        if (!this.state.dragDidMove)
            this.setState({ dragDidMove: true })

        // Notify hover
        this.props.onHover(e.changedTouches[0].clientX, e.changedTouches[0].clientY)

    }

    /** @private Called when the user releases their finger off the screen */
    onTouchEnd(e) {

            // Prevent usual action (no mouse event emulation)
            e.stopPropagation()
            if(e.cancelable)
              e.preventDefault()

            // Ignore if not our touch
            if (this.touchID !== e.changedTouches[0].identifier)
                return

            // Remove touch ID
            this.touchID = null

            // If the user didn't hold, just click
            if (!this.touchIsInteraction && !this.touchDidScroll) {

                // Clear timeout
                this.resetDrag()

                // Click
                this.props.onClick()
                return

            }

            // If the user was just scrolling, stop here
            if (!this.touchIsInteraction)
                return this.resetDrag()

            // If user held down, but didn't move, show menu when they let go
            if (this.touchIsInteraction && this.touchMoved < 10) {

                // Reset dragging
                this.resetDrag()

                // Show menu
                this.props.onContextMenu()
                return

            }

            // Notify hover finished
            this.props.onDrop(e.changedTouches[0].clientX, e.changedTouches[0].clientY)
            this.props.onHoverEnd()

            // Reset dragging
            this.resetDrag()

    }

    /** @private Called when the user's touch gesture is cancelled by something */
    onTouchCancel(e) {

        // Ignore if not our touch
        if (this.touchID !== e.changedTouches[0].identifier)
            return

        // Prevent usual action (no mouse event emulation)
        this.touchID = null
        e.preventDefault()

        // Reset
        this.props.onHoverEnd()
        this.resetDrag()

    }

    /** @private Resets the drag state to not dragged */
    resetDrag() {

        // Cancel timer
        clearTimeout(this.dragTouchTimer)

        // Reset dragging
        this.touchID = null
        this.setScrollOffset(0, 0)
        this.setDragOffset(0, 0)
        this.setState({
            dragging: false,
            dragDidMove: false
        })

    }

}


class Vatom {
    constructor (payload, faces, actions) {
      this.payload = payload
      this.faces = faces
      this.actions = actions
    }

    get id () {
      return this.payload.id
    }

    get private () {
      return this.payload.private
    }

    get unpublished () {
      return this.payload.unpublished
    }

    get version () {
      return this.payload.version
    }

    get sync () {
      return this.payload.sync
    }

    get whenCreated () {
      return this.payload.when_created
    }

    get whenModified () {
      return this.payload.when_modified
    }

    get properties () {
      return this.payload['vAtom::vAtomType']
    }

    /** True if this is a folder vatom */
    get isFolder () {
      return this.properties['root_type'].indexOf('ContainerType') !== -1
    }

    /** True if this is a defined folder vatom, ie a folder that can only accept certain types of child vatoms. */
    get isDefinedFolder () {
      return this.properties['root_type'].indexOf('DefinedFolderContainerType') !== -1
    }

    /** True if this is a discover folder vatom, ie a folder whose contents are fetched by performing the `Discover` action on it. */
    get isDiscoverFolder () {
      return this.properties['root_type'].indexOf('DiscoverFolderContainerType') != -1
    }

    canPerformAction (action) {
      return this.actions.find(a => a.name.indexOf(action) !== -1)
    }

    canCombineWith (otherVatom) {
      // Stop if null or ourselves
      if (!otherVatom || this.id === otherVatom.id) {
        return false
      }

      // If it's not a folder, deny
      if (!this.isFolder) {
        return false
      }

      // If it's not a defined folder, allow
      if (!this.isDefinedFolder) {
        return true
      }

      // Get child policies
      let policies = this.properties['child_policy'] || []

      // Make child policies a little easier for us to understand
      policies = policies.map(p => ({
        templateVariation: p.template_variation,
        maxCount: (p.creation_policy && p.creation_policy.policy_count_max) || 9999,
        enforceMaxCount: (p.creation_policy && p.creation_policy.enforce_policy_count_max) || false
      }))

      // Make sure we have a match
      for (let policy of policies) {
        // Check if template variation matches
        if (policy.templateVariation === otherVatom.properties.template_variation) {
          return true
        }
      }

      // No match found, deny
      return false
    }

    /** Checks if this vatom has an icon face */
    containsIconFace () {
      return !!this.faces.find(f => (f.properties.constraints.platform === 'web' || f.properties.constraints.platform === 'generic') && f.properties.constraints.view_mode === 'icon')
    }

    /** Checks if this vatom has a card face */
    containsCardFace () {
      return !!this.faces.find(f => (f.properties.constraints.platform === 'web' || f.properties.constraints.platform === 'generic') && f.properties.constraints.view_mode === 'card')
    }

    /** Checks if this vatom has a fullscreen face */
    containsFullscreenFace () {
      return !!this.faces.find(f => (f.properties.constraints.platform === 'web' || f.properties.constraints.platform === 'generic') && f.properties.constraints.view_mode === 'fullscreen')
    }

    /** TO DO: Implement in next release

    static mapString (o) {
      return Object.keys(o).map(key => `${key}=${o[key]}`).join('&')
    }

    encodeResource (url) {
      const aP = this.store.assetProvider
      const aPlen = aP.length
      const compare = urlParse(url)
      for (let i = 0; i < aPlen; i += 1) {
        const comparethis = urlParse(aP[i].uri)
        if (compare.hostname === comparethis.hostname) {
          // same uri so get the policy signature and key and append
          const queryString = Vatom.mapString(aP[i].descriptor)
          return `${url}?${queryString}`
        }
      }
      return url
    }

    getResource (resourceName, customPath) {
      let payloadResource = (customPath || this.payload['vAtom::vAtomType'].resources).find(r => r.name === resourceName)
      return this.encodeResource(payloadResource.value.value)
    }
    */
  }
