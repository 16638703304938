//
//

import React from 'react'
import Header from '../../Components/Header'
import MapBox, { Marker, getDistance } from '../../Components/MapBox'
import BLOCKv from '../../Common/Blockv'
import VatomPickup from '../../Code/VatomPickup'
import CacheComponent from '../../Decorators/CacheComponent'
import AuthorisedOnly from '../../Decorators/AuthorisedOnly'
import Errors from '../../Common/Errors'
import Analytics from '../../Code/Analytics'
import { FaceSelection } from '@blockv/sdk/face'
import ImagePolicy from '@blockv/sdk/face/faces/ImagePolicy'
import MotionDetect from '../../Common/MotionDetect'
import Config from '../../Common/Config'
import RefreshOnConfigUpdate from '../../Decorators/RefreshOnConfigUpdate'

// Users can only pick up a vAtom if they are less than this distance away from it (in meters)
const MaxDistanceForPickup = 500

export default @RefreshOnConfigUpdate @AuthorisedOnly class MapScreen extends React.Component {

    constructor() {

        super()

        // Setup state
        this.state = {
            myCountry: 'US'
        }
        this.state.showIOS12Issue = false

        // The current region
        this.region = null

    }

    render() {

        // Get list of vatoms
        let vatoms = this.region?.get(false) || []

        // Filter by config
        vatoms = vatoms.filter(Config.canShowVatom)

        // Use last valid vatom list if no vatoms in region yet
        if (this.region?.synchronized) {
            this._lastValidVatoms = vatoms
            let whitelist = Config.features.publisherFqdn
            if (Config.features.showClientVatomsOnly) {
                vatoms = vatoms.filter(v => whitelist.includes(v.properties.publisher_fqdn))
            }
        } else {
            vatoms = this._lastValidVatoms || []
            let whitelist = Config.features.publisherFqdn
            if (Config.features.showClientVatomsOnly) {
                vatoms = vatoms.filter(v => whitelist.includes(v.properties.publisher_fqdn))
            }
        }



        // To make sure the app doesn't get overloaded, enforce a maximum amount
        // if (vatoms.length > 50)
        //     vatoms.length = 50

        // Render components
        return <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

            {/* Header */}
            <Header style={{ flex: '0 0' }} tab="map" />

            {/* Map container */}
            <div style={{ position: 'relative', flex: '1 1' }}>

                {/* Map view */}
                <MapBox ref='map' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', textAlign: 'left' }} onLoad={this.onMapMoved.bind(this)} onMoved={this.onMapMoved.bind(this)} >

                    {/* Render each vAtom */}
                    {vatoms.map(vatom => {

                        // Fetch icon
                        var icon = ''
                        let latitude = vatom.properties.geo_pos.coordinates?.[1] || 0
                        let longitude = vatom.properties.geo_pos.coordinates?.[0] || 0

                        // Check vatom face
                        let face = FaceSelection.Icon(vatom)
                        if (face && face.properties.display_url.toLowerCase() === 'native://image-policy') {

                            // Use the Image Policy logic to select the image to display
                            icon = ImagePolicy.imageURL(BLOCKv, vatom, face)
                            icon = BLOCKv.UserManager.encodeAssetProvider(icon)

                        } else {

                            // Use plain ActivatedImage
                            var iconRes = vatom.properties.resources.find(r => r.name === 'ActivatedImage')
                            icon = iconRes && BLOCKv.UserManager.encodeAssetProvider(iconRes.value.value)

                        }

                        // Render marker
                        return <Marker key={vatom.id} longitude={longitude} latitude={latitude} icon={icon} onClick={e => this.onVatomClick(vatom, icon)} />

                    })}

                </MapBox>

                {/* AR button */}
                
                <div style={{
                    display: 'inline-block',
                    padding: '10px 0',
                    background: Config.theme.header.backgroundColor,
                    fontFamily: Config.theme.header.selectedTabs.fontFamily,
                    fontSize: '14px',
                    lineHeight: '32px',
                    color: Config.theme.header.tabs.fontColor,
                    padding: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: '15px',
                    textTransform: 'uppercase',
                    position: 'fixed',
                    maxWidth: '230px',
                    left: '50%',
                    bottom: '20px',
                    transform: 'translate(-50%, -50%)',
                    margin: '0 auto',
                    marginTop: '10px',
                    textAlign: 'center',
                    zIndex: 1
                }} onClick={e => this.showAR()}>
                    View in AR
                </div>
                {this.state.showIOS12Issue
                    ? <div style={{ position: 'absolute', zIndex: 5, bottom: 110, left: 'calc(50% - 300px / 2)', width: 300, borderRadius: 22, backgroundColor: 'rgb(228, 30, 38)', textAlign: 'center', color: 'white', fontSize: 13 }}>
                        <div style={{ padding: '10px 10px 2px 10px', fontWeight: 'bold' }}>No motion detected</div>
                        <div style={{ padding: '0px 10px 10px 10px' }}>
                            On iOS, you can enable motion support in the Settings app > Safari > Motion &amp; Orientation Access.
                        </div>
                    </div>
                    : null
                }
            </div>

        </div>

    }

    async componentDidMount() {

        // Log analytics
        Analytics.screen('Map')

        // Check iOS 12 motion issue
        MotionDetect.ios12Disabled().then(b => this.setState({ showIOS12Issue: b }))

        // Close region if any
        if (this.region) {

            this.region.close()
            this.region = null

        }

        let location =  fetch('https://extreme-ip-lookup.com/json/').then(l => l.json()).then(c => {
            let country = c.countryCode
            this.setState({ myCountry: country })
        })

    }

    /** @private Called when the map moves */
    onMapMoved(map) {

        // Get map area
        var bounds = map.getBounds()
        if (!bounds) return
        var sw = bounds.getSouthWest()
        var ne = bounds.getNorthEast()

        // Get bounds size
        let latWidth = Math.abs(ne.lat - sw.lat)
        let lonWidth = Math.abs(ne.lng - sw.lng)

        // Increase area size
        let multiplier = 2
        ne.lat += latWidth * (multiplier - 1)
        sw.lat -= latWidth * (multiplier - 1)
        ne.lng += lonWidth * (multiplier - 1)
        sw.lng -= lonWidth * (multiplier - 1)

        // Remove old region if needed
        if (this.region)
            this.region.close()

        // Create new region
        this.region = BLOCKv.dataPool.region('geopos', { bottom_left: { lat: sw.lat, lon: sw.lng }, top_right: { lat: ne.lat, lon: ne.lng }, publisher_fqdn: Config.features?.maps?.fqdn_whitelist })
        this.region.addEventListener('updated', e => this.onMapUpdated())

        // If region already has some content, refresh immediately
        if (this.region.get(false).length > 0)
            this.onMapUpdated()

        // Ensure the region is refreshed
        // TODO: Once the backend has a way of notifying us of geopos region changes, remove this
        this.region.forceSynchronize()

    }

    onMapUpdated() {

        this.lastUpdate = Date.now()
        this.forceUpdate()

    }

    /** @private Called when the user clicks on a vAtom on the map */
    onVatomClick(vatom, vatom_url) {

        // Log analytics for vatom pickup
        console.log('[Map] User clicked on vatom: ' + vatom.id)
        Analytics.event('initiatePickup', {
            'event': 'initiatePickup',
            'eventValue': 1,
            'userId': BLOCKv.store.userID,
            'digitalObjectId': vatom.id,
            'vatomId': vatom.id,
            'source': 'Map',
            'location': {
                'longitude': this.refs.map.userPosition.longitude,
                'latitude': this.refs.map.userPosition.latitude
            }
        }, vatom)

        // Check distance from user to vAtom
        let latitude = vatom.properties.geo_pos.coordinates?.[1] || 0
        let longitude = vatom.properties.geo_pos.coordinates?.[0] || 0
        let userPos = { lat: this.refs.map.userPosition.latitude, lng: this.refs.map.userPosition.longitude }
        let vatomPos = { lat: latitude, lng: longitude }
        let distance = getDistance(userPos, vatomPos)
        let imperialMetric = (this.state.myCountry === 'US') ? 'yards' : 'meters'
        if (distance > MaxDistanceForPickup) {

            console.warn('Selected vatom is ' + distance + ' meters away from user location.')
            return Errors.show(new Error(`You need to be within 40 ${imperialMetric} of this object to pick it up`), vatom, vatom_url, 'mapPickupOutOfRange')

        }

        // Claim vatom
        VatomPickup.pickup(vatom, { 'longitude': this.refs.map.userPosition.longitude, 'latitude': this.refs.map.userPosition.latitude }, vatom_url)

    }

    async showAR() {

        // Request permission if possible (iOS 13+)
        if (DeviceOrientationEvent.requestPermission) {

            // Request it
            try {

                // Request motion access
                let result = await DeviceOrientationEvent.requestPermission()
                if (result !== 'granted')
                    throw new Error('Motion access has not been granted.')

            } catch (err) {

                // Failed
                Errors.show(err)
                return

            }

        }

        // Show AR
        location.hash = '/ar'

    }

}
