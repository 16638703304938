
/**
 * This is a hack for the iPhone specifically, for iOS 12 only. Apple, in their divine wisdom, has disabled
 * motion detection unless the user goes into Settings and turns it on manually. Pre iOS 12 it is just
 * enabled like everyone else, and iOS 13 and up there is a special API to request permission. But for
 * iOS 12, there's nothing. So here we try to detect it, and if so, prompt a message to the user to
 * enable it in Settings.
 */
export default class MotionDetect {

    /** @returns {Promise<boolean>} Returns true if the iOS 12 warning should be displayed */
    static ios12Disabled() {

        // Check for iOS 13's enable API
        if (typeof DeviceOrientationEvent !== 'undefined' && DeviceOrientationEvent.requestPermission)
            return Promise.resolve(false)

        // Check if we're on iOS
        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        if (!iOS)
            return Promise.resolve(false)

        // We are... We need to check that we can receive motion events now. Create the promise
        return new Promise(resolve => {

            // Create cleanup function
            let eventHandler = null
            let timer = null
            let cleanup = e => {

                // Clean up after either has occurred
                window.removeEventListener('deviceorientation', eventHandler)
                clearTimeout(timer)

            }

            // Set timeout
            timer = setTimeout(e => {

                // No motion events have arrived.
                cleanup()
                resolve(true)

            }, 5000)

            // Add motion event listener
            eventHandler = function(e) {

                // Motion events are coming through, no need to show a message to the user
                cleanup()
                resolve(false)

            }
            window.addEventListener('deviceorientation', eventHandler)

        })

    }

}
