import React from 'react'

import Inventory from './Inventory'
import Map from './Map'
import Scanner from './Scanner'
import Notifications from './Notifications'
import MoreButton from './MoreButton'
import Coins from './Coins'

const Icon = (props) => {

    switch (props.name) {

    case 'inventory':
        return <Inventory {...props} />
    case 'coins':
        return <Coins {...props} />
    case 'map':
        return <Map {...props} />
    case 'scanner':
        return <Scanner {...props} />
    case 'notifications':
        return <Notifications {...props} />
    case 'moreButton':
        return <MoreButton {...props} />
    default:
        return <Inventory {...props} />

    }

}

export default Icon
