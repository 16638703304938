import React from 'react'

const styles = `
.spinner {
  margin: 0px auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  margin: 0px 3px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
`

export default function ThreeDotLoader(props) {

    return <React.Fragment>
        {/* Style CSS */}
        <style>{styles}</style>

        {/* Loader component */}
        <div className="spinner">
            <div className="bounce1" style={{ backgroundColor: props.color || '#333' }}></div>
            <div className="bounce2" style={{ backgroundColor: props.color || '#333' }}></div>
            <div className="bounce3" style={{ backgroundColor: props.color || '#333' }}></div>
        </div>
    </React.Fragment>

}
