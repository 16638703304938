//
// This decorator ensures that a function which returns a promise only runs once at a time.

export default function SingleThreadedPromise(target, key, descriptor) {

    // Get original function
    var func = descriptor.value

    // Promise
    var promise = null

    // Create replacement function
    descriptor.value = function() {

        // Check if promise is still running
        if (promise)
            return promise

        // Not running, start main function and store the promise
        promise = Promise.resolve(func.apply(this, arguments)).then(e => {

            // Done, remove promise and pass on the value
            promise = null
            return e

        }).catch(err => {

            // Failed, remove promise and pass on the error
            promise = null
            throw err

        })

        // Done, return the promise
        return promise

    }

}
