//
// Allows the user to pick a country

import React from 'react'
import Popup from './Popup'
import Countries from '../Code/Countries'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'

export default class CountryPickerPopup extends Popup {

    constructor() {

        super()

        // Setup vars
        this.state = {}
        this.state.countries = []
        this.state.detectedCountry = Countries.detected || { code: 'us', phonePrefix: '+1', name: 'United States' }

    }

    async componentDidMount() {

        // Fetch country codes
        this.setState({ countries: await Countries.get() })

        // Detect country
        let detectedCountry = await Countries.detect()
        if (detectedCountry)
            this.setState({ detectedCountry })

    }

    render() {

        return <div>

            {/* Header */}
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 40, paddingTop: 30, display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>

                {/* Title label */}
                <div style={{ padding: '0px 14px 0px 24px', fontSize: 16, color: '#4a4a4a', flexShrink: '1', flexGrow: '1' }}>Select Country</div>

                {/* Cancel button */}
                <div style={{ padding: '10px 24px 10px 4px', fontSize: 16, color: '#5393c6', cursor: 'pointer', textTransform: 'uppercase', flexShrink: '0', flexGrow: '0' }} onClick={this.close.bind(this)}>Cancel</div>

            </div>

            {/* Scrollable content */}
            <div style={{ position: 'absolute', top: 71, left: 0, width: '100%', height: 'calc(100% - 71px)', overflowX: 'hidden', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>

                <List>

                    {/* Detected country code */}
                    <ListItem button style={{ cursor: 'pointer', fontSize: 15, color: '#4a4a4a', marginLeft: 8 }} onClick={e => this.onSelect(this.state.detectedCountry)}>
                        {`${this.state.detectedCountry.name} (${this.state.detectedCountry.phonePrefix})`}
                    </ListItem>
                    <Divider />

                    {/* Country list */}
                    {this.state.countries.map(country =>
                        <ListItem button style={{ cursor: 'pointer', fontSize: 15, color: '#4a4a4a', marginLeft: 8 }} onClick={e => this.onSelect(country)} >
                            {`${country.name} (${country.phonePrefix})`}
                        </ListItem>
                    )}

                </List>

            </div>

        </div>

    }

    /** @private Called when the user selects a country */
    onSelect(country) {

        // Close us
        this.close()

        // Notify listener
        this.props.onSelect(country)

    }

}
