import React from 'react'
import Swal from 'sweetalert2'
import Blockv from '../../Common/Blockv'
import VatomGrid from '../Inventory/VatomGrid'
import config from '../../Common/Config'
import Back from './icons/Back'
import MediaQuery from 'react-responsive'
import SizeReactiveComponent from '../../Components/SizeReactiveComponent'

export default class SearchFolderRoute extends React.PureComponent {

    /** Initial state */
    constructor(props) {
      super(props)

    this.state = {

        loading: true,
        vatom: null,
        folderConfig: null,
        coverImage: null,

        /** Card aspect ratio (width = height * aspect) */
        cardAspectRatio: 0.6

    }

  }
    /** True if in card mode */
    cardMode = false

    /* Get total coin value from vatom grid */

    receiveCoinValue() {
      localStorage.setItem('folderVatom', this.state.vatom.id)
    }

    /** Render UI */
    render() {

        // Check if still loading
        if (this.state.loading)
            return <div style={{ padding: 20, color: '#AAA', fontSize: 12 }}>Loading...</div>

        // Render the UI
        return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#EEE' }}>
            <div style={{
                position: 'absolute',
                left: this.cardMode ? Math.floor(window.innerWidth / 2 - window.innerHeight * this.state.cardAspectRatio / 2) : 0,
                top: this.cardMode ? 20 : 0,
                width: this.cardMode ? Math.floor(window.innerHeight * this.state.cardAspectRatio) : '100%',
                height: this.cardMode ? Math.floor(window.innerHeight - 40) : '100%',
                overflow: 'hidden',
                backgroundColor: 'white',
                borderRadius: this.cardMode ? 8 : 0,
                boxShadow: this.cardMode ? '0px 0px 16px rgba(0, 0, 0, 0.1)' : 'none'
            }}>

                {/* Header bar */}
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 44, backgroundColor: this.state.folderConfig.title_background || config.theme.search_folder.title_background, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    {/* Title */}
                    <div style={{ textAlign: 'center', padding: '0px 44px', fontWeight: '200', fontSize: 13, fontFamily: config.theme.search_folder.title_font, letterSpacing: '1px', color: this.state.folderConfig.title_color || config.theme.search_folder.title_color }}>{this.state.folderConfig.title || this.state.vatom.properties.title}</div>

                    {/* Back button */}
                    <div className='folder-back' onClick={e => this.goBack()} style={{ position: 'absolute', top: 0, left: 0, width: 44, height: 44, cursor: 'pointer', flex:1, alignItems: 'center', justifyContent: 'center' }}><div><Back color={this.state.folderConfig.title_color || config.theme.search_folder.title_color} /></div></div>

                </div>



                {/* Vatom grid */}
                <VatomGrid scrollKey={this.state.vatom.id}
                    onVatomActivated={this.onVatomActivated.bind(this)}
                    folderVatom={this.state.vatom}
                    iconSize={128}
                    getCoinValue={this.receiveCoinValue.bind(this)}
                    style={{ position: 'absolute', top: 44, left: 0, width: '100%', height: 'calc(100% - 44px)' }}
                    header={this.state.coverImage ?
                          <div className='folder-header' style={{ zIndex: 1, width: '100%', height: this.state.folderConfig.cover_height_css || '30vh', backgroundColor: '#EEE', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url(' + this.state.coverImage + ')' }} />
                     : null} />
            </div>
        </div>

    }

    /** Called on mount */
    componentDidMount() {

        localStorage.setItem('tab', 'searchfolder')
        // Start loading
        this.load().catch(err => {

            // On error, go back to inventory
            Swal.fire("Unable to load", err.message, "warning")
            window.location.hash = '/inventory'

        })

        // Add resize listener
        window.addEventListener('resize', this.onResize)
        this.onResize()

    }

    componentWillUnmount() {

        // Remove listeners
        window.removeEventListener('resize', this.onResize)

    }

    /** @private Called on resize */
    onResize = e => {

        // Set card mode
        this.cardMode = window.innerWidth > 800 && window.innerHeight > 600 && window.innerWidth > window.innerHeight * this.state.cardAspectRatio
        this.forceUpdate()

    }

    /** Load the vatom */
    async load() {

        // Check if the vatom exists in memory already, if not then fetch it from the server
        let vatom = Blockv.dataPool.region('inventory').get(false).find(v => v.id == this.props.match.params.vatom)
        if (!vatom)
            vatom = await Blockv.Vatoms.getUserVatoms([this.props.match.params.vatom]).then(v => v[0])

        // Done
        let folderConfig = vatom.faces.find(f => f.properties.display_url == "native://search-folder")?.properties?.config || {}
        let coverImageResource = folderConfig.cover_image && vatom.properties.resources.find(r => r.name == folderConfig.cover_image)
        let coverImage = coverImageResource && Blockv.UserManager.encodeAssetProvider(coverImageResource.value.value)
        if ( !coverImage && folderConfig.cover_image && folderConfig.cover_image.match(/^http/) ) coverImage = folderConfig.cover_image;
        let cardAspectRatio = folderConfig.desktop_card_aspect || this.state.cardAspectRatio
        this.setState({ loading: false, vatom, folderConfig, coverImage, cardAspectRatio })

    }

    /** @private Called when the user clicks on a vatom */
    onVatomActivated(vatom) {

        // Check if vatom is a search folder
        if (vatom.faces.find(f => f.properties.display_url == "native://search-folder")) {
            window.location.hash = '/search-folder/' + vatom.id
            return
        }

        // Show activated screen

        window.location.hash = '/vatom/' + vatom.id

    }

    /** Called to go back */
    goBack() {
        window.location.hash = '/inventory'
    }

}
