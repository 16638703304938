//
//

/** Represents a single contact in the user's address book */
export default class Contact {

    constructor() {

        // Unique ID in the address book
        this.id = ''

        // Plugin this contact came from
        this.plugin = null

        // Name
        this.firstName = ''

        // Surname
        this.lastName = ''

        // Avatar URL
        this.avatarURL = ''

        // BLOCKv User ID
        this.userID = ''

        // Phone, email or Ethereum address, etc
        this.token = ''

        // Priority
        this.priority = 0

        // Time stamp of the contact
        this.date = 0

    }

}
