const getBrowserInfo = () => {
    const ua = navigator.userAgent;
    var os;
    var version;
    var uaIndex;

    // determine OS
    if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
        os = 'iOS';
        uaIndex = ua.indexOf('OS ');
    }
    else if (ua.match(/Android/i)) {
        os = 'Android';
        uaIndex = ua.indexOf('Android ');
    }
    else {
        os = null;
    }

    // determine version
    if (os === 'iOS' && uaIndex > -1) {
        version = ua.substr(uaIndex + 3, 3).replace('_', '.');
    }
    else if (os === 'Android' && uaIndex > -1) {
        os = ua.substr(uaIndex + 8, 3);
    }
    else {
        version = null;
    }

    return {
        version: function () {
            return version;
        },
        os: function () {
            return os;
        },
        isMobile: function () {
            return os !== null;
        }
    };
};

export default link => {
    const browserInfo = getBrowserInfo();
    let replaceBody = false;

    switch (browserInfo.os()) {
        case 'iOS':
            if (parseFloat(browserInfo.version()) <= 8) {
                replaceBody = ';';
            } else {
                replaceBody = '&';
            }
            break;
        default:
            break;
    }
    if (replaceBody) {
        return link.replace(/\?body=/, replaceBody + 'body=');
    }
}