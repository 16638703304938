//
// These media queries can be used to make content only appear on mobile, or only on desktop, etc.

import React from 'react'
import MediaQuery from 'react-responsive'

/** Use this component to render things only when on desktop */
export function DesktopOnly(props) { return <MediaQuery minWidth={601}>{props.children}</MediaQuery> }

/** Use this component to render things only when on mobile */
export function MobileOnly(props) { return <MediaQuery maxWidth={600}>{props.children}</MediaQuery> }

/** Use this component to render things only when the height is above 340 */
export function TallOnly(props) { return <MediaQuery minHeight={341}>{props.children}</MediaQuery> }

/** Use this component to render things only when the height is below 340 */
export function ShortOnly(props) { return <MediaQuery maxHeight={340}>{props.children}</MediaQuery> }
