export default async function getEnvironmentCamera() {

    // Check if unsupported
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia)
      throw new Error("This browser doesn't support camera access.")

    // Get available enumerated devices
    let availableDevices = await navigator.mediaDevices.enumerateDevices()
    
    // Filter out all except video
    let availableVideo = availableDevices.filter( r => r.kind === 'videoinput')

    // Keep browser errors to displayto the user
    let error = null

    let availableCamera = null

    for( let avid of availableVideo) {
        
        try{
            let constraints = {
                video: {
                    facingMode: {exact: 'environment'},
                    deviceId: {exact: avid.deviceId}
                }
                
            }
            availableCamera = await navigator.mediaDevices.getUserMedia(constraints)
            break
                 
        }catch(err) {
            // Translate known errors into something more friendly
            
            console.error('Video source error : ', err)
            error = err
        }
        
    }
    
    // no permissions
    if(!availableCamera && error?.message?.includes("request is not allowed"))
        throw new Error('Please check your camera permissions.')

    // browser error 
    if(!availableCamera && error?.message)
      throw error

    // no camera found
    if(!availableCamera)
      throw new Error('No camera found.')

    return availableCamera
}