var cnf = localStorage.getItem('vlabs.cnf')
var Vlabs = (cnf) ? JSON.parse(atob(cnf)) : {}

// test 1

if (!cnf && (window.location.hostname === 'localhost')) {

    Vlabs = {
        publisher: 'vatomlabs.dev',
        app: '6353ea17-6b9d-488c-880c-24f8e8944c4a',
        api_url: 'https://api.vl.vatom.network',
        ws_url: 'wss://ws.vatomviewer.com',
        title: 'VIEWER',
        favicon: 'https://vatomviewer.com/v/vlabs/flask-round.png',
        ga_tracking_id: 'UA-128484942-2',
        faq_link: 'https://www.builtonthelab.io/faq',
        feedback_email: 'Vlabs <help@vatomlabs.com>',
        feedback_subject: 'Help!',
        header_logo: 'https://vatomviewer.com/v/vlabs/vatomlabs_icon.png',
        header_width: '32px',
        header_height: '32px',
        header_color: '#FFFFFF', // white
        header_tab: '#808080', // grey
        header_tab_border: '#C0C0C0', // silver
        header_tab_active: '#000000', // black
        header_tab_accent: '#B22222', // firebrick
        tab_inventory: 'WALLET',
        tab_coins: "COINS",
        tab_map: 'MAP',
        tab_scanner: 'SCAN',
        btn_home: 'HOME',
        hide_inventory: false,
        hide_coins: false,
        hide_map: false,
        hide_scanner: true,
        hide_category_search: true,
        hide_vatom_menu: false,
        vatom_logo: 'https://vatomviewer.com/v/vlabs/vatomlabs_logo_grey.png',
        vatom_logo_width: '133px',
        vatom_logo_height: '37px',
        vatom_icons: '#000000', // black
        vatom_portrait: true,
        client_vatoms_only: false,
        coins_tpl: [
            "vatomlabs.dev::1074::v0::cointoken::v0",
            "vatomlabs.dev::1026::v0::token::v0"
        ],
        //coins_value: {
        //    "vatomlabs.dev::1074::v0::cointoken::v0": 2,
        //    "vatomlabs.dev::1026::v0::token::v0": 1
        //},
        hide_tpl: [
            "vatomlabs.dev::1042::v0::tokenfolder::v0"
        ],
        ar_distance: null,
        ar_height: null,
        ar_scale: null,
        map_style: 'street',
        css: "https://vatomviewer.com/c/vatomlabs/vatomlabs/css/style.css",
        style: {
             button_font: 'Comic Sans MS',
             button_background_color: '#B22222' // firebrick
        },
        login_redirect: '',
        upgraded_viewer: true,
    }

    if ( process.env.REFRESH_TOKEN ) localStorage.setItem('viewer_refresh',process.env.REFRESH_TOKEN)
    if ( process.env.ASSET_PROVIDER ) localStorage.setItem('viewer_asset_provider',process.env.ASSET_PROVIDER)

}

if (typeof Vlabs.style === 'undefined') Vlabs.style = {}
module.exports = Vlabs
