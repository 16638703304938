
import React from 'react'
import ReactDOM from 'react-dom'

//
// This decorator modifies a React component so that it is cached in the DOM, and is never removed.
// This is useful for react-router routes which load a lot on creation but the user comes back to it often.
// NOTE: Any component with this decorator can only be used once! Recommended to use for react-router routes
// without any params only.
//
// Use it like:
//
//  @CacheComponent("myroute")
//  export default class MyRoute extends React.Component { ... }

var CachedContents = {}

class ComponentContainer extends React.Component {

    constructor() {

        super()
        this.cache = CachedContents

    }
    render() {

        // Render the container element
        return <div ref={c => this.div = c} />

    }

    componentDidMount() {

        // Fetch existing content
        this.content = this.cache[this.props.cacheKey]
        if (!this.content) {

            // Create container div
            this.content = document.createElement('div')

            // Render children into the div
            ReactDOM.render(<>{this.props.children}</>, this.content)

        }

        // Attach content to screen
        this.div.appendChild(this.content)

        // Recover scroll positions
        for (var recover of this.content.recoverScrollPositions || [])
            recover()

        // Remove content from cache, in case another instance tries to use it
        this.cache[this.props.cacheKey] = null

        // HACK: Mapbox still receives the window resize event when it's offscreen and ends up sizing itself weirdly.
        // So, send another resize event once it's back on screen so it can sort itself out.
        try {

            window.dispatchEvent(new Event('resize'))

        } catch (err) {

            console.warn('Unable to send window resize event after re-adding cached component.')

        }

    }

    componentWillUnmount() {

        // Check that cache is empty. If not, we have a conflict!
        if (this.cache[this.props.cacheKey])
            throw new Error("Multiple instances of CacheComponent exist! Make sure you're using unique IDs and that you're not using router params.")

        // HACK: Some browsers lose scroll position when removing/re-adding elements. So, store all scroll positions for re-adding later
        this.content.recoverScrollPositions = []
        Array.from(this.content.querySelectorAll('*')).forEach(div => {

            // Check if element is scrolled
            if (!div.scrollTop || div.scrollTop <= 1)
                return

            // Create a scroll recovery function for it
            let scrollTop = div.scrollTop
            this.content.recoverScrollPositions.push(e => {

                div.scrollTop = scrollTop

            })

        })

        // Detach content from screen
        this.div.removeChild(this.content)

        // Put it back into the cache
        this.cache[this.props.cacheKey] = this.content

    }

}

export default function CacheComponent(key) {

    return function(OriginalComponent) {

        return props => <ComponentContainer cacheKey={key}><OriginalComponent {...props} /></ComponentContainer>

    }

}

CacheComponent.clearCache = function() {

    try {

        for (let key in CachedContents) {

            if (CachedContents[key])
                ReactDOM.unmountComponentAtNode(CachedContents[key])

        }

    } catch (err) {

        console.warn('[Cache Decorator] Unable to clear DOM elements', err)

    }
    CachedContents = {}

}
