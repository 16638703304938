import Contact from './Contact'
import ContactsPlugin from './ContactsPlugin'
import BLOCKv from '../../Common/Blockv'

export default class RecentContactsPlugin extends ContactsPlugin {

    constructor(manager) {

        super(manager)

        // Setup properties
        this.id = 'com.blockv.recentusers'
        this.name = 'Recent Contacts'
        this.iconURL = ''
        this.connected = true

    }

    async refresh() {

        // Stop if not logged in
        if (!BLOCKv.UserManager.isLoggedIn)
            return

        // Fetch from local storage
        try {

            // Fetch
            let items = JSON.parse(localStorage['ui.recent-users'])

            // Create contact for each one
            let idx = 0
            for (let item of items) {

                // Add it
                let contact = new Contact()
                contact.plugin = this
                contact.id = item.token
                contact.firstName = item.token
                contact.token = item.token
                contact.source = 'Recent User'
                contact.priority = 100000 - (idx++)
                contact.date = item.date
                this.addContact(contact)

            }

        } catch (err) {

            // Log non-critical error
            console.warn(err)

        }

    }

}
