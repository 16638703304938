//
// Displays the current user's, or a VatomUser's profile icon in a clickable button

import React from 'react'
import BLOCKv from '../Common/Blockv'

/** Active components */
var activeComponents = []

export default class UserAvatarButton extends React.Component {

    // Constructor
    constructor() {

        super()
        // Set state
        this.state = {}
        this.state.profileIncomplete = false
        this.state.avatar = require('./UserAvatarButton.AvatarBlank.png')

        // Bind listeners
        this.userChanged = this.userChanged.bind(this)

    }

    /** @private Called when the user's information changes */
    async userChanged() {

        // Check if user's profile is complete
        let user = this.props.user || await BLOCKv.UserManager.getCurrentUser()

        // Fetch avatar URL
        let avatarUrl = await BLOCKv.UserManager.encodeAssetProvider(user.avatarUri)
        if (avatarUrl.indexOf('default') !== -1) avatarUrl = ''

        // Check if missing email or phone number
        // name, password, email phone verified
        let profileIncomplete = false
        let userTokens = await BLOCKv.UserManager.getCurrentUserTokens() || []
        let userEmail = userTokens.find(et => et.properties.token_type === 'email')
        let userPhone = userTokens.find(pt => pt.properties.token_type === 'phone_number')

        if (!userEmail && !userPhone)
            profileIncomplete = true

        if (userTokens.find(c => c.confirmed === false))
            profileIncomplete = true

        // Check if missing name
        if (!user.firstName && !user.lastName)
            profileIncomplete = true

        // Check if password is missing
        // TODO: Waiting for server to give us a field for this

        // Set state
        this.setState({
            profileIncomplete,
            avatar: avatarUrl || require('./UserAvatarButton.AvatarBlank.png')
        })

    }

    static get activeComponents() {

        return activeComponents

    }

    /** @private Called when the component is added to the screen */
    async componentDidMount() {

        // Store as active component
        activeComponents.push(this)

        // Register for event changes
        // Events.registerEvent("user.updated", this.userChanged);
        this.userChanged()

    }

    /** @private Called when the component is removed from the screen */
    componentWillUnmount() {

        // Remove event listener
        // Events.unregisterEvent("user.updated", this.userChanged);

        // Remove active component
        activeComponents = activeComponents.filter(c => c !== this)

    }

    /** @private Render the component */
    render() {

        return <div style={Object.assign({
            // Layout
            position: 'relative',
            width: 40,
            height: 40
        }, this.props.style)} onClick={this.props.onClick} >

            {/* Image */}
            <div style={{
                // Layout
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',

                // Appearance
                backgroundImage: `url(${this.state.avatar})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                cursor: this.props.onClick ? 'pointer' : 'default',
                borderRadius: 20,
                overflow: 'hidden'

            }} />

            {/* Incomplete profile indicator */}
            <div style={{
                display: this.state.profileIncomplete ? 'block' : 'none',
                position: 'absolute',
                top: 0,
                right: 0,
                width: 12,
                height: 12,
                backgroundColor: '#db5029',
                borderRadius: 6,
                boxSizing: 'border-box',
                border: '2px solid white'
            }} />

        </div>

    }

}
