
import React from 'react'
import BLOCKv from '../Common/Blockv'
import CacheComponent from './CacheComponent'
// Modifies a react component so it can only be used while the user is logged in

class ComponentContainer extends React.Component {

    render() {

        if (!BLOCKv.UserManager.isLoggedIn)
            return <></>

        // Render the container element
        return <>{this.props.children}</>

    }

    componentDidMount() {

        if (!BLOCKv.UserManager.isLoggedIn) {

            console.warn('[AuthorizedOnly] Not logged in, redirecting to login screen')
            window.location.hash = '/'
            return

        }

        // Add listeners
        this.inventory = BLOCKv.dataPool.region('inventory')
        this.inventory.addEventListener('closed', this.onclose)
        this.inventory.addEventListener('error', this.onLoadError)

        // Call error now if the inventory is already in an error state
        if (this.inventory.error)
            this.onLoadError(this.inventory.error)

    }

    componentWillUnmount() {

        // Remove listeners
        if (this.inventory) {

            this.inventory.removeEventListener('closed', this.onclose)
            this.inventory.removeEventListener('error', this.onLoadError)
            this.inventory = null

        }

    }

    onclose = () => {

        window.location.hash = '/'
        CacheComponent.clearCache()

    }

    /** @private Called when an error occurs during inventory load */
    onLoadError = err => {

        // If error is an invalid token, we need to log out
        if (err.code === 401) {

            // Log out, ignoring errors since the token is cleared in the SDK in either case
            console.warn('[AuthorizedOnly] Detected an invalid access token, logging out...')
            BLOCKv.UserManager.logout().catch(err => console.warn('[AuthorizedOnly] Log out failed, continuing...', err.message)).then(e => {

                // Clear cached React components and go to login screen
                CacheComponent.clearCache()
                window.location.hash = '/'

            })

        }

    }

}

export default function(OriginalComponent) {

    return props => <ComponentContainer><OriginalComponent {...props} /></ComponentContainer>

}
