import React from 'react'

const Notifications = ({
    style = {},
    stroke = '#888888',
    width = '20px',
    height = '24px',
    className = '',
    viewBox = '0 0 16 16'

}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 24">
            <path fill={stroke} fillRule="evenodd" d="M2.271 18.131l.062-.173c.062-.164.123-.328.175-.48l.154-.41c.812-2.269 1.182-4.66 1.14-7.308-.01-.88-.02-1.768.103-2.637.36-2.617 2.425-4.774 5.005-5.264 3.165-.593 6.34 1.44 7.1 4.538.113.46.175.971.196 1.656.01.552.02 1.114.02 1.666 0 .634 0 1.288.031 1.922a18.92 18.92 0 0 0 1.305 6.02c.03.061.051.133.072.194l.092.276H2.271zm8.653 3.772a1.661 1.661 0 0 1-1.88-.03c-.556-.398-.813-1.084-.648-1.718.062-.225.216-.255.339-.255.277.01 2.24.01 2.518 0h.02c.236 0 .298.163.329.276.164.654-.113 1.339-.678 1.727zm8.96-3.322c-1.274-2.566-1.921-5.356-1.921-8.31v-.205l.01-.48c.01-.848.02-1.738-.072-2.596-.36-3.24-2.651-5.878-5.857-6.726a9.218 9.218 0 0 0-.997-.194A8.518 8.518 0 0 0 9.033.05C4.748.621 2.282 4.26 2.056 7.561c-.041.706-.041 1.441-.041 2.147.01.266.01.531.01.787a18.22 18.22 0 0 1-1.87 7.983c-.196.388-.206.746-.031 1.012.164.266.483.409.914.409h5.59l-.01.265c-.01.051-.01.092-.02.133-.072.685.051 1.309.36 1.86.698 1.227 1.654 1.83 2.928 1.84.339.01.688-.03 1.007-.112 1.346-.327 2.374-1.523 2.497-2.913.02-.205.02-.409.01-.634v-.44s4.511.01 5.652 0c.36 0 .668-.153.832-.408.155-.266.155-.593 0-.91z"/>
        </svg>
    )

}

export default Notifications
