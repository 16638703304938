import React from 'react'

const theme = require('../../theme/theme')

export default class Base extends React.Component {

    componentDidMount() {
    }

    render() {

        return <div className={'page base'}>
            <div className={'frame'}>
                <div className={'guide'}>
                    <img alt='logo' src={theme.images.iconLight} />
                    <h1 className={'text'}>{theme.loginText.title}</h1>
                    <h2 className={'text'}>{theme.loginText.subTitle}</h2>
                </div>
                <div className={'card'}>{this.props.children}</div>
            </div>
        </div>

    }

}
