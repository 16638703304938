
import React from 'react'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

export default props => <div id='sort-popup-bg' onClick={e => {

    // Close if the user clicked on the background
    if (e.target.id === 'sort-popup-bg')
        props.onHide()

}} style={{
    visibility: props.visible ? 'visible' : 'hidden',
    opacity: props.visible ? 1 : 0,
    transition: 'visibility 0.2s, opacity 0.2s',
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
}}>

    {/* Container */}
    <div style={{
        width: '100%',
        maxWidth: 500,
        backgroundColor: '#FAFAFA',
        borderRadius: '3px 3px 0px 0px',
        overflow: 'hidden',
        transition: 'transform 0.2s',
        transform: props.visible ? 'translateY(0px)' : 'translateY(300px)'
    }}>

        {/* Sort options */}
        <Title text='Sort' />
        <div style={{ margin: '0px 20px' }}>
            <ButtonGroup fullWidth>
                <Button style={props.sort === 'newest' ? { backgroundColor: '#72c0c7', color: 'white' } : {}} onClick={e => props.onSortChanged('newest')}>Newest</Button>
                <Button style={props.sort === 'oldest' ? { backgroundColor: '#72c0c7', color: 'white' } : {}} onClick={e => props.onSortChanged('oldest')}>Oldest</Button>
                <Button style={props.sort === 'atoz' ? { backgroundColor: '#72c0c7', color: 'white' } : {}} onClick={e => props.onSortChanged('atoz')}>A to Z</Button>
                <Button style={props.sort === 'ztoa' ? { backgroundColor: '#72c0c7', color: 'white' } : {}} onClick={e => props.onSortChanged('ztoa')}>Z to A</Button>
            </ButtonGroup>
        </div>

        {/* View options */}
        <Title text='View' />
        <ToggleOption text='Titles' checked={props.titles} onChange={props.onTitlesChanged} />

    </div>

</div>

const Title = props => <div style={{ margin: '20px', color: '#888', fontSize: 12, textAlign: 'left', textTransform: 'uppercase' }}>{props.text}</div>

const ToggleOption = props => <div style={{ margin: 20, display: 'flex', alignItems: 'center' }}>
    <div style={{ fontSize: 15, color: '#444', textAlign: 'left', flex: '1 1 auto' }}>{props.text}</div>
    <Switch checked={props.checked} onChange={props.onChange} />
</div>
