//
// This class displays an overlay over the app to show a new vatom.

import React from 'react'
import ReactDOM from 'react-dom'
import BLOCKv from '../../Common/Blockv'
import IncomingOverlayView from './IncomingOverlayView'

var CurrentOverlay = null

export default class IncomingOverlay {
    static ignore = false
    /**
     *  Shows an overlay for the specified vatom.
     *
     *  @param {Vatom} vatom The vatom to display.
     */
    static show(vatom) {

        // Check if already displaying vatom
        if (CurrentOverlay)
            return console.warn('IncomingOverlay already displaying a vatom, show() ignored.')

        if(IncomingOverlay.ignore)
          return
        // Create and show
        CurrentOverlay = new IncomingOverlay(vatom)

    }

    /** @private */
    constructor(vatom) {

        // Create container div
        this.div = document.createElement('div')
        document.body.appendChild(this.div)

        // Render component
        ReactDOM.render(<IncomingOverlayView ref={n => this.onComponent(n)} vatom={vatom} onClosed={this.onClosed.bind(this)} />, this.div)

    }

    /** Called when React has given us an instance to our component */
    onComponent(n) {

        // Store it
        this.component = n

        // Show
        this.show()

    }

    /** Display the overlay */
    show() {

        // Animate in
        setTimeout(_ => {

            this.component && this.component.setState({ visible: true, loading: false })
            this.component && this.component.onReadyToDisplay()

        }, 0)

    }

    /** We were closed */
    onClosed() {

        // Remove our container
        ReactDOM.unmountComponentAtNode(this.div)
        this.div.parentNode && this.div.parentNode.removeChild(this.div)

        // Remove our static instance
        if (CurrentOverlay === this)
            CurrentOverlay = null

    }

}
