//
//

/** This is a base Plugin class for the Contacts system. */
export default class ContactsPlugin {

    /** Called when the plugin is loaded */
    constructor(manager) {

        // Store contacts manager
        this.manager = manager

        // The ID of this plugin
        this.id = ''

        // Name of the plugin
        this.name = 'Unnamed Plugin'

        // The icon of the plugin
        this.iconURL = ''

        // Stores the found contacts for this plugin
        this.contacts = []

        // @private True while still updating
        this._updating = false

        // True if the user is connected to this service already
        this.connected = false

    }

    /** @override Refreshes the contacts. */
    refresh() {

        console.error(new Error('NOT IMPLEMENTED'))

    }

    /** Adds or updates a contact in the contacts array */
    addContact(contact) {

        /**
         * ? dont add if there is a newer contact
        */
        if (contact.date && this.contacts.find(c => c.date > contact.date && c.id === contact.id))
            return

        // Remove all contacts with the same ID
        for (let i = 0; i < this.contacts.length; i++) {

            if (this.contacts[i].id === contact.id)
                this.contacts.splice(i--, 1)

        }

        // Add this contact
        this.contacts.push(contact)

        // Notify changed
        this.manager.pluginChanged(this)

    }

    /** True if the plugin is updating */
    get updating() {

        return this._updating

    }

    /** Set updating flag */
    set updating(v) {

        this._updating = v
        this.manager.pluginChanged(this)

    }

    /** @override Call to remove all credentials for this plugin */
    reset() {

    }

}
