// Represents a send popup, to send a vatom

import React from 'react'
import Popup from './Popup'
import Countries from '../Code/Countries'
import CountryPickerPopup from './CountryPickerPopup'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Contacts from '../Code/Contacts'
import ScannerView from '../Components/ScannerView'
import { ListItemIcon } from '@material-ui/core'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import BLOCKv from '../Common/Blockv'
import JSAlert from 'js-alert'
import EthereumAddress from 'ethereum-address'
import Errors from '../Common/Errors'
import Analytics from '../Code/Analytics'
import Swal from 'sweetalert2'
import Tags from '../Common/Tags'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton } from 'react-share'
import generateSmsLink from './sharePopupHelpers'
export default class SendPopup extends Popup {

    constructor() {

        super()
        // All available contacts
        this.contacts = []

        // Setup vars
        this.state = {}
        this.state.input = ''
        this.state.country = Countries.detected || { code: 'us', phonePrefix: '+1', name: 'United States' }
        this.state.contacts = []
        this.state.scannerMode = false
        this.state.recentUsers = []
        this.state.isShare = false
        this.state.copied = false

        // Bind event listeners
        this.onContactsChanged = this.onContactsChanged.bind(this)

    }

    async componentDidMount() {

        // Log analytics
        Analytics.screen('Popup:Send')

        // Focus text field on mount. Delay, since on iPhone the keyboard comes up in the wrong place.
        setTimeout(e => {

            this.refs.inputField.focus()

        }, 250)

        // Listen for changes to contacts
        Contacts.addEventListener('change', this.onContactsChanged)
        Contacts.load()
        this.onContactsChanged()

        // Detect country
        let country = await Countries.detect()
        if (country)
            this.setState({ country })

        if (this.props.shouldShare)
            this.setState({ isShare: true })

    }

    componentWillUnmount() {

        // Remove listeners
        Contacts.removeEventListener('change', this.onContactsChanged)

    }

    /** @private Called when Contacts have been updated */
    onContactsChanged() {

        // Update UI
        this.contacts = Contacts.getAll()
        this.refreshFilter(this.state.input)

    }

    /** @private Called when the contacts or the search filter changes, so that we can filter the contacts. */
    refreshFilter(input) {

        // Check if no search text
        if (!input)
            return this.setState({ contacts: this.contacts })

        // Otherwise, get search string
        let searchFor = input.trim().toLowerCase()

        // Go through each contact, create filtered list
        let results = this.contacts.filter(contact => {

            // If no cached search string exists for this contact, create it
            if (!contact.searchString)
                contact.searchString = (contact.firstName + ' ' + contact.lastName + ' ' + contact.email + ' ' + contact.phone).trim().toLowerCase()

            // Allow this contact if it contains the search string
            return contact.searchString.indexOf(searchFor) !== -1

        }).sort((a, b) => {

            return b.date - a.date

        })

        // Set the results as our contact list
        this.setState({ contacts: results })

    }

    /** Renders the UI components */
    render() {
        const isStudioVatomWithShare = this.props.vatom.payload.private.state && this.props.vatom.payload.private.state["varius.behavior:varius.io:studio-info-v1"] && this.props.shouldShare && this.props.vatom.payload.private.state["varius.behavior:varius.io:share-v1"]
        return this.state.scannerMode ? this.renderScanner() : isStudioVatomWithShare ? this.renderCustomShare() : this.renderList()

    }

    /** Renders the UI compoents when in the normal list mode. Allows users to select a contact or enter an address. */
    renderList() {

        // Check if input is a phone number (don't show country picker if there's a + though)
        let isPhone = /^[ ()\-0-9]+$/.test(this.state.input)

        let sortedContacts = this.state.contacts.sort((a, b) => {

            return b.date - a.date

        })
        // Create list elements

        const sortedArr = sortedContacts.reduce((ind, element) => {

            if (Tags.shared.get(element?.id, 'favorite'))
                return [element, ...ind]
            else
                return [...ind, element]

        }, [])

        // Create list elements
        let contacts = sortedArr.map((contact, index) => {

            // Content label
            let content = <div>
                <div>{contact.firstName} <b>{contact.lastName}</b></div>
                <div style={{ fontSize: 12, color: '#888' }}>{contact.source}</div>
            </div>

            // Icon
            let icon = <div style={{
                backgroundImage: 'url(' + BLOCKv.UserManager.encodeAssetProvider(contact.avatarURL) + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                width: 32,
                height: 32,
                borderRadius: 16,
                margin: 10
            }} />

            // Create list item
            return <ListItem button key={index} onClick={e => this.contactSelected(contact)}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                {content}
                {Tags.shared.get(contact?.id, 'favorite')
                    ? <ListItemSecondaryAction onClick={e => this.unfavoriteContact(contact)}>
                        <IconButton edge="end" aria-label="Favorite">
                            <Star />
                        </IconButton>
                    </ListItemSecondaryAction>
                    : <ListItemSecondaryAction onClick={e => this.favoriteContact(contact)}>
                        <IconButton edge="end" aria-label="Favorite">
                            <StarBorder />
                        </IconButton>
                    </ListItemSecondaryAction>
                }

            </ListItem>

        })

        return <div>

            {/* Header */}
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 40, paddingTop: 30, display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>

                {/* Cancel button */}
                <div style={{ padding: '10px 14px 10px 24px', fontSize: 16, color: '#5393c6', cursor: 'pointer', textTransform: 'uppercase', flexShrink: '0', flexGrow: '0' }} onClick={this.close.bind(this)}>Cancel</div>

                {/* Country selector, only visible if entering a phone number */}
                {isPhone ? <div style={{ fontSize: 16, cursor: 'pointer', color: '#4a4a4a', width: 40, flexShrink: '0', flexGrow: '0' }} onClick={this.showCountryPicker.bind(this)}>{this.state.country.phonePrefix}</div>
                    : null}

                {/* Input field */}
                <input ref='inputField' placeholder='Phone, Email or ETH' value={this.state.input} onChange={this.onInputChanged.bind(this)} style={{ flexGrow: '1', flexShrink: '1', fontSize: 16, color: '#4a4a4a', border: 'none', background: 'none', outline: 'none', outlineOffset: 0, width: 64, margin: 0, padding: 0 }} onKeyDown={this.onKeyDown.bind(this)} />

                {/* Send button */}
                <div style={{ padding: '10px 24px 10px 4px', fontSize: 16, color: '#5393c6', cursor: 'pointer', textTransform: 'uppercase', flexShrink: '0', flexGrow: '0' }} onClick={this.send.bind(this)}>{this.state.isShare ? 'Share' : 'Send'}</div>

            </div>

            {/* Scrollable content */}
            <div style={{ position: 'absolute', top: 71, left: 0, width: '100%', height: 'calc(100% - 71px)', overflowX: 'hidden', overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}>

                {/* Top spacer */}
                <div style={{ height: 8 }} />

                {/* If loading, show it */}
                <div style={{ textAlign: 'center', padding: 10, color: '#888', fontSize: 13 }}>{Contacts.loading ? 'Updating...' : (this.state.contacts.length === 1 ? '1 contact' : this.state.contacts.length + ' contacts')}</div>

                {/* Show Scanner button */}
                <div onClick={e => this.setState({ scannerMode: true })} style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 48,
                    height: 48,
                    backgroundSize: '24px 24px',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(' + require('./SendPopup.qr.svg') + ')',
                    cursor: 'pointer'
                }} />

                <List>

                    {/* List of contacts */}
                    {contacts}

                    {/* Padding */}
                    <div style={{ height: 10 }} />

                </List>

                {/* List of external services, if any */}
                <div style={{ paddingTop: 30, fontSize: 15, color: '#333', textAlign: 'center' }}>Want more contacts?</div>
                <div style={{ paddingTop: 4, fontSize: 12, color: '#888', textAlign: 'center' }}>Connect to your address book service</div>
                <div style={{ paddingTop: 10, paddingBottom: 40, display: 'flex', justifyContent: 'center' }}>
                    {Contacts.plugins.filter(p => p.iconURL).map(plugin =>
                        <div onClick={e => this.connectService(plugin)} style={{ width: 40, height: 40, backgroundImage: 'url(' + plugin.iconURL + ')', backgroundSize: '24px 24px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', cursor: 'pointer' }} />
                    )}
                </div>

            </div>

        </div>

    }

    /** Renders the UI when in Scanner mode. Allows the user to scan another user's QR code to send the vAtom to them. */
    renderScanner() {

        return <React.Fragment>

            {/* Scanner */}
            <ScannerView noImageRecognition style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} onContent={this.onScannedLink.bind(this)} />

            {/* Back button */}
            <div onClick={e => this.setState({ scannerMode: false })} style={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: 64,
                height: 64,
                backgroundSize: '16px 16px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: 'url(' + require('./SendPopup.back.svg') + ')',
                cursor: 'pointer'
            }} />

        </React.Fragment>

    }

    renderCustomShare() {
        // for analytics
        const initiateShareChannel = (medium, vatom) => {
            Analytics.event('selectShareChannel', {
                'event': 'selectShareChannel',
                'eventValue': 1,
                "userId": vatom.payload['vAtom::vAtomType'].owner,
                "medium": medium
            }, vatom)
        }
        // share buttons
        const ShareButtons = ({ vatom, isCopied }) => {
            const url = (vatom.payload.private.state && this.props.vatom.payload.private.state["varius.behavior:varius.io:share-v1"] && vatom.payload.private.state["varius.behavior:varius.io:share-v1"].shareUrl) ? vatom.payload.private.state["varius.behavior:varius.io:share-v1"].shareUrl : ""
            const smsLink = `${url}?utm_source=sms&utm_medium=share`

            return (
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", marginTop: 30 }}>

                    <div
                        style={{
                            width: 64,
                            height: 64,
                            opacity: '1',
                            backgroundImage: 'url(' + require('../Routes/Activated/IconFullScreen.close.svg') + ')',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            order: 5,
                            position: 'absolute',
                            top: 0,
                            left: 0
                        }}
                        onClick={() => this.close()}
                    />

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: 10, marginTop: '2rem' }}>
                        <span>Share your Object!</span>
                        <span style={{ fontSize: 13, margin: 10 }}>Your friend will get one and you’ll keep yours too!</span>
                    </div>

                    {!url ?
                        <span style={{ position: 'absolute', top: '45%', fontSize: 13, textAlign: 'center', margin: 10 }}>We're sorry, there appears to be a problem with this vatom's share functionality...</span>
                        : <>

                            <div style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center", borderTop: 'solid 0.5px #002953' }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} key="linkFB" onClick={() => initiateShareChannel("linkFB", vatom)}>
                                    <FacebookShareButton url={`${url}?utm_source=facebook&utm_medium=share`}>
                                        <span style={{ fontSize: 20, margin: 10, color: "#3866b8", cursor: "pointer", opacity: url === "" ? 0.5 : 1.0 }} className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
                                        </span>
                                    </FacebookShareButton>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} key="linkTW" onClick={() => initiateShareChannel("linkTW", vatom)}>
                                    <TwitterShareButton url={`${url}?utm_source=twitter&utm_medium=share`}>
                                        <span style={{ fontSize: 20, margin: 10, color: "#38a1f3", cursor: "pointer", opacity: url === "" ? 0.5 : 1.0 }} className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fab fa-twitter fa-stack-1x fa-inverse"></i>
                                        </span>
                                    </TwitterShareButton>
                                </div>
                                <WhatsappShareButton onClick={() => initiateShareChannel("linkWhatsapp", vatom)} url={`${url}?utm_source=whatsapp&utm_medium=share`}>
                                    <span style={{ fontSize: 20, margin: 10, color: "#25d366", cursor: "pointer", opacity: url === "" ? 0.5 : 1.0 }} className="fa-stack fa-2x">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-whatsapp fa-stack-1x fa-inverse"></i>
                                    </span>
                                </WhatsappShareButton>
                            </div>
                            <div style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: 'solid 0.5px #002953' }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <a href={generateSmsLink(`sms:?body=${smsLink}`)} onClick={() => initiateShareChannel("linkSMS", vatom)} style={{ fontSize: 20, margin: 10, color: "#13cc39", cursor: "pointer", opacity: url === "" ? 0.5 : 1.0 }} className="fa-stack fa-2x">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-comment fa-stack-1x fa-inverse"></i>
                                    </a>

                                    <TelegramShareButton onClick={() => initiateShareChannel("linkTelegram", vatom)} url={`${url}?utm_source=telegram&utm_medium=share`}>
                                        <span style={{ fontSize: 20, margin: 10, color: "#0088cc", cursor: "pointer", opacity: url === "" ? 0.5 : 1.0 }} className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fab fa-telegram-plane fa-stack-1x fa-inverse"></i>
                                        </span>
                                    </TelegramShareButton>

                                    <CopyToClipboard text={`${url}?utm_source=link&utm_medium=share`} onCopy={() => this.setState({ copied: true })}>
                                        <span style={{ fontSize: 20, margin: 10, color: isCopied ? "red" : "#ff883b", cursor: "pointer", opacity: url === "" ? 0.5 : 1.0 }} className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-link fa-stack-1x fa-inverse"></i>
                                        </span>
                                    </CopyToClipboard>

                                </div>
                            </div>
                            <span onClick={() => this.close()} style={{ marginTop: 20, width: "100%", textAlign: "center", cursor: 'pointer' }}>Done</span>
                        </>
                    }
                </div>
            )
        }
        return <ShareButtons vatom={this.props.vatom} isCopied={this.state.copied} />
    }

    /** @private Called when the user scans a link */
    onScannedLink(link) {

        // Check if it's an Ethereum address
        let match = /^(ethereum:)?(0x[a-fA-F0-9]{40})/.exec(link)
        if (match && match[2]) {

            // Scanned an Ethereum link
            this.contactSelected(match[2])
            return

        }
        // Decode userID from url
        match = /\/receive\/([0-9A-Fa-f-]+)/.exec(link)
        if (!match || !match[1]) {

            // Not a valid link
            Errors.show(new Error('The scanned link was not a valid user.'))
            this.setState({ scannerMode: false })
            return

        }

        // Select this user
        this.contactSelected(match[1])

    }

    /** @private Called when the user changes the input in the input field */
    onInputChanged(e) {
        
        // Update state
        this.setState({ input: e.target.value })

        // Do search
        this.refreshFilter(e.target.value)

    }

    /** @private Called when the user presses a key in the input field */
    onKeyDown(e) {

        // Check for enter
        if (e.keyCode !== 13)
            return

        // Submit!
        this.send()

    }

    /** @private Called when the user selects a contact */
    async contactSelected(contact) {
        let sendMessage = `Are you sure you want to ${(this.state.isShare) ? 'share' : 'send '} this Vatom ${(this.state.isShare) ? 'with' : 'to'} <strong>${contact.firstName} ${contact.lastName}</strong>`
        if(typeof contact != 'object' && contact != null)
            sendMessage = `Are you sure you want to ${(this.state.isShare) ? 'share' : 'send '} this Vatom ${(this.state.isShare) ? 'with' : 'to'} <strong>${contact}</strong>`  
        
        let cS = await Swal.fire({
            title: (this.state.isShare) ? 'Share Vatom' : 'Send Vatom',
            html: sendMessage,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: (this.state.isShare) ? 'Share' : 'Send',
            allowEnterKey: false
        })

        if (!cS.value)
            return

        // Send it
        this.sendTo(contact)

    }

    /** Send to the specified contact or email/phone/eth string */
    sendTo(contact) {

        // Close us
        this.close()

        // Get action name
        var action = 'Transfer'
        if (this.props.shouldShare)
            action = 'Clone'

        if (this.props.shouldRedeem)
            action = 'Redeem'

        let tokenType = 'directUser'

        if (contact && contact.token && contact.token.includes('@'))
            tokenType = 'directEmail'
        else if (contact && contact.token && (/^[ +()\-0-9]+$/.test(contact.token)))
            tokenType = 'directSMS'
        else
            tokenType = 'directUser'

        Analytics.event('initiateSend', {
            'event': 'initiateSend',
            'eventValue': 1,
            'userId': BLOCKv.store.userID,
            'templateVariationName': this.props.vatom && this.props.vatom.properties.template_variation,
            'source': 'ObjectMenu'
        }, this.state.vatom)
        // Send it. We don't need to wait for this promise to finish, since we have preemptive actions the vatom will be removed instantly.
        BLOCKv.Vatoms.transferTo(typeof contact === 'string' ? contact : (contact.userID || contact.token), action, this.props.vatom.id).catch(Errors.show)

        // Log analytics
        Analytics.event('performAction', {
            'event': 'performAction',
            'eventValue': 1,
            'actionUri': action,
            'userId': BLOCKv.store.userID,
            'digitalObjectId': this.props.vatom.id,
            'vatomId': this.props.vatom.id,
            'templateVariationName': this.props.vatom && this.props.vatom.properties.template_variation,
            'medium': tokenType
        }, this.state.vatom)

        // Done!
        if (this.props.onSendComplete)
            this.props.onSendComplete()

    }

    favoriteContact(contact) {

        Tags.shared.set(contact?.id, 'favorite', 'y')
        this.forceUpdate()

    }

    unfavoriteContact(contact) {

        Tags.shared.remove(contact?.id, 'favorite')
        this.forceUpdate()

    }

    /** @private Called when the user presses the send button */
    async send() {

        // Check if valid
        if (!this.state.input)
            return

        // Check if input is a phone number, email or Ethereum address
        var input = this.state.input.trim()

        if (/^0x[a-fA-F0-9]{40}$/.test(input)) {

            // Check share mode
            if (this.props.shouldShare || this.props.shouldRedeem)
                return Errors.show(new Error('You cannot perform this action with an Ethereum address.'))

            // Input is an Ethereum address. Check if valid
            if (!EthereumAddress.isAddress(input))
                return Errors.show(new Error('The specified Ethereum address is not valid.'))

            // Confirm
            let c = await Swal.fire({
                title: this.state.isShare ? 'Share Vatom' : 'Send Vatom',
                html: `Are you sure you want to ${this.state.isShare ? 'share this vAtom with ' : 'send this vAtom to '}  <strong>${input}</strong>`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.state.isShare ? 'Share' : 'Send',
                allowEnterKey: false
            })

            if (!c.value)
                return

        } else if (/^[ +()\-0-9]+$/.test(input)) {

            // Convert to international format
            var phoneUtil = PhoneNumberUtil.getInstance()
            var pnf = phoneUtil.parse(input, this.state.country && (this.state.country.code || 'US'))
            input = phoneUtil.format(pnf, PhoneNumberFormat.E164)

            Analytics.event('initiateSend', {
                'event': 'initiateSend',
                'eventValue': 1,
                'userId': BLOCKv.store.userID,
                'templateVariationName': this.state.vatom && this.state.vatom.properties.template_variation,
                'source': 'ObjectMenu'
            }, this.state.vatom)

            // Confirm
            let c = await Swal.fire({
                title: this.state.isShare ? 'Share Vatom' : 'Send Vatom',
                html: `Are you sure you want to ${this.state.isShare ? 'share this vAtom with ' : 'send this vAtom to '}  <strong>${input}</strong>`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.state.isShare ? 'Share' : 'Send',
                allowEnterKey: false
            })

            if (!c.value)
                return

        } else if (/^[^\s]+@[^\s]+$/.test(input)) {

            // No conversion needed, it's an email

            // Confirm
            let c = await Swal.fire({
                title: this.state.isShare ? 'Share Vatom' : 'Send Vatom',
                html: `Are you sure you want to ${this.state.isShare ? 'share this vAtom with ' : 'send this vAtom to '}  <strong>${input}</strong>`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.state.isShare ? 'Share' : 'Send',
                allowEnterKey: false
            })

            if (!c.value)
                return

        } else {

            // Not a valid entry
            return Errors.show(new Error('Please enter an email address or phone number.'))

        }

        // Add to recent users list
        let items = []
        let recentTime = new Date()
        try {

            // Fetch
            items = JSON.parse(localStorage['ui.recent-users'])

        } catch (err) {

            console.warn('Unable to retrieve users list: ' + err.message)

        }

        items = items.filter(a => a !== input)
        items.unshift({ token: input, date: recentTime.getTime() })
        localStorage['ui.recent-users'] = JSON.stringify(items)

        // Close us
        this.close()

        // Send it
        this.sendTo(input)

    }

    /** @private Called when the user presses the phone number prefix field. */
    showCountryPicker() {

        // Show it
        CountryPickerPopup.show({ selectedCountry: this.state.country, onSelect: country => this.setState({ country }) })

    }

    /** @private Called when the user clicks on a service to link */
    async connectService(service) {

        // Show loader UI
        let loader = '<div>Connecting...</div>'

        // Catch errors
        try {

            // Connect
            await service.connect()

            // Log analytics
            Analytics.event('AppAction:ConnectContactsService', { service: 'google' })

            // Notify done
            JSAlert.alert(`Your ${service.name} have been imported.`, 'Contacts connected.')

        } catch (err) {

            Errors.show(err)

        }

        // Hide loader
        loader.dismiss()

    }

}